import React, { useState } from "react";

export default function InputField({
  label,
  labelName,
  placeholder,
  fieldData,
  maxLength,
  setFormData,
  hasError,
  height,
  onChange,
  readOnly,
}) {
  const [focusedField, setFocusedField] = useState(null);

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };

  const handleKeyDown = (e) => {
    if (e.key === " " && e.target.value === "") {
      e.preventDefault();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Allow only letters, numbers, spaces, commas, and dots
    let filteredValue = value
      .replace(/[^a-zA-Z,. ]/g, "") // Keep letters, numbers, spaces, commas, dots
      .replace(/\s{2,}/g, " ") // Replace multiple spaces with a single space
      .replace(/([,.])\1+/g, "$1"); // Prevent consecutive commas or dots

    setFormData((prevData) => ({
      ...prevData,
      [name]: filteredValue,
    }));

    if (onChange) {
      onChange(filteredValue);
    }
  };

  return (
    <div className="frm-group">
      <div htmlFor={label} className="sub-head">
        {labelName}
      </div>
      <div
        className={`frm-field ${
          (focusedField === label || hasError) && !fieldData
            ? "draw-border"
            : ""
        }`}
        style={{ height: "auto", padding: ".75rem" }}
      >
        <textarea
          value={fieldData}
          onChange={handleInputChange}
          onFocus={() => handleFocus(label)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          id={label}
          name={label}
          placeholder={placeholder}
          className="frm-control"
          maxLength={maxLength}
          readOnly={readOnly}
          style={{
            caretColor: "#1e6641",
            cursor: "default",
            height: height,
          }}
        />
      </div>
    </div>
  );
}
