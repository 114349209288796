import React, { useState, useEffect } from "react";
import Button from "../../Components/Button/Button";
import InputField from "../../Components/InputField/InputField";
import SelectSearch from "../../Components/SelectFields/SelectField";
import { rem } from "../../Components/Rem_func";
const FilterFeedback = ({ filteredData, setFilteredData, hide }) => {
  // // State variables to store filter criteria
  // const [filteredData, setFilteredData] = useState({
  //   form_date: "",
  //   company_name: "",
  //   client_name: "",
  //   client_disignation: "",
  //   telephone_number: "",
  //   email_address: "",
  //   other_feedback: "",
  //   quality_rate: "",
  //   services_experience_rate: "",
  //   technical_enquires_rate: "",
  //   team_communication_rate: "",
  //   team_help_rate: "",
  //   product_quality_punctuality_rate: "",
  //   customer_statisfaction_rate: "",
  //   service_provider_rate: "",
  //   about_team_product_service: "",
  // });
  const [localData, setLocalData] = useState(filteredData);

  useEffect(() => {
    setLocalData(filteredData);
  }, [filteredData]);
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const maxDate = getCurrentDate();

  //   quality rate options
  const checkOptions = ["Very Good", "Good", "Average", "Poor"];

  // Function to handle filter application
  const handleFilter = () => {
    setFilteredData(localData);
    hide();
  };

  // Function to reset filters
  const ResetFilter = () => {
    const resetData = {
      form_timestamp_from: "",
      form_timestamp_to: "",
      company_name: "",
      client_name: "",
      client_disignation: "",
      telephone_number: "",
      email_address: "",
      other_feedback: "",
      quality_rate: "",
      services_experience_rate: "",
      technical_enquires_rate: "",
      team_communication_rate: "",
      team_help_rate: "",
      product_quality_punctuality_rate: "",
      customer_statisfaction_rate: "",
      service_provider_rate: "",
      about_team_product_service: "",
    };

    setFilteredData(resetData);
  };
  const handleSelectChange = (name, value) => {
    setLocalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="filter-box">
      <div className="popover-title">Filter</div>
      <div className="filter-body">
        <div className="sub-head" style={{ marginBottom: "0" }}>
          Date
        </div>
        <div className="date-filter">
          <div style={{ width: "48%" }}>
            <InputField
              labelName={"From"}
              label={"form_timestamp_from"}
              type={"Date"}
              placeholder={""}
              fieldData={localData.form_timestamp_from}
              setFormData={setLocalData}
              maxLength={
                localData.form_timestamp_to
                  ? localData?.form_timestamp_to?.split(" ")[0]
                  : maxDate
              }
            />
          </div>
          <div style={{ width: "48%" }}>
            <InputField
              labelName={"To"}
              label={"form_timestamp_to"}
              type={"Date"}
              placeholder={""}
              fieldData={localData.form_timestamp_to}
              setFormData={setLocalData}
              maxLength={maxDate}
              minLength={localData?.form_timestamp_from?.split(" ")[0] || ""}
            />
          </div>
        </div>

        <InputField
          label={"company_name"}
          labelName={"Company Name"}
          type={"text"}
          placeholder={""}
          fieldData={localData.company_name}
          setFormData={setLocalData}
          maxLength={50}
        />
        <InputField
          label={"client_name"}
          labelName={"Client Name"}
          type={"text"}
          placeholder={""}
          fieldData={localData.client_name}
          setFormData={setLocalData}
          maxLength={50}
          pattern={"onlyAlpha"}
        />
        <InputField
          label={"client_disignation"}
          labelName={"Client Designation"}
          type={"text"}
          placeholder={""}
          fieldData={localData.client_disignation}
          setFormData={setLocalData}
          maxLength={50}
          pattern={""}
        />
        <InputField
          label={"telephone_number"}
          labelName={"Mobile Number"}
          // type={"number"}
          placeholder={"+91"}
          fieldData={localData.telephone_number}
          setFormData={setLocalData}
          maxLength={10}
          pattern={"mobile"}
        />
        <InputField
          label={"email_address"}
          labelName={"Email Address"}
          type={"text"}
          placeholder={"enter your email"}
          fieldData={localData.email_address}
          setFormData={setLocalData}
          maxLength={50}
          pattern={"email"}
        />

        <div>
          <SelectSearch
            label="Quality Rate"
            name="quality_rate"
            Placeholder="Select option"
            options={checkOptions}
            value={localData?.quality_rate}
            onChange={(value) => handleSelectChange("quality_rate", value)}
            height={36}
          />
        </div>
        <div>
          <SelectSearch
            label="Services Experience Rate"
            name="services_experience_rate"
            Placeholder="Select option"
            options={checkOptions}
            value={localData?.services_experience_rate}
            onChange={(value) =>
              handleSelectChange("services_experience_rate", value)
            }
            height={36}
          />
        </div>

        <div>
          <SelectSearch
            label="Technical Enquires Rate"
            name="technical_enquires_rate"
            Placeholder="Select option"
            options={checkOptions}
            value={localData?.technical_enquires_rate}
            onChange={(value) =>
              handleSelectChange("technical_enquires_rate", value)
            }
            height={36}
          />
        </div>

        <div>
          <SelectSearch
            label="Team Communication Rate"
            name="team_communication_rate"
            Placeholder="Select option"
            options={checkOptions}
            value={localData?.team_communication_rate}
            onChange={(value) =>
              handleSelectChange("team_communication_rate", value)
            }
            height={36}
          />
        </div>

        <div>
          <SelectSearch
            label="Team Help Rate"
            name="team_help_rate"
            Placeholder="Select option"
            options={checkOptions}
            value={localData?.team_help_rate}
            onChange={(value) => handleSelectChange("team_help_rate", value)}
            height={36}
          />
        </div>

        <div>
          <SelectSearch
            label="Product Quality Punctuality Rate"
            name="product_quality_punctuality_rate"
            Placeholder="Select option"
            options={checkOptions}
            value={localData?.product_quality_punctuality_rate}
            onChange={(value) =>
              handleSelectChange("product_quality_punctuality_rate", value)
            }
            height={36}
          />
        </div>

        <div>
          <SelectSearch
            label="Customer Satisfaction Rate"
            name="customer_statisfaction_rate"
            Placeholder="Select option"
            options={checkOptions}
            value={localData?.customer_statisfaction_rate}
            onChange={(value) =>
              handleSelectChange("customer_statisfaction_rate", value)
            }
            height={36}
          />
        </div>
      </div>

      <div className="filter-btn">
        <div>
          <Button
            btnname={"Reset"}
            bg={"#fff"}
            cl={"#000"}
            border={`${rem(1)} solid #1E6641`}
            onclick={ResetFilter}
            box_s={"none"}
          />
        </div>
        <div>
          <Button
            btnname={"Apply Now"}
            onclick={handleFilter}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleFilter();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterFeedback;
