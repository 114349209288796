import React from "react";
import SalesTracker from "../STcomponents/SalesTracker/SalesTracker";
import OPtable from "./OPtable";
const AwaitingOrder = () => {
  return (
    <>
      <SalesTracker
        title={"Order Placed"}
        UserDataTable={OPtable}
        api={"/api/order_place"}
        OrderPlaced={true}
      />
    </>
  );
};

export default AwaitingOrder;
