import React, { useCallback, useEffect, useState } from "react";
import "./FeedbackForm.scss";
import { Table } from "antd";
import Button from "../../../Components/Button/Button";
import SnackBar from "../../../Components/SnackBar";
import { useForm, Controller } from "react-hook-form";
import { rem } from "../../../Components/Rem_func";
import _nonAuthHttp from "../../../Utils/Api/_nonAuthHttp";
import _http from "../../../Utils/Api/_http";
// import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../Redux/Reducers";
import { Tooltip } from "react-tooltip";

export default function FeedbackForm() {
  const [toast, setToast] = useState({
    submit: false,
    error: false,
    warning: false,
  });
  const [submit, setSubmit] = useState(false);
  const [feedback, setFeedback] = useState({
    about_team_product_service: [],
    service_provider_rate: [],
  });
  const [isComplete, setIsComplete] = useState(false);
  const [customAlert, setCustomAlert] = useState({
    about_team_product_service: false,
    service_provider_rate: false,
  });
  const [cond, setCond] = useState(false);
  const [error, setError] = useState("");
  const [err, setErr] = useState({});
  const [loading, setLoading] = useState(false);
  const [get, setGet] = useState(false);
  const {
    setValue,
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const [url, setUrl] = useState();
  // "https://spira-qa.dtskill.com/feedbackform?reference_number=56f119d11ae7"
  const [tooltip, setTooltip] = useState("Click to Copy Link");
  const [formData, setFormData] = useState({
    organisation_name: "",
    client_name: "",
    client_designation: "",
    telephone_number: "",
    email_address: "",
    reference_number: "",
  });
  const [isRotate, setIsRotate] = useState(false);

  const Access = useSelector(selectUserData);

  // const [referenceNumber, setReferenceNumber] = useState("");

  const fetchFeedbackData = useCallback(
    async (reference_number) => {
      try {
        const response = await _nonAuthHttp.get(
          `/api/get-half-feedback?reference_number=${reference_number}`
        );
        const data = await response.data.feedback_data;

        // setValue("ack_no", data.organisation_name || "");
        setValue("company_name", data.organisation_name || "");
        setValue("client_name", data.client_name || "");
        setValue("client_disignation", data.client_designation || "");
        setValue("telephone_number", data.telephone_number || "");
        setValue("email_address", data.email_address || "");
      } catch (error) {
        console.error("Error fetching feedback data:", error);
        setToast({ error: true });
        setError(error?.response?.data?.error);
      }
    },
    [setValue]
  );

  useEffect(() => {
    // Extract the reference_number from the URL query string
    const searchParams = new URLSearchParams(window.location.search);
    const reference_number = searchParams.get("reference_number");

    if (reference_number) {
      // setReferenceNumber(reference_number);
      // Call the API with the extracted reference_number
      fetchFeedbackData(reference_number);
    }
  }, [fetchFeedbackData]);

  // useEffect
  useEffect(() => {
    if (feedback.about_team_product_service.length === 0 && cond) {
      setCustomAlert((prev) => ({ ...prev, about_team_product_service: true }));
    } else {
      setCustomAlert((prev) => ({
        ...prev,
        about_team_product_service: false,
      }));
    }

    if (feedback.service_provider_rate.length === 0 && cond) {
      setCustomAlert((prev) => ({ ...prev, service_provider_rate: true }));
    } else {
      setCustomAlert((prev) => ({ ...prev, service_provider_rate: false }));
    }
  }, [
    feedback.service_provider_rate,
    feedback.about_team_product_service,
    cond,
  ]);

  const handletoast = () => {
    setToast({ submit: false, error: false, warning: false });
  };

  // function for customer information
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Trim leading spaces for all inputs
    const trimmedValue = value.trimStart();
    if (name === "reference_number" || name === "ack_number") {
      const sanitizedValue = value
        .replace(/\s/g, "")
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .toUpperCase();
      setFormData({ ...formData, [name]: sanitizedValue });
      return;
    }
    // For email_address, prevent any spaces entirely (including leading)
    if (name === "email_address") {
      // Remove any spaces from the input
      const sanitizedValue = value
        .replace(/\s/g, "")
        .replace(/[^a-z0-9.@ ]/g, "")
        .replace(/([,.@])\1+/g, "$1");
      setFormData({ ...formData, [name]: sanitizedValue });

      // Update form data without any spaces

      if (!sanitizedValue) {
        setErr((prevErrors) => ({
          ...prevErrors,
          email_address: "* Email is required",
        }));
      } else if (
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
          sanitizedValue
        )
      ) {
        setErr((prevErrors) => ({
          ...prevErrors,
          email_address: "* Invalid email address",
        }));
      } else {
        setErr((prevErrors) => ({ ...prevErrors, email_address: "" }));
      }
      return;
    }

    // For telephone_number, restrict input to numeric values only and max length of 10
    if (name === "telephone_number") {
      const numericValue = trimmedValue.replace(/\D/g, ""); // Remove all non-numeric characters
      const truncatedValue = numericValue.slice(0, 10); // Limit the length to 10 characters

      setFormData({ ...formData, [name]: truncatedValue });

      // Revalidate telephone number
      if (truncatedValue === "") {
        setErr((prevErrors) => ({
          ...prevErrors,
          telephone_number: "* Telephone number is required",
        }));
      } else if (truncatedValue.length < 10) {
        setErr((prevErrors) => ({
          ...prevErrors,
          telephone_number: "* Telephone number must be exactly 10 digits",
        }));
      } else {
        setErr((prevErrors) => ({ ...prevErrors, telephone_number: "" }));
      }
      return;
    }

    // For fields that should only accept alphabetic characters
    if (
      name === "organisation_name" ||
      name === "client_name" ||
      name === "client_designation"
    ) {
      const alphabeticValue = trimmedValue
        .replace(/[^a-zA-Z\s]/g, "")
        .replace(/\s{2,}/g, " "); // Remove non-alphabetic characters and reduce multiple spaces
      setFormData({ ...formData, [name]: alphabeticValue });

      // Revalidate for empty input
      if (alphabeticValue === "") {
        setErr((prevErrors) => ({
          ...prevErrors,
          [name]: `* ${name
            .replace("_", " ")
            .replace(/\b\w/g, (l) => l.toUpperCase())} is required`,
        }));
      } else {
        setErr((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
      return;
    }

    // Update form data for other fields
    setFormData({ ...formData, [name]: trimmedValue });

    // Check if the field is empty and set an error if required
    if (trimmedValue === "") {
      setErr((prevErrors) => ({
        ...prevErrors,
        [name]: `* ${name
          .replace("_", " ")
          .replace(/\b\w/g, (l) => l.toUpperCase())} is required`,
      }));
    } else {
      setErr((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const GetBuyerData = async (e) => {
    e.preventDefault();
    if (!formData.ack_number && !formData.reference_number) {
      setToast({ error: true });
      setError("Please fill Reference Number or Ack Number");
      return;
    }
    setGet(true);
    try {
      const params = { reference_number: formData.reference_number };

      const response = await _http.get("/api/get-all-ackmail", { params });
      const data = await response.data.feedback_data;
      setFormData((prevData) => ({
        ...prevData,
        organisation_name: data.organisation_name || "",
        client_name: data.client_person_name?.trim() || "",
        client_designation: data.client_designation || "",
        telephone_number: data.telephone_number || "",
        email_address: data.client_email || "",
      }));
      // Clear error messages
      setErr({});
      console.log(err);
    } catch ({ error, data }) {
      console.log(error);
      setToast({ error: true });
      setError("Error fetching Buyer's Data");
    }
    setGet(false);
  };
  const userSubmitHandler = async (data) => {
    let feedbackdata = [
      "ref_num",
      "company_name",
      "client_name",
      "client_disignation",
      "telephone_number",
      "email_address",
    ];

    const dataKeys = Object.keys(data);

    // Check if the necessary keys exist in the data object
    const requiredKeysExist = feedbackdata.every((key) =>
      dataKeys.includes(key)
    );

    if (!requiredKeysExist) {
      console.log("Required fields are missing.");
      return;
    }

    setLoading(true);
    try {
      const res = await _http.post("/api/generate_feedback_url", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(res);

      setToast({ submit: true });
      setUrl(res.data.url);
      setError(res.data.message || "Successfully sent the URL to the email.");
    } catch (error) {
      console.log(error);
      setToast({ error: true });
      setError("Submission failed");
    }
    setLoading(false);
  };

  const handleReset = (e) => {
    e.preventDefault();

    setFormData({
      reference_number: "",
      organisation_name: "",
      client_name: "",
      client_designation: "",
      telephone_number: "",
      email_address: "",
    });
    setErr({});
    setUrl();
    setValue("company_name", "");
    setValue("client_name", "");
    setValue("client_disignation", "");
    setValue("telephone_number", "");
    setValue("email_address", "");
    setValue("ref_num", "");
  };
  const onSubmit = async (data) => {
    console.log("yu");

    setCond(true);
    let hasErrors = false;
    if (feedback.about_team_product_service.length === 0) {
      setCustomAlert((prev) => ({ ...prev, about_team_product_service: true }));
      hasErrors = true;
    } else {
      setCustomAlert((prev) => ({
        ...prev,
        about_team_product_service: false,
      }));
    }
    if (feedback.service_provider_rate.length === 0) {
      setCustomAlert((prev) => ({ ...prev, service_provider_rate: true }));
      hasErrors = true;
    } else {
      setCustomAlert((prev) => ({ ...prev, service_provider_rate: false }));
    }
    if (hasErrors || Object.keys(errors).length > 0) {
      setToast({ ...toast, error: true });
      setError("Please fill all the required fields");
      return;
    }
    data.service_provider_rate = feedback.service_provider_rate;
    data.about_team_product_service = feedback.about_team_product_service;

    let feedbackdata = [
      "ref_num",
      "company_name",
      "client_name",
      "client_disignation",
      "telephone_number",
      "email_address",
      "other_feedback",
      "quality_rate",
      "services_experience_rate",
      "technical_enquires_rate",
      "team_communication_rate",
      "team_help_rate",
      "product_quality_punctuality_rate",
      "customer_statisfaction_rate",
      "service_provider_rate",
      "about_team_product_service",
    ];

    const dataKeys = Object.keys(data);

    const allKeysExist = dataKeys.every((key) => feedbackdata.includes(key));
    console.log(dataKeys);

    if (!allKeysExist) {
      return;
    }
    setSubmit(true);

    try {
      await _nonAuthHttp.post(`/api/customer_feedback`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      setFeedback({
        about_team_product_service: [],
        service_provider_rate: [],
      });
      reset({
        company_name: "",
        client_name: "",
        client_disignation: "",
        telephone_number: "",
        email_address: "",
        other_feedback: "",
        quality_rate: "",
        services_experience_rate: "",
        technical_enquires_rate: "",
        team_communication_rate: "",
        team_help_rate: "",
        product_quality_punctuality_rate: "",
        customer_statisfaction_rate: "",
      });
      setIsComplete(true);
      setToast({ submit: true });
      setError("Successfully submitted");
    } catch (error) {
      setToast({ error: true });
      setError("Submission failed");
      setSubmit(false);

      console.log(error.msg);
    }
    setCond(false);
  };

  const checkvalue1 = [
    { key: "1", value: "Quality", pass: "service_provider_rate" },
    { key: "2", value: "Trust", pass: "service_provider_rate" },
    { key: "4", value: "Previous experience", pass: "service_provider_rate" },
    { key: "3", value: "Brand Name", pass: "service_provider_rate" },
    { key: "5", value: "Word of mouth", pass: "service_provider_rate" },
  ];

  const checkvalue2 = [
    {
      key: "1",
      value: "Customer Satisfaction",
      pass: "about_team_product_service",
    },
    { key: "2", value: "Response Time", pass: "about_team_product_service" },
    {
      key: "3",
      value: "Quality of product",
      pass: "about_team_product_service",
    },
    {
      key: "4",
      value: "Customer Engagement",
      pass: "about_team_product_service",
    },
    {
      key: "5",
      value: "Problem Resolution",
      pass: "about_team_product_service",
    },
  ];

  const columns = [
    { title: " ", dataIndex: "question", key: "question" },
    {
      title: "Very Good",
      dataIndex: "veryGood",
      key: "veryGood",
      width: rem(118),
    },
    {
      title: "Good",
      dataIndex: "good",
      key: "good",
      width: rem(100),
    },
    {
      title: "Average",
      dataIndex: "average",
      key: "average",
      width: rem(100),
    },
    {
      title: "Poor",
      dataIndex: "poor",
      key: "poor",
      width: rem(100),
    },
  ];

  function checkboxHandler(e, pass) {
    let isSelected = e.target.checked;
    let value = e.target.value;

    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [pass]: isSelected
        ? [...prevFeedback[pass], value]
        : prevFeedback[pass].filter((item) => item !== value),
    }));
  }

  const renderRadio = (question, value) => (
    <Controller
      control={control}
      name={question}
      rules={!Access.spiraToken ? { required: "This field is required" } : {}}
      render={({ field }) => (
        <label className="container-radio container-check">
          <input
            type="radio"
            {...field}
            checked={field.value === value}
            value={value}
            disabled={submit}
          />
          <span className="checkmark"></span>
        </label>
      )}
    />
  );

  const dataSource = [
    {
      key: "1",
      question: "How would you rate our product in terms of Quality?",
      veryGood: renderRadio("quality_rate", "Very Good"),
      good: renderRadio("quality_rate", "Good"),
      average: renderRadio("quality_rate", "Average"),
      poor: renderRadio("quality_rate", "Poor"),
    },
    {
      key: "2",
      question:
        "How would you rate our products and services based on your experience?",
      veryGood: renderRadio("services_experience_rate", "Very Good"),
      good: renderRadio("services_experience_rate", "Good"),
      average: renderRadio("services_experience_rate", "Average"),
      poor: renderRadio("services_experience_rate", "Poor"),
    },
    {
      key: "3",
      question:
        "How would you rate our team on their ability to resolve your technical enquiries?",
      veryGood: renderRadio("technical_enquires_rate", "Very Good"),
      good: renderRadio("technical_enquires_rate", "Good"),
      average: renderRadio("technical_enquires_rate", "Average"),
      poor: renderRadio("technical_enquires_rate", "Poor"),
    },
    {
      key: "4",
      question: "How would you rate our team’s communication?",
      veryGood: renderRadio("team_communication_rate", "Very Good"),
      good: renderRadio("team_communication_rate", "Good"),
      average: renderRadio("team_communication_rate", "Average"),
      poor: renderRadio("team_communication_rate", "Poor"),
    },
    {
      key: "5",
      question: "How would you rate our team’s willingness to help?",
      veryGood: renderRadio("team_help_rate", "Very Good"),
      good: renderRadio("team_help_rate", "Good"),
      average: renderRadio("team_help_rate", "Average"),
      poor: renderRadio("team_help_rate", "Poor"),
    },
    {
      key: "6",
      question:
        "How would you rate our company’s delivery services in terms of product quality, punctuality and problem?",
      veryGood: renderRadio("product_quality_punctuality_rate", "Very Good"),
      good: renderRadio("product_quality_punctuality_rate", "Good"),
      average: renderRadio("product_quality_punctuality_rate", "Average"),
      poor: renderRadio("product_quality_punctuality_rate", "Poor"),
    },
    {
      key: "7",
      question: "How would you rate your overall customer satisfaction?",
      veryGood: renderRadio("customer_statisfaction_rate", "Very Good"),
      good: renderRadio("customer_statisfaction_rate", "Good"),
      average: renderRadio("customer_statisfaction_rate", "Average"),
      poor: renderRadio("customer_statisfaction_rate", "Poor"),
    },
  ];
  const handleCopy = () => {
    if (url) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          console.log("URL copied to clipboard");
          // Optionally show a success message or tooltip
        })
        .catch((err) => {
          console.error("Failed to copy the URL: ", err);
        });
      setTooltip("Copied");
      setTimeout(() => {
        setTooltip("Click to Copy Link");
      }, 1000);
    }
  };

  const handlePreview = (e) => {
    e.preventDefault();
    const newErrors = {};
    const requiredFields = [
      "organisation_name",
      "client_name",
      "client_designation",
      "telephone_number",
      "email_address",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `* ${field

          .replace("_", " ")
          .replace(/\b\w/g, (l) => l.toUpperCase())} is required`;
        setToast({ error: true });
        setError("Please fill all the input field");
      }
    });

    // Additional validation for telephone_number and email_address
    if (
      formData.telephone_number &&
      !/^\d{10}$/.test(formData.telephone_number)
    ) {
      newErrors.telephone_number = "* Invalid Telephone Number";
      setToast({ error: true });
      setError("Invalid Telephone Number");
    }
    if (
      formData.email_address &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        formData.email_address
      )
    ) {
      newErrors.email_address = "* Invalid Email Address";
      setToast({ error: true });
      setError("Invalid Email Address");
    }

    if (Object.keys(newErrors).length > 0) {
      setErr(newErrors);
      return;
    }
    setValue("company_name", formData.organisation_name || "");
    setValue("client_name", formData.client_name || "");
    setValue("client_disignation", formData.client_designation || "");
    setValue("telephone_number", formData.telephone_number || "");
    setValue("email_address", formData.email_address || "");
    setValue("ref_num", formData?.reference_number || "");
    setError({});
  };

  const handleRotate = () => {
    setIsRotate(!isRotate);
  };

  return (
    <section style={{ height: "100%" }}>
      <div className="feedbackform">
        {Access.spiraToken && <p className="form-title">Feedback</p>}
        {Access.spiraToken && (
          <div className="Upper-Feedback">
            <div className="heading-2">Customer Details / Information</div>
            <form className="input-fields-val df">
              <div className="info-input-details">
                <span className="sub-head user-field-heading">
                  Reference Number/Ack Number
                </span>
                <input
                  placeholder={`Enter Reference Number/Ack Number`}
                  className="input-filter"
                  name={"reference_number"}
                  value={formData.reference_number}
                  onChange={handleChange}
                  readOnly={get}
                  maxLength={20}
                />
              </div>
              <div className="df ">
                <Button
                  btnname={get ? "Fetching..." : "Fetch Buyer POC"}
                  height={38}
                  onclick={GetBuyerData}
                  // type="submit"
                  br={5}
                  fs={15}
                  disabled={get}
                  fw={500}
                  border={`${rem(1)} solid #1E6641`}
                  width={148}
                  bg={"#fff"}
                  cl={"#1e6641"}
                />
              </div>
            </form>
            <form>
              <div className="inputs-1-details">
                {[
                  "organisation_name",
                  "client_name",
                  "email_address",
                  "client_designation",
                  "telephone_number",
                ].map((field) => (
                  <div key={field} className="info-input-details">
                    <span className="sub-head">
                      {field
                        .replace("_", " ")
                        .replace(/\b\w/g, (l) => l.toUpperCase())}
                    </span>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <input
                        placeholder={`Enter ${field.replace("_", " ")}`}
                        className="input-filter"
                        name={field}
                        value={formData[field]}
                        onChange={handleChange}
                        readOnly={loading}
                      />
                      {err[field] && (
                        <span className="FormError">{err[field]}</span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </form>
            <div className="btn-Submit">
              <Button
                btnname={"Reset"}
                height={38}
                onclick={handleReset}
                br={5}
                fs={15}
                disabled={loading}
                fw={500}
                bg={"#fff"}
                cl={"#1e6641"}
                width={119}
                border={`${rem(1)} solid #1E6641`}
              />
              <Button
                // btnname={loading ? "Submitting..." : "Submit"}
                btnname={"Preview"}
                height={38}
                // onclick={userSubmitHandler}
                onclick={handlePreview}
                type="submit"
                br={5}
                fs={15}
                bg={"#1E6641"}
                disabled={loading}
                width={119}
                fw={500}
                border={`${rem(1)} solid #1E6641`}
              />
            </div>
            {/* <hr className="line" /> */}
          </div>
        )}

        <div className="below-feedback">
          <div className="header df flexColumn justify-start align-start">
            <div className="heading">Customer Feedback Form</div>
            <p className="heading-info">
              As part of our (ISO 9001-2015) Quality Management System, and our
              commitment to continuously improve the quality of our services and
              products. We would appreciate your comments and suggestions.
              Please take a few minutes to complete the short questionnaire
              below. We thank you for your time and input.
            </p>
          </div>
          <form
            onSubmit={handleSubmit(
              Access.spiraToken ? userSubmitHandler : onSubmit
            )}
            className="w100"
          >
            <div className="input-fields w100">
              <div className="inputs-1">
                <div className="info-input">
                  <span className="sub-head">
                    Reference Number/ Ack Number*
                  </span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <input
                      type="text"
                      placeholder="Enter Reference Number/ Ack No"
                      className="input-filter"
                      {...register("ref_num", { required: true })}
                      readOnly={submit}
                      onInput={(e) => {
                        let value = e.target.value;

                        // Remove non-alphabetic characters and special characters
                        value = value
                          ?.replace(/\s/g, "")
                          ?.replace(/[^a-zA-Z0-9 ]/g, "");

                        // Set the formatted value back to the input
                        e.target.value = value.toUpperCase();
                      }}
                    />

                    {errors.ref_num && (
                      <span className="FormError">
                        * This field is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="info-input">
                  <span className="sub-head">Organization Name*</span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <input
                      type="text"
                      placeholder="Enter Organization Name"
                      className="input-filter"
                      {...register("company_name", { required: true })}
                      readOnly={submit}
                      onInput={(e) => {
                        let value = e.target.value;

                        // Remove non-alphabetic characters and special characters
                        value = value.replace(/[^A-Za-z\s]/g, "");

                        // Replace multiple spaces with a single space
                        value = value.replace(/\s{2,}/g, " ");

                        // Trim leading spaces
                        value = value.trimStart();

                        // Set the formatted value back to the input
                        e.target.value = value;
                      }}
                    />

                    {errors.company_name && (
                      <span className="FormError">
                        * This field is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="info-input">
                  <span className="sub-head">Client Name*</span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <input
                      type="text"
                      placeholder="Enter Client Name"
                      className="input-filter"
                      readOnly={submit}
                      {...register("client_name", {
                        required: true,
                        pattern: /^[A-Za-z\s]*$/, // Only alphabetic characters and spaces
                      })}
                      onInput={(e) => {
                        let value = e.target.value;

                        // Remove non-alphabetic characters and special characters
                        value = value.replace(/[^A-Za-z\s]/g, "");

                        // Replace multiple spaces with a single space
                        value = value.replace(/\s{2,}/g, " ");

                        // Trim leading spaces
                        value = value.trimStart();

                        // Set the formatted value back to the input
                        e.target.value = value;
                      }}
                    />
                    {errors.client_name && (
                      <span className="FormError">
                        * This field is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="info-input">
                  <span className="sub-head">Client Designation*</span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <input
                      placeholder="Enter Client Designation"
                      type="text"
                      className="input-filter"
                      {...register("client_disignation", { required: true })}
                      style={{ textTransform: "capitalize" }}
                      readOnly={submit}
                      onInput={(e) => {
                        let value = e.target.value;

                        // Remove non-alphabetic characters and special characters
                        value = value.replace(/[^A-Za-z\s]/g, "");

                        // Replace multiple spaces with a single space
                        value = value.replace(/\s{2,}/g, " ");

                        // Trim leading spaces
                        value = value.trimStart();

                        // Set the formatted value back to the input
                        e.target.value = value;
                      }}
                    />
                    {errors.client_disignation && (
                      <span className="FormError">
                        * This field is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="info-input">
                  <span className="sub-head">Email ID*</span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <input
                      type="email"
                      placeholder="Enter Email Address"
                      className="input-filter"
                      readOnly={submit}
                      {...register("email_address", {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      })}
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^a-z0-9.@]/g, "")
                          .replace(/([,.@])\1+/g, "$1"); // Remove any character not allowed
                      }}
                    />
                    {errors.email_address && (
                      <span className="FormError">
                        {errors.email_address.type === "required"
                          ? "* This field is required"
                          : "* Invalid email address"}
                      </span>
                    )}
                  </div>
                </div>
                <div className="info-input">
                  <span className="sub-head">Telephone No*</span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <input
                      type="text" // Changed from 'number' to 'text'
                      className="input-filter"
                      placeholder="Enter Telephone Number"
                      inputMode="numeric" // Ensures the numeric keypad is shown on mobile devices
                      pattern="\d*" // Restricts the input to numeric values only
                      readOnly={submit}
                      {...register("telephone_number", {
                        required: true,
                        pattern: /^\d{10}$/,
                      })}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes any non-numeric characters
                      }}
                      maxLength={10}
                    />
                    {errors.telephone_number && (
                      <span className="FormError">
                        {errors.telephone_number.type === "required"
                          ? "* This field is required"
                          : "* Invalid mobile number"}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              // className="toggle_div"
              style={{
                borderTop: Access.spiraToken ? `${rem(1)} solid #d7dade` : "",
                borderBottom: Access.spiraToken
                  ? `${rem(1)} solid #d7dade`
                  : "",
                minHeight: Access.spiraToken ? rem(24) : "",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: rem(5),
                }}
              >
                {Access.spiraToken && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={rem(12)}
                    height={rem(11)}
                    viewBox="0 0 12 11"
                    fill="none"
                    onClick={handleRotate}
                    style={{
                      transform:
                        isRotate || !Access.spiraToken
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      cursor: "pointer",
                    }}
                  >
                    <g clipPath="url(#clip0_4169_101676)">
                      <path
                        d="M0.5 2.75H11.5L5.99992 8.24985L0.5 2.75Z"
                        fill="#666666"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4169_101676">
                        <rect
                          width="11"
                          height="11"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </div>

              {(isRotate || !Access.spiraToken) && (
                <div>
                  <div className="w100" style={{ position: "relative" }}>
                    <div className="heading-2">
                      How satisfied are you with the following
                      {!Access.spiraToken &&
                        (errors.quality_rate ||
                          errors.services_experience_rate ||
                          errors.technical_enquires_rate ||
                          errors.team_communication_rate ||
                          errors.team_help_rate ||
                          errors.product_quality_punctuality_rate ||
                          errors.customer_statisfaction_rate) && (
                          <span className="error">* Answer all the fields</span>
                        )}
                    </div>
                    <hr className="line" />
                    <div className="table-wrap feedback-table">
                      <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                      />
                    </div>
                  </div>
                  <div className="w100">
                    <div className="heading-2">
                      Please mark the appropriate answer for each question{" "}
                    </div>
                    <hr className="line" />
                    <div className="input-fields w100">
                      <div className="sub-head-2">
                        Why did you choose us as your service provider?
                        {
                          // feedback.service_provider_rate.length === 0
                          customAlert.service_provider_rate && (
                            <span className="error">
                              * This field is required
                            </span>
                          )
                        }
                      </div>
                      {checkvalue1.map((item) => (
                        <div key={item.key} className="input-checks df">
                          <label className="container-check">
                            <input
                              type="checkbox"
                              checked={feedback[item.pass].includes(item.value)}
                              onChange={(e) => checkboxHandler(e, item.pass)}
                              value={item.value}
                              disabled={submit}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <span className="chk_val">{item.value}</span>
                        </div>
                      ))}
                      <div className="sub-head-2">
                        What did you like best about our team/products/services?
                        {
                          // feedback.about_team_product_service.length === 0
                          customAlert.about_team_product_service && (
                            <span className="error">
                              * This field is required
                            </span>
                          )
                        }
                      </div>
                      {checkvalue2.map((item) => (
                        <div key={item.key} className="input-checks df">
                          <label className="container-check">
                            <input
                              type="checkbox"
                              checked={feedback[item.pass].includes(item.value)}
                              onChange={(e) => checkboxHandler(e, item.pass)}
                              value={item.value}
                              disabled={submit}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <span className="chk_val">{item.value}</span>
                        </div>
                      ))}
                      <div className="other_feedback_box">
                        <div className="sub-head-2">
                          Any other feedback you can give us would be
                          beneficial?
                        </div>
                        <div className="other-feedback">
                          <textarea
                            placeholder="Type Here..."
                            {...register("other_feedback")}
                            readOnly={submit}
                            onInput={(e) => {
                              let value = e.target.value;

                              // Replace multiple spaces with a single space
                              value = value
                                .replace(/\s{2,}/g, " ")
                                .replace(/([,.])\1+/g, "$1")
                                .replace(/[^a-zA-Z,. ]/g, "");

                              // Trim leading spaces
                              value = value.trimStart();

                              // Set the formatted value back to the input
                              e.target.value = value;
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              className="df"
              style={{ justifyContent: "flex-end", paddingTop: rem(30) }}
            >
              <Button
                btnname={
                  Access.spiraToken
                    ? loading
                      ? "Initiating..."
                      : "Initiate Survey"
                    : submit
                    ? "Sending..."
                    : "Send To The Client"
                }
                height={38}
                onclick={Access.spiraToken ? userSubmitHandler : onSubmit}
                type="submit"
                br={5}
                fs={15}
                fw={500}
                border={`${rem(1)} solid #1E6641`}
                disabled={submit}
              />
            </div>
          </form>
        </div>
        {Access.spiraToken && url && (
          <div className="w100 urlSty">
            {
              <>
                <div
                  className="heading-2"
                  style={{
                    paddingBottom: rem(12),
                    paddingTop: rem(20),
                  }}
                >
                  Share the feedback link to the customer{" "}
                  <span style={{ paddingLeft: rem(8) }}>(</span>
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "#4B77E7",
                      lineHeight: "normal",
                    }}
                    data-tooltip-content={tooltip}
                    data-tooltip-id="tooltip-arrow"
                    onClick={handleCopy}
                  >
                    {url}
                  </span>
                  <span>)</span>
                  <span
                    className="copy-icon "
                    data-tooltip-content={tooltip}
                    data-tooltip-id="tooltip-arrow"
                    onClick={handleCopy}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={rem(16)}
                      height={rem(16)}
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_912_44734)">
                        <path
                          d="M10.6668 0.666016H2.66683C1.9335 0.666016 1.3335 1.26602 1.3335 1.99935V11.3327H2.66683V1.99935H10.6668V0.666016ZM12.6668 3.33268H5.3335C4.60016 3.33268 4.00016 3.93268 4.00016 4.66602V13.9993C4.00016 14.7327 4.60016 15.3327 5.3335 15.3327H12.6668C13.4002 15.3327 14.0002 14.7327 14.0002 13.9993V4.66602C14.0002 3.93268 13.4002 3.33268 12.6668 3.33268ZM12.6668 13.9993H5.3335V4.66602H9.00016H12.6668V13.9993Z"
                          fill="#141D46"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_912_44734">
                          <rect width={rem(16)} height={rem(16)} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </div>
              </>
            }
          </div>
        )}
      </div>

      {submit && (
        <div
          className="main-container df justify-center feedback-submission"
          style={{
            position: "fixed",
            top: "0%",
            height: "100vh",
            width: "100vw",
            zIndex: "1111",
            background: "rgba(168, 193, 181, 0.34)",
            backdropFilter: "blur(2px)",
          }}
        >
          <div className={`circle-loader ${isComplete ? "load-complete" : ""}`}>
            {isComplete && <div className="feed-checkmark draw"></div>}
          </div>
        </div>
      )}
      <SnackBar
        message={error}
        severity={"error"}
        handleClose={handletoast}
        Open={toast.error}
      />
      <SnackBar
        message={error}
        severity={"success"}
        handleClose={handletoast}
        Open={toast.submit}
      />
      <SnackBar
        message={error}
        severity={"warning"}
        handleClose={handletoast}
        Open={toast.warning}
      />
      <Tooltip
        id="tooltip-arrow"
        place="bottom"
        style={{
          fontSize: rem(16),
          padding: rem(7),
          zIndex: "2",
          borderRadius: rem(5),
        }}
      />
    </section>
  );
}
