import React, { useEffect, useState } from "react";
import { Empty } from "antd";
import searchIcon from "./../../Assets/UserIcon/usersearchicon.svg";
import { useNavigate } from "react-router-dom";
import _http from "../../Utils/Api/_http";
import SnackBar from "../../Components/SnackBar";
import { rem } from "../../Components/Rem_func";
import document from "./../../Assets/Quiz/Manual.svg";
import csv from "./../../Assets/Quiz/csv.svg";
import xlsx from "./../../Assets/Quiz/xlsx.svg";
const QuizRecent = () => {
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [searchValue, SetSearchValue] = useState("");
  const [apidata, setApidata] = useState([]);
  const [filteredData, setFilteredData] = useState();

  //   const [data, setData] = useState([]);

  const RecentQuestion = async () => {
    setLoading(true);
    try {
      const response = await _http.get(`/quiz/Get-question-pools`);
      setApidata(response.data);
      setFilteredData(response.data);
      setToast(
        (response.data.message && { submit: true }) ||
          (response.data.error && { error: true })
      );
      setError(response.data.message || response.data.error);
    } catch (er) {
      setToast({ er: true });
      setError("Error Fetching  Data");
    }
    setLoading(false);
  };
  useEffect(() => {
    RecentQuestion();
  }, []);

  const navigate = useNavigate();

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };
  const calculateTime = (registeredTime) => {
    const registeredDate = new Date(registeredTime);
    const currentDate = new Date();

    const diffMs = currentDate - registeredDate; // Difference in milliseconds
    const totalSeconds = Math.floor(diffMs / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const totalDays = Math.floor(totalHours / 24);

    if (totalDays > 0) {
      return `${totalDays} day${totalDays > 1 ? "s" : ""} ago`;
    } else if (totalHours > 0) {
      return `${totalHours} hour${totalHours > 1 ? "s" : ""} ago`;
    } else if (totalMinutes > 0) {
      return `${totalMinutes} minute${totalMinutes > 1 ? "s" : ""} ago`;
    } else {
      return `${totalSeconds} second${totalSeconds > 1 ? "s" : ""} ago`;
    }
  };

  const handleReportTable = (id) => {
    navigate(`/SpiraQuiz/ReportTable?id=${id}`);
  };

  useEffect(() => {
    const filtered = apidata?.filter((data) =>
      data?.pool_name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchValue, apidata]);
  return (
    <section className="up-31" style={{ display: "block" }}>
      <div className="container-head df" style={{ paddingBottom: rem(22) }}>
        <p className="title">{"QRE Type Report List"}</p>
      </div>
      <div className="data1" style={{ height: "90%" }}>
        <div className="btn1" style={{ justifyContent: "flex-end" }}>
          <div className="quiz-sh-fl ">
            {apidata?.length > 0 && (
              <div className="user-search df">
                <input
                  type="text"
                  placeholder="Search"
                  name="user"
                  value={searchValue}
                  onChange={(e) => SetSearchValue(e.target.value)}
                />
                <img src={searchIcon} alt="icon" />
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <div className="spinners"></div>
        ) : (
          <>
            {filteredData?.length > 0 ? (
              <div className="up-box1">
                {filteredData?.map((item, index) => (
                  <div
                    className="q-details"
                    key={item.id}
                    onClick={() => {
                      handleReportTable(item.id);
                    }}
                    style={{ paddingRight: rem(10) }}
                  >
                    <div className="q-title">
                      <div className="q-pooler">
                        <img
                          src={
                            item.source ===
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              ? xlsx
                              : item.source === "text/csv"
                              ? csv
                              : document
                          }
                          alt=""
                        />
                        <span>
                          {item?.pool_name}{" "}
                          <p>{`${calculateTime(item.registered_time)}`}</p>
                        </span>
                      </div>

                      <div className="q-para justify-end">
                        <p className="schedule">
                          Scheduled On{" "}
                          <span>
                            {item?.schedule_time
                              ? new Date(item?.schedule_time)?.toLocaleString()
                              : "Not Scheduled"}
                          </span>
                        </p>

                        <p className="duration">
                          Duration
                          <span>{item?.duration_in_minutes}</span>Mins
                        </p>

                        <p className="questions">
                          No of Questions
                          <span>{item?.question_count}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Empty></Empty>
            )}
          </>
        )}
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={"Quiz Uploaded Successfully"}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </section>
  );
};

export default QuizRecent;
