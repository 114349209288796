import React, { useState, useEffect, useRef } from "react";
import "./SelectField.scss";
import { rem } from "../Rem_func";
import arrowIcon from "../../Assets/selectfield-arrow.svg";

const SelectSearch = ({
  value = [], // Initialize value as an empty array for multiple selections
  onChange,
  options,
  label,
  name,
  Placeholder,
  dropdownPositionProp,
  height,
  hasError,
  readOnly,
  multiple = false, // New prop for multiple selection
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    multiple ? (Array.isArray(value) ? value : []) : value
  );
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState(
    dropdownPositionProp || "bottom"
  );
  const [isFocused, setIsFocused] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (multiple) {
      setSelectedOptions(Array.isArray(value) ? value : []);
    } else {
      setSelectedOptions(value || ""); // Update for single selection
    }
  }, [value, options, multiple]); // Ensure these dependencies are correct

  useEffect(() => {
    if (multiple) {
      const validSelectedOptions = selectedOptions.filter((selectedOption) =>
        options.includes(selectedOption)
      );
      if (validSelectedOptions.length !== selectedOptions.length) {
        setSelectedOptions(validSelectedOptions);
        onChange(validSelectedOptions);
      }
    }

    // Update filtered options
    setFilteredOptions(options);
  }, [options, multiple, selectedOptions, onChange]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    if (multiple) {
      // Handle multiple select
      let newSelectedOptions = [...selectedOptions];
      if (newSelectedOptions.includes(option)) {
        newSelectedOptions = newSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        );
      } else {
        newSelectedOptions.push(option);
      }

      setSelectedOptions(newSelectedOptions);
      onChange(newSelectedOptions);
    } else {
      setSelectedOptions(option);
      onChange(option);
      setDropdownVisible(false);
    }
  };

  const handleButtonClick = () => {
    // if (!dropdownPositionProp && containerRef.current) {
    //   const containerRect = containerRef.current.getBoundingClientRect();
    //   const windowHeight =
    //     window.innerHeight || document.documentElement.clientHeight;

    //   // Check if there is enough space below the container
    //   if (windowHeight - containerRect.bottom < 0) {
    //     setDropdownPosition("top");
    //   } else {
    //     setDropdownPosition("bottom");
    //   }
    // }

    setDropdownVisible((prev) => !prev);
    setFilteredOptions(options);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const isDrawBorder = () => {
    return multiple
      ? selectedOptions.length === 0
      : selectedOptions === null || selectedOptions === "";
  };

  // Convert multiple selected options to a comma-separated string
  const displayValue = multiple
    ? selectedOptions.join(", ")
    : selectedOptions || "";

  return (
    <div
      className="selectable-list-container frm-group"
      ref={containerRef}
      style={{ position: "relative" }}
    >
      {label && <div className="sub-head">{label}</div>}
      <div
        className={`frm-field ${
          (isFocused || hasError) && isDrawBorder() ? "draw-border" : ""
        }`}
        style={{
          cursor: "pointer",
          height: rem(height),
          pointerEvents: readOnly ? "none" : "auto",
        }}
        onClick={handleButtonClick}
      >
        <input
          type="text"
          placeholder={Placeholder}
          value={displayValue} // Use the comma-separated value for multiple selections
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className="frm-control"
          style={{
            color: selectedOptions ? "#000" : "#7B8499",
            textTransform: "capitalize",
          }}
          id={name}
          autoComplete="off"
          readOnly
        />
        <img
          src={arrowIcon}
          alt=""
          style={{ width: rem(11), height: rem(11) }}
        />
      </div>
      <ul
        ref={dropdownRef}
        className={`uldropdown ${dropdownVisible ? "active" : ""} ${
          dropdownPosition === "top" ? "top" : ""
        }`}
        style={dropdownPosition === "top" ? { bottom: rem(height) } : {}}
      >
        {filteredOptions?.length > 0 ? (
          filteredOptions.map((option, index) => (
            <li
              key={index}
              className={`dropdown-option ${
                multiple
                  ? selectedOptions.includes(option)
                    ? "selected"
                    : ""
                  : selectedOptions === option
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleOptionClick(option)}
              style={{
                textTransform: "capitalize",
                backgroundColor: multiple
                  ? selectedOptions.includes(option)
                    ? "#f0f0f0"
                    : ""
                  : selectedOptions === option
                  ? "#f0f0f0"
                  : "",
                color: multiple
                  ? selectedOptions.includes(option)
                    ? "#000"
                    : "#555"
                  : selectedOptions === option
                  ? "#000"
                  : "#555",
                pointerEvents: readOnly ? "none" : "auto",
              }}
            >
              {option}
            </li>
          ))
        ) : (
          <li className="dropdown-option">Option not found</li>
        )}
      </ul>
    </div>
  );
};

export default SelectSearch;
