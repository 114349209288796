import React, { useCallback, useEffect, useState } from "react";
import spiraLogo2 from "../../Assets/QuizMobile/spiraLogo2.svg";
import _nonAuthHttp from "../../Utils/Api/_nonAuthHttp";
import FullStarRating from "../../Assets/QuizMobile/FullStarRating.svg";
import MobileLoader from "./MobileLoader";
import SnackBar from "../../Components/MobSnackBar";

export default function StartQuiz({
  setQuizState,
  regData,
  quizState,
  getPool,
}) {
  const [quizs, setQuizs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [mapObj, setMapObj] = useState({
    pool_name: "",
    duration_in_minutes: "",
    last_attended_time: "",
    average_rating: "",
    participant_count: "",
    schedule_time: "",
    question_count: "",
  });
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const participant_name = sessionStorage.getItem("participantsName");
  // const poolNameId = JSON.parse(sessionStorage.getItem("poolNameId"));

  const findContinue = regData?.attended_quizzes?.find((x) => {
    return x.pool_id === Number(getPool);
  });

  const fetchQuizData = useCallback(async () => {
    if (!quizState.quiz) {
      return;
    }
    setLoading(true);
    try {
      const res = await _nonAuthHttp.get("/quiz/Get-question-pools");
      setQuizs(res.data);
    } catch (error) {
      console.log(error);
      setError("Internal Server Error");
      setToast({ error: true });
    } finally {
      setLoading(false);
    }
  }, [quizState.quiz]); // Empty dependency array means this function won't change between renders

  useEffect(() => {
    fetchQuizData();
  }, [fetchQuizData]);

  let findIdHandler;

  useEffect(() => {
    findIdHandler = quizs?.find((x) => {
      return x?.id === Number(getPool);
    });

    console.log(findIdHandler);

    if (findIdHandler) {
      setShowBtn(true);
      sessionStorage.setItem("pool_id", findIdHandler?.id); // This will log the id of the first matching item
      sessionStorage.setItem("pool_title", findIdHandler?.pool_name);
      setMapObj({
        pool_name: findIdHandler?.pool_name,
        duration_in_minutes: findIdHandler?.duration_in_minutes,
        last_attended_time: findIdHandler?.last_attended_time,
        average_rating: findIdHandler?.average_rating,
        participant_count: findIdHandler?.participant_count,
        schedule_time: findIdHandler?.schedule_time,
        question_count: findIdHandler?.question_count,
      });
    }
  }, [quizs, getPool]);

  const clickHandler = () => {
    setQuizState("question");

    sessionStorage.setItem("register", false);
    sessionStorage.setItem("quiz", false);
    sessionStorage.setItem("question", true);
    sessionStorage.setItem("ranking", false);
  };

  const continueQuizHandler = () => {
    setQuizState("question");

    sessionStorage.setItem("register", false);
    sessionStorage.setItem("quiz", false);
    sessionStorage.setItem("question", true);
    sessionStorage.setItem("ranking", false);
  };

  function formatLastUpdatedDate(dateString) {
    // Parse the API date string to a Date object
    const date = new Date(dateString);

    // Define options for date formatting
    const options = { year: "numeric", month: "short", day: "numeric" };

    // Format the date
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    // Return the formatted string
    return `Last Updated: ${formattedDate}`;
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            background: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {" "}
          <MobileLoader />{" "}
        </div>
      ) : (
        <div className="quizQuestion_container">
          <div className="upper_quiz">
            <img src={spiraLogo2} alt="error" />
            <p className="name_title">Hello, {participant_name}</p>
          </div>
          <div className="lower_quiz">
            {showBtn && (
              <div className="welding_quiz">
                {!findContinue && (
                  <div>
                    <div className="quiz_play">
                      <div className="left_quiz">
                        <p className="quiz1">{mapObj.pool_name}</p>
                        <div
                          style={{
                            display: "flex",
                            paddingTop: "5px",
                          }}
                        >
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M11.5133 13.4401H4.63327C4.15327 13.4401 3.7666 13.0534 3.7666 12.5734V3.96676C3.7666 3.48676 4.15327 3.1001 4.63327 3.1001H11.5133C11.9933 3.1001 12.3799 3.48676 12.3799 3.96676V12.5734C12.3799 13.0534 11.9933 13.4401 11.5133 13.4401Z"
                                stroke="#999999"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6.04663 6.95337H8.76663"
                                stroke="#999999"
                                strokeWidth="1.2"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6.04663 9.58667H10.1"
                                stroke="#999999"
                                strokeWidth="1.2"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <p className="ques">
                            {mapObj.question_count} Question
                          </p>
                        </div>
                        <div style={{ display: "flex", paddingTop: "5px" }}>
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M8.30669 14.2132C11.5689 14.2132 14.2134 11.5687 14.2134 8.30657C14.2134 5.04441 11.5689 2.3999 8.30669 2.3999C5.04453 2.3999 2.40002 5.04441 2.40002 8.30657C2.40002 11.5687 5.04453 14.2132 8.30669 14.2132Z"
                                stroke="#999999"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8.30664 5.08008V8.30675L10.2933 9.25341"
                                stroke="#999999"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <p className="ques">
                            {mapObj.duration_in_minutes} min
                          </p>
                        </div>
                        <div style={{ display: "flex", paddingTop: "5px" }}>
                          <p className="total_ques">
                            Attempts:{mapObj.participant_count}
                          </p>
                          <p className="total_ques">
                            Last Updated:{" "}
                            {mapObj.last_attended_time
                              ? formatLastUpdatedDate(mapObj.last_attended_time)
                              : "No Data Found"}
                          </p>
                        </div>
                      </div>
                      <div className="right_quiz">
                        <div className="rate_center">
                          <img
                            src={FullStarRating}
                            alt="error"
                            className="rate-img"
                          />
                          <p>{mapObj.average_rating}</p>
                        </div>
                      </div>
                    </div>

                    <div className="btn_quiz">
                      <button className="quizBtn" onClick={clickHandler}>
                        Start Quiz
                      </button>
                    </div>
                  </div>
                )}
                {findContinue && (
                  <div>
                    <p className="cont_quiz">Continue Quiz</p>
                    <div className="quiz_result">
                      <div className="left_quiz">
                        <p className="quiz1">{findContinue.pool_name}</p>
                        <div
                          style={{
                            display: "flex",
                            paddingTop: "5px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M11.5133 13.4401H4.63327C4.15327 13.4401 3.7666 13.0534 3.7666 12.5734V3.96676C3.7666 3.48676 4.15327 3.1001 4.63327 3.1001H11.5133C11.9933 3.1001 12.3799 3.48676 12.3799 3.96676V12.5734C12.3799 13.0534 11.9933 13.4401 11.5133 13.4401Z"
                              stroke="#999999"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.04663 6.95337H8.76663"
                              stroke="#999999"
                              strokeWidth="1.2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.04663 9.58667H10.1"
                              stroke="#999999"
                              strokeWidth="1.2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p className="ques">
                            {regData?.attempted_questions}/
                            {findContinue?.question_count} Questions
                          </p>
                        </div>
                        <div style={{ display: "flex", paddingTop: "5px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M8.30669 14.2132C11.5689 14.2132 14.2134 11.5687 14.2134 8.30657C14.2134 5.04441 11.5689 2.3999 8.30669 2.3999C5.04453 2.3999 2.40002 5.04441 2.40002 8.30657C2.40002 11.5687 5.04453 14.2132 8.30669 14.2132Z"
                              stroke="#999999"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.30664 5.08008V8.30675L10.2933 9.25341"
                              stroke="#999999"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p className="ques">
                            {mapObj.duration_in_minutes} min
                          </p>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <button
                            className="continue_btn"
                            onClick={continueQuizHandler}
                          >
                            Continue Quiz
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!showBtn && (
              <div className="no_quiz_img">
                <div className="show_img">
                  <p>We're sorry, the quiz isn't available right now.</p>
                </div>
              </div>
            )}
            <SnackBar
              message={errors}
              severity={"error"}
              handleClose={handletoast}
              Open={toast?.error}
            />
            <SnackBar
              message={errors}
              severity={"success"}
              handleClose={handletoast}
              Open={toast?.submit}
            />
          </div>
        </div>
      )}
    </>
  );
}
