import React from "react";
import SalesTrackers from "../STcomponents/SalesTracker/SalesTracker";
import EmailTable from "./EmailTable.jsx";

const Report = () => {
  return (
    <>
      <SalesTrackers
        title={"Email"}
        UserDataTable={EmailTable}
        api={"/api/get-all-emails"}
        emailE={true}
      />
    </>
  );
};

export default Report;
