import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./Dashboard.scss";
import _http from "../../../Utils/Api/_http";
import { rem } from "../../../Components/Rem_func";
// import { rem } from "../../../Components/Rem_func";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BranchBarChart = ({ formatNumber }) => {
  const [branch, setBranch] = useState("");
  const fetchData = async () => {
    try {
      const response = await _http.get("/api/awared-vs-quoted");
      setBranch(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const data = {
    labels: ["Abu Dhabi", "Dubai", "Oman"],
    datasets: [
      {
        type: "bar",
        label: "",
        data: [
          branch?.Abu_Dhabi?.total_waiting_value,
          branch?.Dubai?.total_waiting_value,
          branch?.Oman?.total_waiting_value,
        ],
        // backgroundColor: "#1e6641",
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }
          const gradient = ctx.createLinearGradient(0, 0, 0, chartArea.bottom);
          gradient.addColorStop(1, "#E3FFF0");

          gradient.addColorStop(0.21, "#1E6641");

          return gradient;
        },
        // borderWidth: 1,
        borderRadius: 10,
        barPercentage: 0.5,
        // categoryPercentage: 0.8,
        order: 2, // Ensures the line is drawn on top of the bars
        yAxisID: "y",
      },
      {
        type: "line",
        label: false,
        data: [
          branch?.Abu_Dhabi?.total_placed_value,
          branch?.Dubai?.total_placed_value,
          branch?.Oman?.total_placed_value,
        ],

        borderColor: "#878787",
        borderWidth: 1.5,
        pointBackgroundColor: "#FFF",
        pointBorderColor: "#FFF",
        pointRadius: 3,
        fill: false,
        order: 1, // Ensures the line is drawn on top of the bars
        yAxisID: "y1",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 8,
          },
          color: "#A3AED0",
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
      y: {
        grid: {
          display: false,
          color: "#A3AED0",
        },
        ticks: {
          font: {
            size: 10,
          },
          color: "#A3AED0",
          beginAtZero: true,
          callback: function (value) {
            return `${formatNumber(value)}`; // Add '%' after the value for percentage
          },
        },
        border: {
          display: false, // Hide the y-axis line
        },
        title: {
          display: false,
        },
      },
      y1: {
        position: "right",
        beginAtZero: true,
        grid: {
          display: false,
          drawOnChartArea: false,
        },
        ticks: {
          display: false,
          font: {
            size: 10,
          },
          color: "#A3AED0",
          callback: function (value) {
            return value; // Add '%' after the value for percentage
          },
        },
        title: {
          display: false,
          //   text: "successful conversion divided by opportunity ",
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            if (tooltipItem.datasetIndex === 1) {
              return `Awarded: ${formatNumber(tooltipItem.raw)} AED`;
            }
            return `Quoted: ${formatNumber(tooltipItem.raw)} AED`;
          },
        },
      },
    },
  };

  return (
    <div className="branch-bar-chart revenue-graph df flexColumn">
      <div className="revenue-title df justify-center">Quoted Vs Awarded</div>
      <div className="df revenue-label">
        <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#1E6641"
            />
          </svg>
          Quoted Value(AED)
        </div>
        <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#BEC8D0"
            />
          </svg>
          Awarded Value(AED)
        </div>
      </div>
      <div className="barChat" style={{ height: rem(135), width: rem(210) }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default BranchBarChart;
