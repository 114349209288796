import React, { useState, useEffect } from "react";
import "./STeditPopup.scss";
import { Modal } from "antd";
import InputField from "../../../../Components/InputField/InputField";
import { rem } from "../../../../Components/Rem_func";
import TextField from "../../../../Components/TextField/TextField";

const EditPopup = ({ user, handleClose, open }) => {
  // State to manage user data in the form
  const [userData] = useState({
    ID: user.id,
    sales_person_name: user.from_person_name,
    from_email: user.from_email,
    client_email: user.client_email,
    from_subject: user.from_subject,
    from_email_time: user.from_email_time
      ? new Date(user.from_email_time).toLocaleString()
      : "",
    plain_text: user.plain_body,
  });

  useEffect(() => {
    if (open) {
      const firstFocusableElement = document.querySelector(".user-popup input");
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Modal
        centered
        open={open}
        onCancel={handleClose}
        width={rem(808)}
        className="edit-popup"
      >
        <div className="user-popup">
          <div className="flexBox popup sales-popup">
            <div style={{ width: rem(300) }}>
              <InputField
                labelName={"Email From"}
                type={"text"}
                placeholder={""}
                fieldData={userData.sales_person_name.toLowerCase()}
                readOnly={true}
                styles={{ textTransform: "capitalize" }}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <InputField
                labelName={"Email"}
                type={"text"}
                placeholder={""}
                fieldData={userData.from_email}
                readOnly={true}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <InputField
                labelName={"From Date/Time"}
                type={"text"}
                placeholder={""}
                fieldData={userData.from_email_time}
                readOnly={true}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <TextField
                labelName={"Mail Subject"}
                type="text"
                fieldData={userData?.from_subject}
                readOnly={true}
                height={rem(60)}
              />
            </div>
            <div style={{ width: "100%" }}>
              <TextField
                labelName={"Mail Body"}
                type="text"
                fieldData={userData?.plain_text}
                readOnly={true}
                height={rem(240)}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditPopup;
