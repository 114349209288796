import React, { useState, useEffect } from "react";
import FeedbackTable from "./FeedbackTable.jsx";
import { Popover, Space } from "antd";
import down from "../../Assets/EnquiresIcon/down.svg";
import filter from "../../Assets/EnquiresIcon/filter.svg";
import filteron from "../../Assets/EnquiresIcon/filteron.svg";
import greenDown from "../../Assets/EnquiresIcon/green_down.svg";
import "../SalesTracker/STcomponents/SalesTracker/SalesTracker.scss";
import "./Feedback.scss";
import FilterFeedback from "./FilterFeedback.jsx";
import SizeChanger from "../../Components/PageSizeChanger/SizeChanger.jsx";
import CrossIcon from "../../Components/Svg/CrossIcon.jsx";
import { rem } from "../../Components/Rem_func.jsx";
const Feedback = () => {
  const [openfilter, setOpenfilter] = useState(false);
  const [filterOn, setFilterOn] = useState(false);
  const [filteredData, setFilteredData] = useState({
    form_timestamp_from: "",
    form_timestamp_to: "",
    company_name: "",
    client_name: "",
    client_disignation: "",
    telephone_number: "",
    email_address: "",
    other_feedback: "",
    quality_rate: "",
    services_experience_rate: "",
    technical_enquires_rate: "",
    team_communication_rate: "",
    team_help_rate: "",
    product_quality_punctuality_rate: "",
    customer_statisfaction_rate: "",
    service_provider_rate: "",
    about_team_product_service: "",
  });
  // Function to handle filter popover visibility change
  const handleOpenChange = (newOpen) => {
    setOpenfilter(newOpen);
  };

  // Function to hide filter popover
  const hide = () => {
    setOpenfilter(false);
  };

  // Function to reset filters

  // Function to handle filter criteria change
  // const onFilter = (data) => {
  //   setFilteredData(data);
  //   const isFilterOn =
  //     data.form_date !== "" ||
  //     data.company_name !== "" ||
  //     data.client_name !== "" ||
  //     data.client_disignation !== "" ||
  //     data.telephone_number !== "" ||
  //     data.email_address !== "" ||
  //     data.other_feedback !== "" ||
  //     data.quality_rate !== "" ||
  //     data.services_experience_rate !== "" ||
  //     data.technical_enquires_rate !== "" ||
  //     data.team_communication_rate !== "" ||
  //     data.team_help_rate !== "" ||
  //     data.product_quality_punctuality_rate !== "" ||
  //     data.customer_statisfaction_rate !== "" ||
  //     data.service_provider_rate !== "" ||
  //     data.about_team_product_service !== "";

  //   setFilterOn(isFilterOn);
  // };
  const [count, setCount] = useState("50");
  const [totalcount, setTotalCount] = useState("0");
  const PageCount = ["10", "20", "50", "100"];

  const items = PageCount.map((page) => ({
    key: page,
    label: (
      <div
        style={{ width: "100%" }}
        className="pageCount"
        onClick={() => setCount(page)}
      >
        {page}
      </div>
    ),
  }));

  // Function to check if any filters are applied
  const checkIfFiltersAreApplied = (filters) => {
    return Object?.values(filters)?.some((value) => value !== "");
  };

  // Update filterOn whenever filteredData changes
  useEffect(() => {
    setFilterOn(checkIfFiltersAreApplied(filteredData));
  }, [filteredData]);
  const removeThisFilter = (key) => {
    setFilteredData((prev) => ({ ...prev, [key]: "" }));
  };
  return (
    <section>
      <div className="container1" style={{ paddingTop: rem(53) }}>
        <div className="container-head df">
          <p className="title">Feedback Stats</p>
          <div className="total-count-box">{totalcount}</div>
        </div>
        <div className="container-head2 df">
          <SizeChanger count={count} items={items} />
          <Popover
            trigger="click"
            open={openfilter}
            placement="bottomRight"
            onOpenChange={handleOpenChange}
            content={
              <FilterFeedback
                hide={hide}
                // onFilter={onFilter}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
              />
            }
          >
            <Space>
              <span
                className="filter-head"
                data-tooltip-id="tooltip-arrow"
                data-tooltip-content="Filter"
              >
                <img src={filterOn ? filteron : filter} alt="icon" />{" "}
                <img src={filterOn ? greenDown : down} alt="icon" />
              </span>
            </Space>
          </Popover>
        </div>
        <div className="df filter-section">
          {filterOn && filteredData && (
            <div
              className="filtered-data-item df "
              style={{ paddingBottom: rem(6) }}
            >
              {Object.entries(filteredData).map(
                ([key, value]) =>
                  value && (
                    <span key={key} className="df filtered-option">
                      <span className="filtered-value">
                        {key
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase())}
                        :{value || "N/A"}
                      </span>

                      <span
                        onClick={() => removeThisFilter(key)}
                        style={{ cursor: "pointer" }}
                      >
                        <CrossIcon />
                      </span>
                    </span>
                  )
              )}
            </div>
          )}
        </div>
        <FeedbackTable
          filteredData={filteredData}
          totalCount={setTotalCount}
          defaultPageSize={count}
        />
      </div>
    </section>
  );
};

export default Feedback;
