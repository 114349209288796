import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./Dashboard.scss";
import { rem } from "../../../Components/Rem_func";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BranchBarChart = ({ formatNumber, users }) => {
  const [branch, setBranch] = useState({
    Abu_Dhabi: { total_waiting_value: 0, total_placed_value: 0 },
    Dubai: { total_waiting_value: 0, total_placed_value: 0 },
    Oman: { total_waiting_value: 0, total_placed_value: 0 },
  });
  useEffect(() => {
    const calculateTotals = () => {
      const locations = ["Abu Dhabi", "Dubai", "Oman"];
      const initialData = {
        Abu_Dhabi: { total_waiting_value: 0, total_placed_value: 0 },
        Dubai: { total_waiting_value: 0, total_placed_value: 0 },
        Oman: { total_waiting_value: 0, total_placed_value: 0 },
      };

      const result = users.reduce((acc, user) => {
        const location = user.location;
        if (locations.includes(location)) {
          const waitingValue = parseFloat(
            user.currency_value?.replace(/[^0-9.-]+/g, "")
          );
          const placedValue = parseFloat(
            user.final_order_currency?.replace(/[^0-9.-]+/g, "")
          );

          // Add to totals only if the values are numbers
          acc[location.replace(" ", "_")].total_waiting_value += !isNaN(
            waitingValue
          )
            ? waitingValue
            : 0;
          acc[location.replace(" ", "_")].total_placed_value += !isNaN(
            placedValue
          )
            ? placedValue
            : 0;
        }
        return acc;
      }, initialData);

      setBranch(result);
    };

    calculateTotals();
  }, [users]);

  //
  const data = {
    labels: ["Abu Dhabi", "Dubai", "Oman"],
    datasets: [
      {
        type: "bar",
        label: "",
        data: [
          branch?.Abu_Dhabi?.total_waiting_value,
          branch?.Dubai?.total_waiting_value,
          branch?.Oman?.total_waiting_value,
        ],
        backgroundColor: "#558B70",
        borderRadius: 10,
        barPercentage: 0.5,
        order: 2,
        xAxisID: "x", // Now acts as the y-axis due to indexAxis
      },
      {
        type: "line",
        label: false,
        data: [
          branch?.Abu_Dhabi?.total_placed_value,
          branch?.Dubai?.total_placed_value,
          branch?.Oman?.total_placed_value,
        ],

        borderColor: "#c2c2c2",
        borderWidth: 1.5,
        pointBackgroundColor: "#FFF",
        pointBorderColor: "#c2c2c2",
        pointRadius: 3,
        fill: false,
        order: 1,
        xAxisID: "x", // Use the same x-axis ID to ensure alignment with bars
      },
    ],
  };

  const options = {
    indexAxis: "y", // Flip the axes
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          font: { size: 10 },
          color: "#636363",
          beginAtZero: true,
          callback: function (value) {
            return `${formatNumber(value)}`;
          },
        },
        border: { display: false },
      },
      y: {
        grid: { display: false },
        ticks: {
          font: { size: 14 },
          color: "#636363",
        },
        border: { display: false },
      },
      y1: {
        display: false, // Disable the second y-axis to prevent duplicate labels
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            if (tooltipItem.datasetIndex === 1) {
              return `Awarded: ${formatNumber(tooltipItem.raw)} `;
            }
            return `Quoted: ${formatNumber(tooltipItem.raw)} `;
          },
        },
      },
    },
  };

  return (
    <div className="branch-bar-chart revenue-graph df flexColumn">
      <div className="revenue-title df justify-center">Quoted Vs Awarded</div>

      <div className="barChat" style={{ height: rem(195), width: "90%" }}>
        <Bar data={data} options={options} />
      </div>
      <div className="df revenue-label">
        <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#558B70"
            />
          </svg>
          Quoted Value
        </div>
        <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#BEC8D0"
            />
          </svg>
          Awarded Value
        </div>
      </div>
    </div>
  );
};

export default BranchBarChart;
