// Importing necessary dependencies and styles
import React from "react";
import "./Button.scss";
import { rem } from "../../Components/Rem_func";

// Define the base font size

/**
 * Converts pixels to rem units
 * @param {number} pixels - The pixel value to convert
 * @param {number} context - The context for conversion (defaults to baseFontSize)
 * @returns {string} - The value in rem units
 */

// Functional component for a custom button
const Button = ({
  btnname, // Text content of the button
  onclick, // Function to be called when the button is clicked
  width, // Width of the button
  height, // Height of the button
  fs, // Font size of the button text
  fw, // Font weight of the button text
  cl, // Text color of the button text
  bg, // Background color of the button
  border, // Border style of the button
  box_s, //BoxShadow of the button
  disabled, //to disable button
  br, //border radius
}) => {
  // Create the style object dynamically
  const style = {
    ...(typeof width === "number" && { width: rem(width) }), // Custom width style
    ...(typeof height === "number" && { height: rem(height) }), // Custom height style
    ...(typeof fs === "number" && { fontSize: rem(fs) }), // Custom font size style
    ...(typeof br === "number" && { borderRadius: rem(br) }), // Custom border radius style
    fontWeight: fw, // Custom font weight style
    boxShadow: box_s, //Custom box shadow
    background: bg, // Custom background color style
    color: cl, // Custom text color style
    border: border, // Custom border style
  };

  // Rendering a button with specified styles and onClick handler
  return (
    <button
      onClick={onclick} // Event handler for button click
      className="spira-ui-btn" // CSS class for styling the button
      style={style} // Applying the dynamically created style object
      disabled={disabled}
    >
      {/* Displaying the text content of the button */}
      {btnname}
    </button>
  );
};

// Exporting the Button component
export default Button;
