import React from "react";
import { rem } from "../Rem_func";
const CrossIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={rem(12)}
      height={rem(12)}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.14 15.56L7.78 9.19L1.42 15.56L0 14.14L6.37 7.78L0 1.42L1.42 0L7.78 6.37L14.14 0.0100002L15.55 1.42L9.19 7.78L15.55 14.14L14.14 15.56Z"
        fill="#878787"
      />
    </svg>
  );
};

export default CrossIcon;
