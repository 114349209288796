import React from "react";
import "./Home.scss";
import UserHome from "./UserHome/UserHome";
import QualityHome from "./QualityHome/QualityHome";
import QuoteHome from "./QuoteHome/QuoteHome";
import SalesHome from "./SalesHome/SalesHome";
import FeedbackStats from "./FeedbackStats/FeedbackStats";
import { useSelector } from "react-redux";
import { selectUserData } from "../../Redux/Reducers";

function Home() {
  const access = useSelector(selectUserData);

  return (
    <section className="home_container flexColumn df">
      <div className="df box">
        <SalesHome access={access} />
        <FeedbackStats />
      </div>
      {access.role_name !== "employee" && (
        <div className="df box">
          <QuoteHome />
          <QualityHome />
          <UserHome />
        </div>
      )}
    </section>
  );
}

export default Home;
