import React from "react";
import SalesTracker from "../STcomponents/SalesTracker/SalesTracker";
import AwaitingOrderTable from "./AwaitingOrderTable";

const AwaitingOrder = () => {
  return (
    <>
      <SalesTracker
        title={"Awaiting Order"}
        UserDataTable={AwaitingOrderTable}
        api={"/api/users_db"}
      />
    </>
  );
};

export default AwaitingOrder;
