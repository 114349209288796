import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./SalesTracker.scss";
import EditPopup from "../STeditPopup/STeditPopup";
// import EditPopup1 from "../STeditPopup/STeditPopup1";
import down from "../../../../Assets/EnquiresIcon/down.svg";
import filter from "../../../../Assets/EnquiresIcon/filter.svg";
import filteron from "../../../../Assets/EnquiresIcon/filteron.svg";
import greenDown from "../../../../Assets/EnquiresIcon/green_down.svg";
import _http from "../../../../Utils/Api/_http";
import FilterEnquires from "../Filter/FilterEnquires";
import { Space } from "antd";
import SnackBar from "../../../../Components/SnackBar";
import SizeChanger from "../../../../Components/PageSizeChanger/SizeChanger";
// import { rem } from "../../../../Components/Rem_func";
import { selectUserData, setdatacount } from "../../../../Redux/Reducers";
import { useDispatch, useSelector } from "react-redux";
import EmailPopup from "../STeditPopup/EmailPopup";

import { rem } from "../../../../Components/Rem_func";
import searchIcon from "../../../../Assets/UserIcon/usersearchicon.svg";
import SalesHeader from "../../DashBoard/SalesHeader";
import FilterSlider from "../../../../Components/FilterSlider/FilterSlider";
import SelectSearch from "../../../../Components/SelectFields/SelectField";
import SalesCards from "../SalesCards";
import Button from "../../../../Components/Button/Button";
import RejectPopUp from "../STeditPopup/RejectPopUp";
import RejectConfirmPopup from "../STeditPopup/RejectConfirmPopup";
const SalesTracker = ({
  title,
  UserDataTable,
  api,
  AwaitingQuotes,
  emailE,
}) => {
  // State variables
  const [open, setOpen] = React.useState(false);
  const [userList, setUserList] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [loading, setLoading] = useState(false);
  const [openfilter, setOpenfilter] = useState(false);
  const [filteredData, setFilteredData] = useState({
    ack_number: "",
    sales_person_name: "",
    client_person_name: "",
    client_subject: "",
    client_email_from: "",
    client_email_to: "",
    sales_email_from: "",
    sales_email_to: "",
    ack_email_from: "",
    ack_email_to: "",
    quotation_email_from: "",
    quotation_email_to: "",
    order_date_from: "",
    order_date_to: "",
    min_quote_value: "",
    max_quote_value: "",
    min_order_value: "",
    max_order_value: "",
    reject_date_from: "",
    reject_date_to: "",
  });
  const [filters, setFilters] = useState({
    location: "",
    quick_filter: "Last week",
    year: "",
    quarter: "",
    currency: "",
    sales_team: "",
  });
  const [searchData, setSeaarchData] = useState("");
  const [filterOn, setFilterOn] = useState(false);
  const [toast, setToast] = useState({ delete: false, error: false });
  const [errors, setError] = useState("");
  const [employeesArray, setEmployeesArray] = useState({
    name: [],
    count: [],
    totalSalesPerson: [],
  });
  const [rejectOpen, setRejectOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [reason, setReason] = useState({ rejectReason: "" });
  const [confirmReject, setConfirmReject] = useState(false);
  const dispatch = useDispatch();
  const [download, setDownload] = useState(false);
  const access = useSelector(selectUserData);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const fetchData2 = useCallback(async () => {
    if (access.role_name === "employee") {
      return;
    }
    try {
      const response = await _http.get("/api/get_users");
      const userLists = response.data.Output.record.flat();
      setUserList(userLists.flat());
    } catch (error) {
      setToast({ error: true });
      setError("Error fetching data");
    }
  }, [access]);

  useEffect(() => {
    fetchData2();
  }, [fetchData2]);

  const countOccurrences = (name, data, item) => {
    return data.filter((items) => items[item] === name).length;
  };

  // const getSalesPersonCount = employeesArray.count.filter((e) => e !== 0);

  const handleSelectChange = (name, value) => {
    setFilters((prevFilters) => {
      let updatedFilters = {
        ...prevFilters,
        [name]: prevFilters[name] === value ? "" : value,
      };

      if (name === "sales_team") {
        setFilters((prevFilter) => ({
          ...prevFilter,
          sales_team: value,
        }));
      }

      return updatedFilters;
    });
  };

  // Function to handle closing snackbar
  const handletoast = () => {
    setToast({ error: false, delete: false });
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const response = await _http.get(api);

      if (Array.isArray(response.data) && response.data.every(Array.isArray)) {
        const flattenedData = response.data.flat();
        if (title === "Awaiting Quote") {
          const TotalCounts = flattenedData
            .flat()
            .filter((item) => item.reminder_status === "pending");
          dispatch(setdatacount(TotalCounts?.length));
        } else if (title === "Awaiting Order") {
          const TotalCounts = flattenedData
            .flat()
            .filter((item) => item.reminder_status === "success");

          dispatch(setdatacount(TotalCounts?.length));
        } else if (title === "Order Placed") {
          const TotalCounts = flattenedData
            .flat()
            .filter((item) => item.reminder_status === "order_placed");
          dispatch(setdatacount(TotalCounts?.length));
        } else if (title === "Order Rejected") {
          const TotalCounts = flattenedData
            .flat()
            .filter((item) => item.reminder_status === "rejected");
          dispatch(setdatacount(TotalCounts?.length));
        } else {
          dispatch(setdatacount(flattenedData?.flat()?.length));
        }
        // else {
        setUsers(flattenedData.flat());
        // }
      } else {
        setUsers(response.data);
        dispatch(setdatacount(response.data.length));
      }
    } catch (error) {
      setToast({ error: true });
      setError("Error fetching data:", error?.message);
      dispatch(setdatacount(0));
    }
    setLoading(false);
  }, [api, dispatch, title]);
  // Function to compute date ranges based on quick filters
  const computeDateRange = (quickFilter) => {
    const now = new Date();
    let startDate;

    switch (quickFilter) {
      case "Yesterday":
        startDate = new Date(now.setDate(now.getDate() - 1));
        break;
      case "Last 3 days":
        startDate = new Date(now.setDate(now.getDate() - 3));
        break;
      case "Last week":
        startDate = new Date(now.setDate(now.getDate() - 7));
        break;
      case "Last 2 weeks":
        startDate = new Date(now.setDate(now.getDate() - 14));
        break;
      case "Last 3 weeks":
        startDate = new Date(now.setDate(now.getDate() - 21));
        break;
      case "Last month":
        startDate = new Date(now.setMonth(now.getMonth() - 1));
        break;
      case "Last 3 months":
        startDate = new Date(now.setMonth(now.getMonth() - 3));
        break;
      case "Last 6 months":
        startDate = new Date(now.setMonth(now.getMonth() - 6));
        break;
      case "Last 9 months":
        startDate = new Date(now.setMonth(now.getMonth() - 9));
        break;
      case "Last year":
        startDate = new Date(now.setFullYear(now.getFullYear() - 1));
        break;
      default:
        startDate = null;
    }

    return startDate;
  };
  const isWithinQuarter = (date, quarter) => {
    const month = date.getMonth() + 1; // JavaScript months are 0-based, so add 1

    switch (quarter) {
      case "Q1":
        return month >= 1 && month <= 3; // January to March
      case "Q2":
        return month >= 4 && month <= 6; // April to June
      case "Q3":
        return month >= 7 && month <= 9; // July to September
      case "Q4":
        return month >= 10 && month <= 12; // October to December
      default:
        return false;
    }
  };

  const filteredUsers = useMemo(() => {
    const {
      year,
      start_date,
      end_date,
      quarter,
      currency,
      location,
      quick_filter,
      // sales_team,
    } = filters;

    // If no filters are applied, return original users
    if (
      year.length === 0 &&
      !start_date &&
      !end_date &&
      quarter.length === 0 &&
      !currency &&
      !location &&
      !quick_filter
      // &&
      // sales_team.length === 0
    ) {
      return users || [];
    }

    // Define common date and filter conditions
    return (
      (users || []).filter((item) => {
        const emailDateUTC =
          title !== "Email"
            ? item?.client_email_time_utc
            : item?.from_email_time;

        if (!emailDateUTC) {
          return false; // Exclude items with no email date
        }

        // Convert UTC email date to local time (proper handling)
        const emailDate = new Date(emailDateUTC);
        const localEmailDate = new Date(
          emailDate.getFullYear(),
          emailDate.getMonth(),
          emailDate.getDate(),
          emailDate.getHours(),
          emailDate.getMinutes(),
          emailDate.getSeconds()
        );

        const yearOfEmail = localEmailDate.getFullYear();

        // Year filter check
        const isYearIncluded =
          year.length > 0 ? year.includes(yearOfEmail) : true;

        // Date range checks
        const isAfterStartDate = start_date
          ? localEmailDate >= new Date(start_date)
          : true;
        const isBeforeEndDate = end_date
          ? localEmailDate <= new Date(end_date)
          : true;

        // Quarter filter check
        const isInQuarter =
          quarter.length > 0
            ? quarter.some((q) => isWithinQuarter(localEmailDate, q))
            : true;

        // Currency, branch, and quick filter checks
        const isCurrencyMatched = currency
          ? currency.includes(item?.currency)
          : true;

        const isLocationMatched = location
          ? location.includes(item?.location)
          : true;

        // Quick filter handling
        let quickFilterStartDate = quick_filter
          ? computeDateRange(quick_filter)
          : null;
        const isWithinQuickFilter = quickFilterStartDate
          ? localEmailDate >= quickFilterStartDate
          : true;

        // Filter by sales_person_name
        // const isSalesTeamMember =
        //   sales_team.length > 0
        //     ? sales_team.includes(item?.sales_person_name)
        //     : true;
        // Return true if all conditions are met
        return (
          isYearIncluded &&
          isAfterStartDate &&
          isBeforeEndDate &&
          isInQuarter &&
          isCurrencyMatched &&
          isLocationMatched &&
          isWithinQuickFilter
          // &&
          // isSalesTeamMember
        );
      }) || []
    );
  }, [filters, users, title]);

  useEffect(() => {
    let employeeNames = [];
    if (userList.length > 0) {
      employeeNames = userList
        .filter(
          (user) =>
            user?.role_name.includes("employee") &&
            (!filters.location || user?.location === filters.location) // Filter by location
        )
        .map((user) => user.user);
    } else {
      employeeNames = [access?.name];
    }

    // Compute the count of all employee names in filteredUsers
    const employeeCounts = employeeNames.map((name) =>
      countOccurrences(name, filteredUsers, "sales_person_name")
    );

    const filteredNames = employeeNames.filter(
      (_, index) => employeeCounts[index] !== 0
    );
    const filteredCounts = employeeCounts.filter((count) => count !== 0);

    // Update the state with all employee names and their counts
    setEmployeesArray({
      name: filteredNames,
      count: filteredCounts,
      totalSalesPerson: employeeNames,
    });

    if (filters.sales_team && !employeeNames.includes(filters.sales_team)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        sales_team: "",
      }));
    }
  }, [userList, access, filters, filteredUsers]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Function to handle opening user details
  const handleOpen = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  // toast function for update
  const handleUpdateToast = (submit, error, message) => {
    setToast({
      submit: submit,
    });
    setToast({
      error: error,
    });
    setError(message);
  };

  // Function to handle closing user details
  const handleClose = () => setOpen(false);

  // Function to hide filter popover
  const hide = () => {
    setOpenfilter(false);
  };

  // Function to handle filter change
  const handleOpenChange = (newOpen) => {
    setOpenfilter(newOpen);
  };

  // Function to check if any filters are applied
  const checkIfFiltersAreApplied = (filters) => {
    return Object.values(filters).some((value) => value !== "");
  };

  // Update filterOn whenever filteredData changes
  useEffect(() => {
    setFilterOn(checkIfFiltersAreApplied(filteredData));
  }, [filteredData]);

  const renderEmptyOrValue = (text, TT, cln, Type) => {
    const dateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

    // Check if text matches the date-time format
    const isValidDateTime = dateTimePattern.test(text);

    // Return 'DataNR' if text is falsy
    if (!text) {
      return <span className="empty">--</span>;
    }

    // Handle date or time types
    if (Type === "date" || Type === "time") {
      if (isValidDateTime) {
        const [datePart, timePart] = new Date(text)
          ?.toLocaleString()
          ?.split(", ");
        return <span>{Type === "date" ? datePart : timePart}</span>;
      } else {
        return <span>MismatchF</span>; // Return this if the format does not match
      }
    }

    // Default case: Return the text with optional class and capitalization
    return (
      <span
        className={`${cln} w100 overflowData`}
        style={{ textTransform: TT ? "capitalize" : "none" }}
      >
        {text}
      </span>
    );
  };
  const DateSorter = (dataIndex) => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    const dateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/; // YYYY-MM-DD HH:MM:SS format

    return (a, b) => {
      // Get the date strings from the respective dataIndex
      const dateA = a[dataIndex];
      const dateB = b[dataIndex];

      // Check if the date format is valid (either datePattern or dateTimePattern)
      const isValidDateA =
        datePattern.test(dateA) || dateTimePattern.test(dateA);
      const isValidDateB =
        datePattern.test(dateB) || dateTimePattern.test(dateB);

      if (isValidDateA && isValidDateB) {
        // Both dates are valid, compare them as Date objects
        return new Date(dateA) - new Date(dateB);
      } else if (isValidDateA) {
        // Only dateA is valid, so it comes before
        return 1;
      } else if (isValidDateB) {
        // Only dateB is valid, so it comes before
        return -1;
      } else {
        // Neither date is valid, treat them as equal
        return 0;
      }
    };
  };

  const [count, setCount] = useState("50");
  const [totalcount, setTotalCount] = useState({
    total: 0,
    pending: 0,
    success: 0,
    order_placed: 0,
    rejected: 0,
    page_count: 0,
  });
  const PageCount = ["10", "20", "50", "100"];

  const items = PageCount.map((page) => ({
    key: page,
    label: (
      <div
        style={{ width: "100%" }}
        className="pageCount"
        onClick={() => setCount(page)}
      >
        {page}
      </div>
    ),
  }));

  // Handle removing a filter
  const removeThisFilter = (key) => {
    setFilteredData((prev) => {
      const updatedFilters = { ...prev, [key]: "" }; // Clear the filter
      return updatedFilters;
    });
  };

  // Handle input change with regex check
  const handleInputChange = (e) => {
    const { value } = e.target;
    // const pattern = /^[A-Za-z0-9\s]*$/;

    // if (pattern.test(value)) {
    // Normalize spaces to a single space
    const normalizedValue = value?.replace(/\s+/g, " ").trimStart();

    // Update filteredData for specific keys based on user input
    setSeaarchData(normalizedValue);
    // }
  };
  const filterComponent = (
    <div className="df" style={{ gap: rem(16) }}>
      {title !== "Email" && (
        <Space>
          <span
            className="filter-head"
            data-tooltip-id="tooltip-arrow"
            data-tooltip-content="Filter"
            onClick={() => setOpenfilter(true)}
          >
            <img src={filterOn ? filteron : filter} alt="filter icon" />
            <img src={filterOn ? greenDown : down} alt="arrow icon" />
          </span>
        </Space>
      )}
    </div>
  );
  const rejectOrderApi = async () => {
    const formData = new FormData();
    formData.append("reference_number", selectedData[0]?.new_ref_num);
    formData.append("reason", reason?.rejectReason);

    try {
      await _http.post(
        `/api/${
          title === "Awaiting Order" ? "reject_order" : "restore-order-status"
        }`,
        formData
      );
      setError(
        title === "Awaiting Order"
          ? "Order Rejected Successfully"
          : "Order Reverted Successfully"
      );
      setToast({ delete: true });
      setReason({ rejectReason: "" });
      fetchData();
      setSelectedData();
      setSelectedRowKeys();
    } catch (error) {
      setToast({ error: true });
      setError(
        title === "Awaiting Order"
          ? "Order Rejection Failed"
          : "Order Revert Failed"
      );
    }
  };
  const DownloadRejectOrders = async () => {
    setDownload(true);
    try {
      const response = await _http.get("api/download-rejected-orders", {
        responseType: "blob",
      });
      // Create a blob URL for the downloaded file
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "rejected_orders.csv"); // Set the file name
      document.body.appendChild(link);

      link.click(); // Trigger the download
      link.remove(); // Clean up after the download

      // Revoke the blob URL to free up memory
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setToast({ error: true });
      setError("Download Failed");
    }
    setDownload(false);
  };
  // JSX rendering
  return (
    <section>
      <div className={`container1`}>
        <div className="df container-head1">
          <span className="sales">Sales Tracker /</span>
          <span className="sales-title">{title}</span>
        </div>
        <div className="container-head df">
          <div className="df" style={{ gap: rem(8) }}>
            <p className="title">{title}</p>
            <div className="total-count-box">{totalcount?.page_count}</div>
          </div>
          <div className="user-search df">
            <img src={searchIcon} alt="icon" />
            <input
              // type="text"
              placeholder="Search..."
              value={searchData || ""}
              onChange={handleInputChange}
              name="user"
            />
          </div>
        </div>
        {!["Email"].includes(title) && (
          <div className="SalesCards">
            <SalesCards salesList={totalcount} />
          </div>
        )}
        <div
          style={{
            paddingBottom: rem(10),
            // paddingTop: title === "Email" ? rem(53) : {},
          }}
        >
          {/* {title !== "Email" && ( */}
          <SalesHeader
            setFilters={setFilters}
            filters={filters}
            title={title}
            // filteredData={filteredData}
            setFilteredData={setFilteredData}
            filterComponent={filterComponent}
          />
          {/* )} */}
        </div>
        <div className="container-head2 df space-between">
          <div className="df" style={{ gap: rem(8) }}>
            <SizeChanger count={count} items={items} />

            {!["Email"].includes(title) &&
              ["admin", "Manager", "Teamlead"].includes(access?.role_name) && (
                <div className="sales-year df">
                  <span className="sales-label">Sales Member</span>
                  <div style={{ width: rem(209) }}>
                    <SelectSearch
                      options={employeesArray.name}
                      name="sales_team"
                      Placeholder="All"
                      value={filters.sales_team}
                      onChange={(value) =>
                        handleSelectChange("sales_team", value)
                      }
                      height={28}
                      // multiple={true}
                    />
                  </div>
                  <label className="df">{`${employeesArray?.count?.length}/${employeesArray?.totalSalesPerson?.length}`}</label>
                </div>
              )}
          </div>

          <div className="df filter-section">
            {filterOn && filteredData && (
              <div className="filtered-data-item df">
                {Object.entries(filteredData).map(
                  ([key, value]) =>
                    !Array.isArray(value) &&
                    value && (
                      <span key={key} className="df filtered-option">
                        <span className="filtered-value">
                          {key
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (l) => l.toUpperCase())}
                          :{" "}
                          <span className="filtered-data">
                            {value || "N/A"}
                          </span>
                        </span>
                        <span
                          onClick={() => removeThisFilter(key)}
                          style={{ cursor: "pointer" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={rem(11)}
                            height={rem(12)}
                            viewBox="0 0 11 12"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.29075 8.56048C2.11177 8.73951 2.1118 9.02968 2.29081 9.20866C2.46981 9.38764 2.76001 9.38764 2.93899 9.20861L5.49889 6.64827L8.059 9.20838C8.23798 9.38736 8.5282 9.38736 8.70717 9.20838C8.88615 9.0294 8.88615 8.73919 8.70717 8.56021L6.14702 6.00005L8.70699 3.43962C8.88592 3.26062 8.88592 2.97042 8.7069 2.79144C8.52792 2.61246 8.2377 2.61249 8.05872 2.7915L5.4988 5.35187L2.93867 2.79172C2.75969 2.61273 2.46948 2.61273 2.29049 2.79172C2.1115 2.97071 2.1115 3.26091 2.29049 3.4399L4.85071 6.00009L2.29075 8.56048Z"
                              fill="#A4A4A4"
                            />
                          </svg>
                        </span>
                      </span>
                    )
                )}
              </div>
            )}
          </div>
          <div className="df" style={{ gap: rem(12) }}>
            {["Order Rejected"].includes(title) && (
              <Button
                btnname={"Download"}
                height={36}
                fs={15}
                fw={500}
                // width={69}
                br={5}
                cl="#fff"
                bg="#1e6641"
                disabled={download}
                border={`${rem(1)} solid #1e6641`}
                box_s={"none"}
                onclick={() => DownloadRejectOrders()}
              />
            )}
            {(["Awaiting Order"].includes(title) ||
              (["Order Rejected"].includes(title) &&
                ["admin", "Manager"].includes(access?.role_name))) && (
              <Button
                btnname={title === "Awaiting Order" ? "Reject" : "Revert"}
                height={36}
                fs={15}
                fw={500}
                width={69}
                br={5}
                cl="#fff"
                bg="#1e6641"
                border={`${rem(1)} solid #1e6641`}
                box_s={"none"}
                onclick={() => setRejectOpen(selectedData ? true : false)}
              />
            )}
          </div>
        </div>

        <UserDataTable
          handleOpen={handleOpen}
          users={filteredUsers}
          filteredData={filteredData}
          loading={loading}
          renderEmptyOrValue={renderEmptyOrValue}
          DateSorter={DateSorter}
          defaultPageSize={count}
          totalCount={setTotalCount}
          searchData={searchData}
          filters={filters}
          setSelectedData={setSelectedData}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
        {open && selectedUser && !emailE && (
          <EditPopup
            open={open}
            user={selectedUser}
            handleClose={handleClose}
            handleUpdateToast={handleUpdateToast}
            AwaitingQuotes={AwaitingQuotes}
          />
        )}
        {open && selectedUser && emailE && (
          <EmailPopup
            open={open}
            user={selectedUser}
            handleClose={handleClose}
          />
        )}
        {openfilter && (
          <FilterSlider
            setOpenfilter={setOpenfilter}
            width={rem(608)}
            openfilter={openfilter}
            handleOpenChange={handleOpenChange}
            hide={hide}
            DashBoardFilter={FilterEnquires}
            setFilteredData={setFilteredData}
            filteredData={filteredData}
            setFilters={setFilters}
            title={title}
          />
        )}
      </div>
      {rejectOpen && (
        <RejectPopUp
          rejectOpen={rejectOpen}
          setRejectOpen={setRejectOpen}
          selectedData={selectedData}
          setReason={setReason}
          reason={reason}
          setConfirmReject={setConfirmReject}
          title={title}
        />
      )}

      {confirmReject && (
        <RejectConfirmPopup
          confirmReject={confirmReject}
          setConfirmReject={setConfirmReject}
          rejectOrderApi={rejectOrderApi}
          title={title}
        />
      )}
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast.delete}
      />
    </section>
  );
};

export default SalesTracker;
