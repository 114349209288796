import React, { useCallback, useEffect, useRef, useState } from "react";
import logoImg from "../../Assets/QuizMobile/spiraLogo1.svg";
import material_symbols_download from "../../Assets/QuizMobile/material_symbols_download.svg";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import _nonAuthHttp from "../../Utils/Api/_nonAuthHttp";
import { Modal } from "antd";
import SnackBar from "../../Components/MobSnackBar";
import checkImg from "../../Assets/QuizMobile/streamline-icon-rating-document@300x300 1.svg";
import HollowStarRating from "../../Assets/QuizMobile/HollowStarRating.svg";
import FullStarRating from "../../Assets/QuizMobile/FullStarRating.svg";
import MobileLoader from "./MobileLoader";

export default function RankingPage() {
  const [rank, setRank] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [rating, setRating] = useState(0);
  const [toast, setToast] = useState({ submit: false, error: false });
  const [loading, setLoading] = useState(false);
  const [errors, setError] = useState("");
  const scoreRef = useRef(0);
  const scoreDataRef = useRef({ correct: 0, incorrect: 0, unattempted: 0 });
  const [download, setDownload] = useState(false);

  const pool_title = sessionStorage.getItem("pool_title");
  const participantId = sessionStorage.getItem("participantsId");
  const participant_name = sessionStorage.getItem("participantsName");
  const pool_id = sessionStorage.getItem("pool_id");
  const participant_email = sessionStorage.getItem("participant_email");

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const fetchRank = useCallback(async () => {
    setLoading(true);
    try {
      const res = await _nonAuthHttp.post("/quiz/get-participants", {
        id: pool_id,
      });
      setRank(res.data);
      setModalVisible(true);

      const findIdHandler = res.data.find((x) => x.name === participant_name);
      if (findIdHandler) {
        scoreRef.current = findIdHandler.total_score; // Update the state with user score
        scoreDataRef.current = {
          correct: findIdHandler.correct_answers || 0, // Assuming API has correct_answers field
          incorrect: findIdHandler.wrong_answers || 0, // Assuming API has incorrect_answers field
          unattempted: findIdHandler.not_attempted_questions || 0, // Assuming API has unattempted_answers field
        };
      } else {
        console.log("User not found in the rank list.");
      }
    } catch (error) {
      console.log(error);
      setError("Internal Server Error");
      setToast({ error: true });
    } finally {
      setLoading(false);
    }
  }, [participant_name, pool_id]);

  useEffect(() => {
    fetchRank();
  }, [fetchRank]);

  const dateObj = new Date();
  // Format the date as day-month-year
  const day = String(dateObj.getDate()).padStart(2, "0");
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = dateObj.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;

  const downloadHandler = async () => {
    setDownload(true);
    try {
      const res = await _nonAuthHttp.post(
        "/quiz/generate-certificate",
        {
          participant_name: participant_name,
          participated_date: formattedDate,
          participated_email: participant_email,
        },
        {
          responseType: "blob",
        }
      );
      const url = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = `spira quiz_${pool_title}.pdf`;
      link.click();

      setError("Download Successfully");
      setToast({ submit: true });
    } catch (error) {
      console.error("Error downloading the certificate:", error);
      setError(error?.message);
      setToast({ error: true });
    } finally {
      setDownload(false);
    }
  };

  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: function (chart) {
      const width = chart.width;
      const height = chart.height;
      const ctx = chart.ctx;
      ctx.restore();
      const fontSize = (height / 114).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "middle";
      const text = scoreRef.current; // Your dynamic text here
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;
      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };
  const data = {
    datasets: [
      {
        data: [
          scoreDataRef.current.correct,
          scoreDataRef.current.incorrect,
          scoreDataRef.current.unattempted,
        ],
        backgroundColor: ["#1E6641", "#FF3030", "#DFDFDF"],
        hoverBackgroundColor: ["#1E6641", "#558B70", "#CEDDD5"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide default legend, as we are customizing it
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset;
            const total = dataset.data.reduce((sum, value) => sum + value, 0);
            const currentValue = dataset.data[tooltipItem.dataIndex];
            const percentage = ((currentValue / total) * 100).toFixed(2);
            return `${currentValue} (${percentage}%)`;
          },
        },
      },
    },
    cutout: "60%", // Controls the size of the hole in the middle
  };

  const closeHandler = () => {
    setModalVisible(false);
  };

  const handleRatingClick = async (index) => {
    const newRating = index + 1;
    setRating(newRating); // Set rating based on clicked star index (0-based)
    try {
      const res = await _nonAuthHttp.post("/quiz/submit-rating", {
        pool_id: pool_id, //currently it is static need to pass here pool id dynamic
        participant_id: participantId,
        rating: newRating,
      });
      setError("Thanks For Your Ratings");
      setToast({ submit: true });
      console.log(res);
    } catch (error) {
      console.log(error);
      setError(
        error?.response?.data?.non_field_errors || "Internal Server Errror"
      );
      setToast({ error: true });
    } finally {
      closeHandler();
    }
  };

  const renderRatingStars = () => {
    return Array.from({ length: 5 }, (_, index) => {
      const isFilled = index < rating; // Check if this star should be filled or hollow
      const starImage = isFilled ? FullStarRating : HollowStarRating; // Use full star or hollow star

      return (
        <img
          key={index}
          src={starImage}
          alt={`Rating ${index + 1}`}
          onClick={() => handleRatingClick(index)}
          style={{ width: "45px", height: "50px" }}
        />
      );
    });
  };

  return (
    <>
      {loading && (
        <div
          style={{
            background: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <MobileLoader />
        </div>
      )}
      {!loading && (
        <div className="main-container ranking_page">
          <div className="logo-img">
            <img src={logoImg} alt="error" />
            {!download ? (
              <img
                src={material_symbols_download}
                alt="error"
                onClick={downloadHandler}
                className="downloading_img"
              />
            ) : (
              <div className="spinners"></div>
            )}
          </div>
          <div className="score_bar">
            <p className="ranki">{pool_title} Score</p>
            <div>
              <Doughnut
                data={data}
                options={options}
                plugins={[centerTextPlugin]}
              />
            </div>
          </div>
          <div className="outsider">
            <div className="resultBar">
              <div className="rs_div">
                <p className="dot1"></p>
                Correct
              </div>
              <div className="rs_div">
                <p className="dot2"></p>
                Incorrect
              </div>
              <div className="rs_div">
                <p className="dot3"></p>
                Unattenpted
              </div>
            </div>
          </div>
          <div className="table_container">
            <p className="sub_rank">Ranking</p>
            <div className="ranking_table">
              <table>
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Name</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {rank && rank.length > 0 ? (
                    rank.slice(0, 3).map((entry, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{entry.name}</td>
                        <td>{entry.total_score}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No ranking data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Modal
            className="rating_modal"
            open={modalVisible}
            closable={false}
            onCancel={() => setModalVisible(false)}
          >
            <div className="inside-modal">
              <img src={checkImg} alt="error" />
              <p className="rat_experience">
                How do you rate your Quiz experience
              </p>
              <div> {renderRatingStars()} </div>
              <p className="skip" onClick={closeHandler}>
                Skip
              </p>
            </div>
          </Modal>
          <SnackBar
            message={errors}
            severity={"error"}
            handleClose={handletoast}
            Open={toast?.error}
          />
          <SnackBar
            message={errors}
            severity={"success"}
            handleClose={handletoast}
            Open={toast?.submit}
          />
        </div>
      )}
    </>
  );
}
