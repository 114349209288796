import React, { useMemo, useEffect } from "react";
import { rem } from "../../../Components/Rem_func";
import DraggableTable from "../../../Components/TableDrag_func";

const AQTable = ({
  handleOpen,
  users,
  filteredData,
  loading,
  renderEmptyOrValue,
  totalCount,
  defaultPageSize,
  DateSorter,
  searchData,
}) => {
  console.log(users);

  // useMemo hook to filter and format user data based on filter criteria
  const filteredUsers = useMemo(() => {
    return (users || [])
      .filter((user) => {
        const matchesSearchData = searchData
          ? (user?.from_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.from_subject || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.from_email || "")
              .toLowerCase()
              .includes(searchData.toLowerCase())
          : true;

        return matchesSearchData;
      })
      .map((user) => {
        // Highlight search matches in relevant fields
        const matchClientPersonMail =
          searchData &&
          user?.from_email?.toLowerCase().includes(searchData?.toLowerCase());

        const matchClientSubject =
          searchData &&
          user?.from_subject?.toLowerCase().includes(searchData?.toLowerCase());

        return {
          ...user, // Return all original user data
          client_person_mail_class: matchClientPersonMail
            ? "highlighted-text"
            : "",
          client_subject_class: matchClientSubject ? "highlighted-text" : "",
        };
      });
  }, [users, searchData]);

  // Configuration for table columns
  const columns = [
    {
      title: "Email-From",
      dataIndex: "from_email",
      key: "from_email",
      width: rem(134),
      className: "tl",

      render: (text, record) =>
        renderEmptyOrValue(
          record.from_email,
          false,
          record?.client_person_mail_class
        ),
      sorter: (a, b) => (a.from_email || "").localeCompare(b.from_email || ""),
    },
    {
      title: "Mail Subject",
      dataIndex: "from_subject",
      key: "from_subject",
      width: rem(164),
      className: "tl",
      render: (text, record) =>
        renderEmptyOrValue(text, "", record?.client_subject_class),
      sorter: (a, b) =>
        (a.from_subject || "").localeCompare(b.from_subject || ""),
    },
    {
      title: `From Date/Time`,
      dataIndex: "from_email_time",
      key: "from_email_time",
      className: "noPadding",
      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),

      width: rem(134),
      sorter: DateSorter("from_email_time"),
    },
  ];
  useEffect(() => {
    totalCount(filteredUsers?.length); // Set total count directly
  }, [filteredUsers, totalCount]);
  return (
    <>
      <DraggableTable
        handleOpen={handleOpen}
        filteredUsers={filteredUsers}
        loading={loading}
        baseColumns={columns}
        drag={true}
        defaultPageSize={defaultPageSize}
      />
    </>
  );
};

export default AQTable;
