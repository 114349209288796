import React from "react";
import Facebook from "../../Assets/Quiz/Facebook.svg";
import Twitter from "../../Assets/Quiz/TwitterX.svg";
import Instagram from "../../Assets/Quiz/Instagram.svg";
import Telegram from "../../Assets/Quiz/Telegram.svg";
import LinkedIn from "../../Assets/Quiz/LinkedIn.svg";
import Whatsapp from "../../Assets/Quiz/Whatsapp.svg";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const QuizShare = () => {
  const id = sessionStorage.getItem("QuizId");
  const domain = window.location.origin;
  console.log(domain);
  const url = domain + "/quiz-admin/?pool=" + id;

  return (
    <div className="share-this-text">
      <FacebookShareButton
        url={url}
        quote="Test Your Knowledge With Spira QRE"
        hashtag="quiztime"
      >
        <img src={Facebook} alt="fb" draggable="false" />
      </FacebookShareButton>
      <LinkedinShareButton
        url={url}
        title="Spira Quiz"
        summary="Test Your Knowledge With Spira QRE"
        source="spira.com"
      >
        <img src={LinkedIn} alt="linkedIn" draggable="false" />
      </LinkedinShareButton>
      <WhatsappShareButton url={url} title="Test Your Knowledge With Spira QRE">
        <img src={Whatsapp} alt="whatsapp" draggable="false" />
      </WhatsappShareButton>
      <TwitterShareButton url={url} title="Spira Quiz" hashtag="quiztime">
        <img src={Twitter} alt="X" draggable="false" />
      </TwitterShareButton>
    </div>
  );
};

export default QuizShare;
