import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./QualityHome.scss";
import _http from "../../../Utils/Api/_http";
import SnackBar from "../../../Components/SnackBar";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const QualityHome = () => {
  const [quality, setQuality] = useState([]);
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const fetchData = async () => {
    try {
      const response = await _http.get("/api/mtc-file-count");
      setQuality(response.data.data);
    } catch (error) {
      setToast({ error: true });
      setError("Error Fetching MTC Generated Data");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  function getLast12Months(apiData) {
    const today = new Date();
    const result = [];

    // Generate the last 12 months
    const last12Months = Array.from({ length: 12 }, (_, i) => {
      const date = new Date();
      date.setMonth(today.getMonth() - i); // Adjust the month backward
      return date.toLocaleString("default", { month: "short" }); // Short month name
    }).reverse(); // Reverse to start from last year's Nov to this year's Nov

    // Map through last 12 months and find matching months
    for (const month of last12Months) {
      const match = apiData.find((item) => {
        const itemMonth = new Date(item.mtc_date).toLocaleString("default", {
          month: "short",
        });
        return itemMonth === month;
      });
      result.push(match ? month : month); // Add the month
    }

    return result;
  }

  const last12Months = getLast12Months(quality);

  // Helper function to calculate the difference in months
  const getMonthDifference = (date) => {
    const today = new Date();
    const targetDate = new Date(date);
    const monthsAgo =
      (today.getFullYear() - targetDate.getFullYear()) * 12 +
      (today.getMonth() - targetDate.getMonth());
    return monthsAgo; // Difference in months
  };

  // Step 1: Filter data for the last 12 months (this Nov to last Nov)
  const currentyear = quality.filter((item) => {
    if (!item.mtc_date) return false; // Exclude null dates
    const monthsAgo = getMonthDifference(item.mtc_date);
    return monthsAgo <= 12 && monthsAgo >= 0; // Data from the last 12 months
  });

  // Step 2: Filter data for the previous 12 months (before the last 12 months)
  const previousYearData = quality.filter((item) => {
    if (!item.mtc_date) return false; // Exclude null dates
    const monthsAgo = getMonthDifference(item.mtc_date);
    return monthsAgo > 12 && monthsAgo <= 24; // Data from the previous 12 months
  });

  // Step 3: Calculate total heat_count for each period
  const totalCurrentYear = currentyear.reduce(
    (sum, item) => sum + item.heat_count,
    0
  );

  const totalPreviousYear = previousYearData.reduce(
    (sum, item) => sum + item.heat_count,
    0
  );

  // Step 4: Calculate the percentage change
  const percentageChange =
    totalPreviousYear === 0
      ? 0 // Assume a 100x (100%) increase if previous is 0
      : Math.round(
          ((totalCurrentYear - totalPreviousYear) / totalPreviousYear) * 100
        );

  const isPositiveChange = percentageChange >= 0;

  const dynamicData = last12Months.map((month) => {
    // Filter data matching the current month and year
    const match = quality.filter((item) => {
      // Ensure mtc_date is valid and in the correct format (e.g., "YYYY-MM")
      if (!item.mtc_date) return false; // Exclude invalid dates

      const [year, itemMonth] = item.mtc_date.split("-");

      // Ensure month is valid before using toLocaleString
      if (!year || !itemMonth) return false;

      const formattedMonth = new Date(year, itemMonth - 1).toLocaleString(
        "default",
        { month: "short" }
      ); // Convert to short month name
      return formattedMonth === month;
    });

    // Sum up the heat_count for the current month
    return match.reduce((total, item) => total + item.heat_count, 0);
  });

  const data = {
    labels: last12Months,
    datasets: [
      {
        label: "MTC's Generated",
        data: dynamicData,
        backgroundColor: "rgba(30, 102, 65, 0.28)",
        hoverBackgroundColor: "#1e6641",
        borderWidth: 1,
        borderRadius: 10,
        barPercentage: 0.3, // Adjust bar width (smaller value increases gap)
        categoryPercentage: 0.9,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        align: "start",
        font: {
          size: 12,
          weight: "bold",
        },
        color: "#333",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#454545",
          font: {
            size: 7, // Adjust font size for the month labels
          },
          maxRotation: 0, // Prevent rotation of labels
          minRotation: 0,
        },
        border: {
          display: false, // Hide the x-axis line
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#454545",
          font: {
            size: 6, // Adjust font size for the month labels
          },
          maxTicksLimit: 4,
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
    },
  };

  const data1 = {
    datasets: [
      {
        labels: ["This year", "Last year"],
        data: [totalCurrentYear, previousYearData],

        backgroundColor: ["#1E6641", "#E0E0E0"],

        borderWidth: 0,
        hoverOffset: 0,
        cutout: "75%", // Inner circle with smaller cutout to create gap
        radius: "100%",
      },
    ],
  };

  const options1 = {
    rotation: 180, // Start from bottom center
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataset = context.dataset;
            const dataIndex = context.dataIndex;
            const label = dataset.labels
              ? dataset.labels[dataIndex]
              : dataset.label;
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
  };

  return (
    <div className="quality_home">
      <div className="upper_quality">
        <p className="userM">Quality</p>
        <p className="mtc_title">MTC's Generated</p>
      </div>

      <div className="percentage_change">
        <div className="conv-graph">
          <Doughnut data={data1} options={options1} />
          <span
            className="conv-per"
            style={
              isPositiveChange ? { color: "#1E6641" } : { color: "#8FDBB5" }
            }
          >
            {isPositiveChange
              ? `+${percentageChange}%`
              : `-${percentageChange}%`}
          </span>
        </div>
      </div>

      <div
        className="barChat"
        // style={{ height: rem(230), width: "100%", overflowX: "auto" }}
      >
        <Bar data={data} options={options} />
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </div>
  );
};

export default QualityHome;
