import { Modal } from "antd";
import React, { useState } from "react";
import { rem } from "../../../Components/Rem_func";
import VideoIcon from "../../../Assets/Quiz/QreCat&Photo/Video.svg";
import Close from "../../../Assets/Quiz/QreCat&Photo/Close.svg";
import Button from "../../../Components/Button/Button";
import Img from "../../../Assets/Quiz/QreCat&Photo/Img.svg";

export default function UploadModal({
  open,
  section,
  setOpen,
  handleClose,
  fileTitle,
  File,
  uploadMedia,
  setToast,
  setError,
  loading,
  folderCreation,
  Folders,
  handleFileUpload,
}) {
  const [files, setFiles] = useState(Array.from(File || []));

  function removeitem(fileName) {
    const fileToRemove = files.find((file) => file.name === fileName);

    if (fileToRemove) {
      if (section === "catalogue") {
        if (fileToRemove.size >= 125 * 1024 * 1024) {
          setToast({
            type: "info",
            message: "A file larger than 125MB has been removed.",
          });
        }
      } else {
        if (fileToRemove.size >= 25 * 1024 * 1024) {
          setToast({
            type: "info",
            message: "A file larger than 25MB has been removed.",
          });
        }
      }
    }

    const updatedFiles = files.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);

    if (updatedFiles.length === 0) {
      handleClose(true);
    }
  }
  function handleApiCall() {
    const hasLargeFiles =
      files.some((item) => item.size > 125 * 1024 * 1024) ||
      files.some((item) => item.size > 25 * 1024 * 1024);

    if (hasLargeFiles) {
      setToast({
        e: "info",
        message: "One or more files are larger than 125MB.",
      });

      const largeFile = files.find((item) => item.size > 125 * 1024 * 1024);
      if (largeFile) {
        handleFileUpload();
        removeitem(largeFile.name);
      }

      return;
    }

    Folders ? folderCreation(files) : uploadMedia(files);
  }
  return (
    <div>
      <Modal
        centered
        open={open}
        // closable={false}
        onCancel={handleClose}
        className="edit-popup"
        width={rem(847)}
        height={rem(476)}
      >
        <div className="modal-parent">
          {loading ? (
            <div className="spinners">
              {/* <img src={Animation} draggable="false" alt="" /> */}
            </div>
          ) : (
            <>
              <p className="modal_title">
                {section === "catalogue" ? "Upload Video" : "Upload Image"}
              </p>
              <div className="model_body">
                <div className="file_section">
                  {files.map((item, id) => (
                    <div className="filesdatas" key={id}>
                      <div className="left_file">
                        <img
                          src={`${section === "catalogue" ? VideoIcon : Img}`}
                          alt="error"
                        />
                        <div>
                          <p className="file_title">{item.name}</p>
                          <p className="file_size">
                            {section === "catalogue" ? (
                              item.size > 125 * 1024 * 1024 ? (
                                <span
                                  className="file_size"
                                  style={{ color: "red" }}
                                >
                                  File is too large. Max 125MB
                                </span>
                              ) : (
                                <span className="file_size">
                                  {(item.size / (1024 * 1024)).toFixed(2)} MB
                                </span>
                              )
                            ) : item.size > 25 * 1024 * 1024 ? (
                              <span
                                className="file_size"
                                style={{ color: "red" }}
                              >
                                File is too large. Max 125MB
                              </span>
                            ) : (
                              <span className="file_size">
                                {(item.size / (1024 * 1024)).toFixed(2)} MB
                              </span>
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="right_file">
                        <div>
                          <p className="file_title">
                            {new Date().toLocaleDateString("en-GB")}
                          </p>
                          <p className="file_size">
                            {new Date().toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </p>
                        </div>
                        <img
                          src={Close}
                          alt="error"
                          className="close"
                          onClick={() => removeitem(item?.name)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="uplbtns">
                  <Button
                    btnname="Save"
                    width={104}
                    height={34}
                    fs={14}
                    fw={500}
                    border={`${rem(1)} solid #1E6641`}
                    br={4}
                    onclick={() => handleApiCall()}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
