import React, { useState, useEffect, useCallback } from "react";
import Button from "../../../Components/Button/Button";
import InputField from "../../../Components/InputField/InputField";
import SelectSearch from "../../../Components/SelectFields/SelectField";
import { rem } from "../../../Components/Rem_func";
const FilterUser = ({
  onFilter,
  hide,
  roles,
  userList,
  names,
  setFilteredData,
  filteredData,
}) => {
  // Function to handle filter application
  const [localData, setLocalData] = useState(filteredData);

  useEffect(() => {
    setLocalData(filteredData);
  }, [filteredData]);
  // State variable to store reportingTo options based on user roles
  const [reportingTo, setReportingTo] = useState();

  // State variable to store available roles based on user roles
  const [Roles, setRoles] = useState([]);
  const RoleType = useCallback(() => {
    if (roles === "admin") {
      setRoles(["Manager", "Teamlead", "employee"]);
    } else if (roles === "Manager") {
      setRoles(["Teamlead", "employee"]);
    } else if (roles === "Teamlead") {
      setRoles(["employee"]);
    } else {
      return;
    }
    setReportingTo([]); // Reset reportingTo on RoleType change
  }, [roles]);

  const getReportingToOptions = useCallback(
    (role_names, names) => {
      let reportingToOptions = [];

      if (role_names === "Manager") {
        reportingToOptions = [names];
      } else if (role_names === "Teamlead") {
        const managers = userList
          .filter((user) => user.role_name === "Manager")
          .map((user) => user.user);

        reportingToOptions = managers.length > 0 ? managers : [names];
      } else if (role_names === "employee") {
        const teamleads = userList
          .filter((user) => user.role_name === "Teamlead")
          .map((user) => user.user);

        reportingToOptions = teamleads.length > 0 ? teamleads : [names];
      } else if (role_names === "admin") {
        const teamleads = userList
          .filter((user) => user.role_name !== "employee")
          .map((user) => user.user);

        reportingToOptions = teamleads.length > 0 ? teamleads : [names];
      }

      setReportingTo(reportingToOptions);
    },
    [userList]
  );

  const getReportingToOptions1 = useCallback(() => {
    let reportingToOptions = [];

    if (roles === "admin") {
      const admins = userList
        .filter((user) => user.role_name !== "employee")
        .map((user) => user.user);

      reportingToOptions = admins.length > 0 ? admins : [names];
    } else if (roles === "Manager") {
      const managers = userList
        .filter((user) => user.role_name === "Teamlead")
        .map((user) => user.user);

      reportingToOptions = managers.length > 0 ? managers : [names];
    } else if (roles === "Teamlead") {
      const teamleads = userList
        .filter((user) => user.role_name === "employee")
        .map((user) => user.user);

      reportingToOptions = teamleads.length > 0 ? teamleads : [names];
    }
    setReportingTo(reportingToOptions);
  }, [roles, userList, names]);

  useEffect(() => {
    RoleType();
    setLocalData((prevData) => ({
      ...prevData,
      reporting_to: "",
    }));

    localData.role_name
      ? getReportingToOptions(localData.role_name, names)
      : getReportingToOptions1();
  }, [
    roles,
    localData.role_name,
    RoleType,
    getReportingToOptions,
    getReportingToOptions1,
    names,
  ]);

  // Function to handle filter application
  const handleFilter = () => {
    // onFilter(filteredData);
    setFilteredData(localData);
    hide();
  };

  // Function to reset filters
  const ResetFilter = () => {
    const resetData = {
      user: "",
      role_name: "",
      reporting_to: "",
      created_date_from: "",
      created_date_to: "",
      last_login_from: "",
      last_login_to: "",
    };

    setFilteredData(resetData);
    // onFilter(resetData);
  };
  const handleSelectChange = (name, value) => {
    setLocalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const maxDate = getCurrentDate();
  return (
    <div className="filter-box">
      <div className="popover-title">Filter</div>
      <div className="filter-body">
        {/* <InputField
          label={"user"}
          labelName={"User"}
          type={"text"}
          placeholder={""}
          fieldData={localData.user}
          setFormData={setLocalData}
          maxLength={50}
          pattern={"text"}
        /> */}

        <div>
          <SelectSearch
            label="User Role"
            name="role_name"
            Placeholder="Select option"
            options={Roles}
            value={localData?.role_name}
            onChange={(value) => handleSelectChange("role_name", value)}
            height={36}
          />
        </div>
        <div>
          <SelectSearch
            label="Reporting to"
            name="reporting_to"
            Placeholder="Select option"
            options={reportingTo}
            value={localData?.reporting_to}
            onChange={(value) => handleSelectChange("reporting_to", value)}
            height={36}
          />
        </div>

        <div className="sub-head" style={{ marginBottom: "0" }}>
          Created Date
        </div>
        <div className="date-filter">
          <div style={{ width: "48%" }}>
            <InputField
              labelName={"From"}
              label={"created_date_from"}
              type={"Date"}
              placeholder={""}
              fieldData={localData.created_date_from}
              setFormData={setLocalData}
              maxLength={
                localData.created_date_to
                  ? localData.created_date_to.split("T")[0]
                  : localData.last_login_to
                  ? localData.last_login_to.split("T")[0]
                  : maxDate
              }
              minLength={localData.last_login_from}
            />
          </div>
          <div style={{ width: "48%" }}>
            <InputField
              labelName={"To"}
              label={"created_date_to"}
              type={"Date"}
              placeholder={""}
              fieldData={localData.created_date_to}
              setFormData={setLocalData}
              minLength={
                localData.created_date_from
                  ? localData.created_date_from.split("T")[0]
                  : ""
              }
              maxLength={
                localData.last_login_to
                  ? localData.last_login_to.split("T")[0]
                  : maxDate
              }
            />
          </div>
        </div>

        <div className="sub-head" style={{ marginBottom: "0" }}>
          Last Login
        </div>
        <div className="date-filter">
          <div style={{ width: "48%" }}>
            <InputField
              labelName={"From"}
              label={"last_login_from"}
              type={"Date"}
              placeholder={""}
              fieldData={localData.last_login_from}
              setFormData={setLocalData}
              maxLength={
                localData.last_login_to
                  ? localData.last_login_to.split("T")[0]
                  : maxDate
              }
              minLength={
                localData.created_date_from
                  ? localData.created_date_from.split("T")[0]
                  : ""
              }
            />
          </div>
          <div style={{ width: "48%" }}>
            <InputField
              labelName={"To"}
              label={"last_login_to"}
              type={"Date"}
              placeholder={""}
              fieldData={localData.last_login_to}
              setFormData={setLocalData}
              minLength={
                localData.last_login_from
                  ? localData.last_login_from.split("T")[0]
                  : localData.created_date_to.split("T")[0]
              }
              maxLength={maxDate}
            />
          </div>
        </div>
      </div>
      <div className="filter-btn">
        <div>
          <Button
            btnname={"Reset"}
            bg={"#fff"}
            border={`${rem(1)} solid #1E6641`}
            onclick={ResetFilter}
            box_s={"none"}
            cl={"#1e6641"}
            br={4}
          />
        </div>
        <div>
          <Button
            btnname={"Apply Now"}
            onclick={handleFilter}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleFilter();
              }
            }}
            fs={16}
            br={4}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterUser;
