import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./SideBar.scss";
import Home from "./../../Assets/SideBarIcon/Home.svg";
import QuoteGenerator from "./../../Assets/SideBarIcon/Quote.svg";
// import Procurement from "./../../Assets/SideBarIcon/Deal.svg";
import Quality from "./../../Assets/SideBarIcon/Medal.svg";
import Sales from "./../../Assets/SideBarIcon/Sales.svg";
import arrowIcon from "./../../Assets/SideBarIcon/arrowIcon.svg";
import Homeactive from "./../../Assets/SideBarIcon/Homeactive.svg";
import QuoteGeneratoractive from "./../../Assets/SideBarIcon/Quote.svg";
// import Procurementactive from "./../../Assets/SideBarIcon/Dealactive.svg";
import Qualityactive from "./../../Assets/SideBarIcon/MedalActive.svg";
import Salesactive from "./../../Assets/SideBarIcon/Salesactive.svg";
import arrowIconactive from "./../../Assets/SideBarIcon/Arrowactive.svg";
import Useractive from "./../../Assets/SideBarIcon/Useractive.svg";
// import heat from "./../../Assets/SideBarIcon/heat.svg";
// import heatActive from "./../../Assets/SideBarIcon/heatnumberActive.svg";
import feedback from "./../../Assets/SideBarIcon/feedback.svg";
import EmailE from "./../../Assets/SideBarIcon/emailE.svg";
import EmailEactive from "./../../Assets/SideBarIcon/EmailEactive.svg";

import feedbackActive from "./../../Assets/SideBarIcon/feedactive.svg";
import User from "./../../Assets/SideBarIcon/User.svg";
import Quiz from "./../../Assets/SideBarIcon/Quiz.svg";
import QuizActive from "./../../Assets/SideBarIcon/Quiz.svg";
import sideBarClose from "../../Assets/SidebarClose.svg";
import SpiraLogo from "../../Assets/SideBarIcon/SpiraLogo.svg";
import {
  selectIsToggleOn,
  selectUserData,
  toggle,
  selectDataCount,
  setdatacount,
} from "../../Redux/Reducers";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { useUserData } from "../../Utils/UserDataProvider";
import { rem } from "../Rem_func";
// import logout_img from "../../Assets/SideBarIcon/logout.svg";
import logoImg from "../../Assets/NavbarIcon/spira 1.svg";
import { useUserImage } from "../UserImageProvider";

// Custom hook to handle sessionStorage operations
const useSessionStorage = (key, initialValue) => {
  const storedValue = sessionStorage.getItem(key);
  const initial = storedValue ? new Map(JSON.parse(storedValue)) : initialValue;
  const [value, setValue] = useState(initial);

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(Array.from(value.entries())));
  }, [key, value]);

  return [value, setValue];
};

// Function to calculate dropdown height
const calculateHeight = (dropDownDataLength) => {
  return `${dropDownDataLength * 32 + 5}`;
};

const SideBar = () => {
  // State hooks
  const [dropdownState, setDropdownState] = useSessionStorage(
    "dropdownState",
    new Map()
  );
  const [access, setAccess] = useState();
  const { fetchData } = useUserData();
  const userImage = useUserImage();

  // Redux hooks
  const dispatch = useDispatch();
  const isSideBarOn = useSelector(selectIsToggleOn);
  const isAccess = useSelector(selectUserData);
  const DataCount = useSelector(selectDataCount);
  // React Router hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Effect to set access state from sessionStorage
  useEffect(() => {
    setAccess(isAccess);
  }, [isAccess]);

  useEffect(() => {
    if (!access || Object.keys(access).length === 0) {
      fetchData();
    }
  }, [access, fetchData]);

  // Sidebar data
  const sidebarData = [
    {
      text: "Home",
      link: "/SpiraAI/Dashboard",
      imgSrc: Home,
      activeImgSrc: Homeactive,
      alt: "side-img",
      dropdown: false,
      linksactive: true,
      permission: true,
      arrowImage: false,
    },

    {
      text: "Sales Tracker",
      link: "#",
      activeImgSrc: Salesactive,
      imgSrc: Sales,
      alt: "side-img",
      dropdown: true,
      permission: access?.sales_tracker,
      arrowImage: true,
      dropDownData: [
        { title: "Dashboard", link: ["/Salestracker/Dashboard"] },
        { title: "Report", link: ["/Salestracker/report"], count: true },
        { title: "Enquiries", link: ["/enquires"], count: true },
        { title: "Awaiting Quote", link: ["/awaiting-quotes"], count: true },
        { title: "Awaiting Order", link: ["/awaiting-order"], count: true },
        { title: "Order Placed", link: ["/order-placed"], count: true },
        { title: "Order Rejected", link: ["/rejected-order"], count: true },
      ],

      calculatedHeight: calculateHeight(7),
    },

    {
      text: "Quality",
      link: "/QualityTeam.MTCgenerator",
      activeImgSrc: Qualityactive,
      imgSrc: Quality,
      alt: "side-img",
      permission: access?.quality,
      linksactive: true,
      arrowImage: false,
    },
    {
      text: "Quote Generator",
      link: "/QuoteGenerator",
      activeImgSrc: QuoteGeneratoractive,
      imgSrc: QuoteGenerator,
      alt: "side-img",
      permission: access?.quote_generator,
      dropdown: false,
      arrowImage: false,
    },
    {
      text: "Feedback",
      link: "#",
      imgSrc: feedback,
      activeImgSrc: feedbackActive,
      alt: "side-img",
      dropdown: true,
      linksactive: true,
      permission: access?.feedback_form,
      arrowImage: true,
      dropDownData: [
        { title: "Feedback Form", link: ["/SpiraAI/feedbackform"] },
        { title: "Feedback Stats", link: ["/feedbackdata"], count: true },
      ],
      calculatedHeight: calculateHeight(2),
    },
    {
      text: "Email E",
      link:
        access?.user_email === "nabiel@spirapower.com"
          ? "/Salestracker/email"
          : "/underConstruction",
      activeImgSrc: EmailEactive,
      imgSrc: EmailE,
      alt: "side-img",
      permission: access?.sales_tracker,
      dropdown: false,
      arrowImage: false,
    },

    {
      text: "QRE",
      link: "#",
      activeImgSrc: QuizActive,
      imgSrc: Quiz,
      alt: "side-img",
      permission: access?.sales_tracker,
      dropdown: true,
      dropDownData: [
        {
          title: "QRE Configure",
          link: ["/SpiraQuiz", "/SpiraQuiz/catalogue", "/SpiraQuiz/photo"],
        },
        {
          title: "QRE Report",
          link: ["/SpiraQuiz/Report", "/SpiraQuiz/ReportTable"],
          count: true,
        },
      ],
      arrowImage: true,
      calculatedHeight: calculateHeight(2),
    },
  ];
  const permittedItems = sidebarData?.filter((item) => item.permission);

  // useEffect(() => {
  //   // Adjust dropdownState to only include items in filteredSidebarData
  //   const newDropdownState = new Map();
  //   permittedItems.forEach((_, index) => {
  //     newDropdownState.set(index, dropdownState.get(index) || false);
  //   });
  //   setDropdownState(newDropdownState);
  // }, [dropdownState, setDropdownState, permittedItems]);
  // Function to handle dropdown toggle
  const handleDropDown = (index) => {
    const newDropdownState = new Map(dropdownState);
    // to toggle dropdown when click on other dropdown
    // for (const [i, isOpen] of newDropdownState.entries()) {
    //   if (i !== index && isOpen) {
    //     newDropdownState.set(i, false);
    //   }
    // }

    newDropdownState.set(index, !newDropdownState.get(index));
    // while closing sidebar set dropdowns to hide
    if (!isSideBarOn) {
      for (const [i] of newDropdownState.entries()) {
        newDropdownState.set(i, false);
      }
    }

    setDropdownState(newDropdownState);

    const item = permittedItems[index];
    if (item.link && !item.dropdown) {
      navigate(item.link);
    }

    sessionStorage.setItem(
      "dropdownState",
      JSON.stringify(Array.from(newDropdownState.entries()))
    );
    // to handle dropdown when sidebar is minimised
    if (item.dropdown && !isSideBarOn) {
      dispatch(toggle());
      for (const [i] of newDropdownState.entries()) {
        if (i === index) {
          newDropdownState.set(i, true);
        }
      }
    }
  };

  // Function to handle sidebar toggle
  const handleSideBar = () => {
    const newDropdownState = new Map(dropdownState);
    // Toggle the sidebar state
    dispatch(toggle());
    setDropdownState(new Map(newDropdownState)); // Ensure to create a new Map instance
  };

  // Function to handle link click
  const handleLinkClick = (event, link) => {
    if (location.pathname !== link) {
      dispatch(setdatacount(""));
    }
    navigate(link[0]);
    event.stopPropagation();
  };
  // Function to check if dropdown is active
  const isActiveDropdown = (index) =>
    dropdownState.get(index) && permittedItems[index].dropdown;

  // Filter permitted items based on user access

  // Map sidebar elements
  const sidebarElements = permittedItems?.map((item, index) => (
    <li onClick={() => handleDropDown(index)} key={index}>
      <div
        className={`sidebar-link df Color${
          location.pathname === item.link ||
          (item.dropDownData &&
            item.dropDownData.some(
              (dropItem) =>
                Array.isArray(dropItem.link) &&
                dropItem.link.includes(location.pathname)
            ))
            ? "1"
            : ""
        }`}
        // data-tooltip-id="tooltip-arrow"
        data-tooltip-content={isSideBarOn ? "" : item.text}
      >
        <div className="df sidebar-link-right">
          <img
            src={
              // location.pathname === item.link ||
              // (item.dropDownData &&
              //   item.dropDownData.some(
              //     (dropItem) => location.pathname === dropItem.link
              //   ))
              //   ? item.activeImgSrc
              item.imgSrc
            }
            alt={item.alt}
            className={`${
              dropdownState[index] && item.dropdown ? "Border-Color" : ""
            }`}
          />
          <p
            className={`${
              location.pathname === item.link ||
              (item.dropDownData &&
                item.dropDownData.some(
                  (dropItem) =>
                    Array.isArray(dropItem.link) &&
                    dropItem.link.includes(location.pathname)
                ))
                ? "Border-Color"
                : "sidebar-link-title"
            }`}
          >
            {item.text}
          </p>
        </div>
        {item.arrowImage && (
          <div
            className={`homesidebar-rightarrow ${
              isActiveDropdown(index) ? "arrow-down" : ""
            }`}
          >
            <img
              src={
                item.dropDownData &&
                item.dropDownData.some(
                  (dropItem) => location.pathname === dropItem.link
                )
                  ? arrowIconactive
                  : arrowIcon
              }
              alt="icon"
            />
          </div>
        )}
      </div>
      {/* // Mapping dropdown elements */}

      {
        <div
          className={`dropdown-line df ${
            isActiveDropdown(index) ? "active" : ""
          }`}
          style={
            isSideBarOn && isActiveDropdown(index)
              ? { height: rem(item.calculatedHeight) }
              : { padding: "0" }
          }
        >
          <div className="absline"></div>
          <div className={` dropdown-link df`}>
            <ul className={`df  `}>
              {(item.dropDownData || []).map((dropItem, dropIndex) => (
                <div
                  className={`sidebar-dropdown ${
                    dropItem.link.some((link) => location.pathname === link)
                      ? "activelink"
                      : ""
                  }`}
                  key={dropIndex}
                >
                  <li
                    onClick={(event) => handleLinkClick(event, dropItem.link)}
                    className={`df  ${
                      dropItem.link.some((link) => location.pathname === link)
                        ? "link-col"
                        : "link-col1"
                    } dropdown-links`}
                    style={{ gap: rem(8) }}
                  >
                    {dropItem.title}
                    {dropItem.link.some((link) => location.pathname === link) &&
                      dropItem?.count && (
                        <span
                          className="total-count-box-sidebar df"
                          style={{
                            display: DataCount >= 0 ? "" : "none",
                          }}
                        >
                          {DataCount ? DataCount : 0}
                        </span>
                      )}
                  </li>
                </div>
              ))}
            </ul>
          </div>
        </div>
      }
    </li>
  ));

  const skeletonElements = (
    <div className="w100">
      {Array(6) // Creates an array with 6 empty slots
        .fill(null) // Fills the array with 'null' values
        .map(
          (
            _,
            index // Iterates over each slot
          ) => (
            <div key={index} className="skeleton df align-center"></div>
          )
        )}
    </div>
  );

  // Rendering the Sidebar component

  const handleUserNavigate = () => {
    navigate("/user");
  };

  const handleuserprofile = () => {
    navigate("/SpiraAI/user-profile");
  };
  return (
    <aside className="sidebar" style={{ position: "relative" }}>
      <div className="logo_img">
        <img src={!isSideBarOn ? SpiraLogo : logoImg} alt="" />
      </div>
      <span className="sidebar_line df">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={"100%"}
          height={rem(6)}
          viewBox="0 0 264 6"
          fill="none"
        >
          <path
            d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM258.333 3C258.333 4.47276 259.527 5.66667 261 5.66667C262.473 5.66667 263.667 4.47276 263.667 3C263.667 1.52724 262.473 0.333333 261 0.333333C259.527 0.333333 258.333 1.52724 258.333 3ZM3 3.5H261V2.5H3V3.5Z"
            fill="#E9E9E9"
          />
        </svg>
      </span>
      <div className="sidebar-container">
        <ul className={`home-sidebar`}>
          {sidebarElements ? <>{sidebarElements}</> : skeletonElements}
        </ul>
        <span className="sidebar_line df mAuto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={"100%"}
            height={rem(6)}
            viewBox="0 0 264 6"
            fill="none"
          >
            <path
              d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM258.333 3C258.333 4.47276 259.527 5.66667 261 5.66667C262.473 5.66667 263.667 4.47276 263.667 3C263.667 1.52724 262.473 0.333333 261 0.333333C259.527 0.333333 258.333 1.52724 258.333 3ZM3 3.5H261V2.5H3V3.5Z"
              fill="#E9E9E9"
            />
          </svg>
        </span>
        {/* profile section */}
        <div className="bottom_main home-sidebar">
          {access?.user_management && (
            <div
              className={`sidebar-link df Color${
                location.pathname === "/user" ? "1" : ""
              }`}
              onClick={handleUserNavigate}
            >
              <div className="df sidebar-link-right">
                <img
                  src={location.pathname === "/user" ? Useractive : User}
                  alt="error"
                />
                <p
                  className={
                    location.pathname === "/user"
                      ? "Border-Color"
                      : "sidebar-link-title"
                  }
                >
                  User Management
                </p>
              </div>
            </div>
          )}
          <div
            className="profile_section"
            onClick={handleuserprofile}
            style={{ cursor: "pointer" }}
          >
            {!isSideBarOn && <img src={userImage} alt="error" />}
            <div className="left-profile df">
              {isSideBarOn ? (
                <>
                  <img src={userImage} alt="error" />

                  <div className="profile">
                    <p className="profile_name">{access?.name}</p>
                    <p className="profile_email">{access?.user_email}</p>
                  </div>
                </>
              ) : (
                " "
              )}
            </div>
            {isSideBarOn ? (
              <div className="right-profile">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={rem(24)}
                  height={rem(24)}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5.616 20C5.15533 20 4.771 19.846 4.463 19.538C4.155 19.23 4.00067 18.8453 4 18.384V5.616C4 5.15533 4.15433 4.771 4.463 4.463C4.77167 4.155 5.156 4.00067 5.616 4H12.019V5H5.616C5.462 5 5.32067 5.064 5.192 5.192C5.06333 5.32 4.99933 5.46133 5 5.616V18.385C5 18.5383 5.064 18.6793 5.192 18.808C5.32 18.9367 5.461 19.0007 5.615 19H12.019V20H5.616ZM16.462 15.539L15.76 14.819L18.079 12.5H9.192V11.5H18.079L15.759 9.18L16.461 8.462L20 12L16.462 15.539Z"
                    fill="#454545"
                  />
                </svg>
              </div>
            ) : (
              " "
            )}
          </div>
        </div>
      </div>
      <div className={``} onClick={handleSideBar}>
        <img
          className={`toggle-sidebar-icon ${
            isSideBarOn ? "close-sidebar" : "open-sidebar"
          }`}
          src={sideBarClose}
          alt="icon"
          data-tooltip-id="tooltip-arrow"
          data-tooltip-content={isSideBarOn ? "Close Sidebar" : "Open Sidebar"}
        />
      </div>
      <Tooltip
        id="tooltip-arrow"
        place="bottom"
        style={{
          fontSize: rem(16),
          padding: rem(7),
          zIndex: "2",
          borderRadius: rem(5),
        }}
      />
    </aside>
  );
};

export default SideBar;
