import React from "react";
import notaccess from "../Assets/notaccess.svg";
import spiraLogo from "../Assets/LoginPage/spira 1.svg";

const NotAccess = () => {
  return (
    <section
      className="denied"
      style={{ height: "100vh", position: "relative" }}
    >
      <div className="login-logo">
        <img src={spiraLogo} alt="img" />
      </div>
      <div className="df notaccess">
        <img src={notaccess} alt="Not Access" style={{ height: "50%" }} />
      </div>
    </section>
  );
};

export default NotAccess;
