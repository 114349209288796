import React, { useState, useEffect, useCallback } from "react";
import "chart.js/auto";
import { rem } from "../../../Components/Rem_func";
import { Bar } from "react-chartjs-2";
const SalesBieChart = ({ users }) => {
  const handle = useCallback(
    (prop) => {
      const calculate =
        (users || [])?.filter((pending) =>
          pending?.reminder_status?.includes(prop)
        ) || [];
      return calculate?.length || 0;
    },
    [users] // Add 'users' as a dependency
  );
  const [conversion, setConversion] = useState({
    total: users?.length || 0,
    pending: handle("pending") || 0,
    success: handle("success") || 0,
    order_placed: handle("order_placed") || 0,
  });

  useEffect(() => {
    setConversion({
      total: users?.length || 0,
      pending: handle("pending") || 0,
      success: handle("success") || 0,
      order_placed: handle("order_placed") || 0,
    });
  }, [users, handle]);
  const datas = [
    {
      name: "Successful conversion",
      value: conversion?.order_placed || 0,

      color: "#1E6641",
      percentage: (conversion?.order_placed / conversion?.total) * 100 || 0,
    },
    {
      name: "Unsuccessful conversion",
      value: conversion?.success || 0,
      color: "#558B70",
      percentage: (conversion?.success / conversion?.total) * 100 || 0,
    },
    {
      name: "Quoted value Missing",
      value: conversion?.pending || 0,
      color: "#CEDDD5",
      percentage: (conversion?.pending / conversion?.total) * 100 || 0,
    },
    {
      name: "Total Enquiries",
      value: conversion?.total || 0,
      color: "#BED2C8",
      percentage: 100,
    },
  ];
  const data = {
    labels: [
      "Successful conversion",
      "Unsuccessful conversion",
      "Quoted value Missing",
      "Total Enquiries",
    ],
    datasets: [
      {
        type: "bar",
        barPercentage: 0.5,
        borderRadius: 10,
        data: [
          conversion?.order_placed || 0,
          conversion?.success || 0,
          conversion?.pending || 0,
          conversion?.total || 0,
        ],
        backgroundColor: ["#1E6641", "#558B70", "#CEDDD5", "#BED2C8"],
        hoverBackgroundColor: ["#1E6641", "#558B70", "#CEDDD5", "#BED2C8"],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y1: {
        position: "right",
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset;
            const value = dataset.data[tooltipItem.dataIndex];
            const percentage = datas[tooltipItem.dataIndex]?.percentage || 0;
            return `Record: ${value}(${percentage.toFixed(2)}%)`;
          },
        },
      },
    },
  };

  return (
    <div className="sales-bie-chart">
      <div className="chart-title">By TAT Bucket</div>
      <div className="chart-wrapper">
        <div className="legend-wrapper">
          {datas.map((entry, index) => (
            <div key={`legend-${index}`} style={{ paddingTop: rem(5) }}>
              <div className="legend-item">
                <div
                  className="legend-color"
                  style={{ backgroundColor: entry.color }}
                />
                <p>{entry.name}</p>
              </div>
              <p className="ktg">{`${
                entry?.value
              } (${entry?.percentage?.toFixed(2)}%)`}</p>
            </div>
          ))}
        </div>
        <div style={{ width: rem(174), height: rem(171) }}>
          <Bar data={data} options={options} />{" "}
        </div>
      </div>
    </div>
  );
};

export default SalesBieChart;
