import React, { useMemo, useEffect } from "react";
import { rem } from "../../../Components/Rem_func";
import DraggableTable from "../../../Components/TableDrag_func";
const AwaitingOrderTable = ({
  handleOpen,
  users,
  filteredData,
  loading,
  renderEmptyOrValue,
  totalCount,
  defaultPageSize,
  DateSorter,
  searchData,
  filters,
  setSelectedData,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const columns = [
    {
      title: "ACK Number",
      dataIndex: "new_ref_num",
      key: "new_ref_num",
      width: rem(124),
      className: "",
      sorter: (a, b) =>
        (a.new_ref_num || "").localeCompare(b.new_ref_num || ""),
      render: (text, record) =>
        renderEmptyOrValue(text, "", record.new_ref_num_class),
    },
    {
      title: "Sales Team Member",
      dataIndex: "sales_person_name",
      key: "sales_person_name",
      className: "",
      sorter: (a, b) =>
        (a.sales_person_name || "").localeCompare(b.sales_person_name || ""),
      width: rem(136),
      render: (text, record) =>
        renderEmptyOrValue(
          text?.toLowerCase(),
          true,
          record.sales_person_name_class
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: rem(146),
      render: () => <span className="orej status">Order Rejected</span>,
      className: "",
    },
    {
      title: "Quote Value",
      dataIndex: "currency_value",
      key: "currency_value",
      sorter: (a, b) => {
        // Ensure that both 'a.order_value' and 'b.order_value' are clean numeric strings
        const cleanValueA = a.currency_value?.replace(/[^0-9.-]+/g, ""); // Remove all non-numeric characters except period (.) and minus (-)
        const cleanValueB = b.currency_value?.replace(/[^0-9.-]+/g, "");

        // Parse the cleaned values as floats
        const valueA = parseFloat(cleanValueA) || 0;
        const valueB = parseFloat(cleanValueB) || 0;

        // Compare the two numerical values
        return valueA - valueB;
      },
      width: rem(104),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      sorter: (a, b) => (a.currency || "").localeCompare(b.currency || ""),
      width: rem(114),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Awaiting Order",
      dataIndex: "quotation_time_utc",
      key: "quotation_time_utc",
      width: rem(114),
      sorter: (a, b) => {
        const quoteDateA = new Date(a.quotation_time_utc || "");
        const quoteAgeingDaysA = !isNaN(quoteDateA)
          ? Math.floor((new Date() - quoteDateA) / (1000 * 60 * 60 * 24))
          : null;

        const quoteDateB = new Date(b.quotation_time_utc || "");
        const quoteAgeingDaysB = !isNaN(quoteDateB)
          ? Math.floor((new Date() - quoteDateB) / (1000 * 60 * 60 * 24))
          : null;

        return quoteAgeingDaysA - quoteAgeingDaysB;
      },
      render: (text, record) => {
        const quoteDate = new Date(text); // 'text' is the UTC date
        const currentUtcDate = new Date().toISOString(); // Get the current time in UTC format

        const quoteAgeingDays = !isNaN(quoteDate.getTime())
          ? (new Date(currentUtcDate).getTime() - quoteDate.getTime()) /
            (1000 * 60 * 60 * 24)
          : null;

        return (
          <span>
            {text
              ? quoteAgeingDays !== null
                ? `${Math.ceil(quoteAgeingDays)} days`
                : "--"
              : "--"}
          </span>
        );
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      sorter: (a, b) => (a.reason || "").localeCompare(b.reason || ""),
      width: rem(164),
      render: (text) => renderEmptyOrValue(text),
      className: "tl",
    },
    {
      title: "Buyer Seller POC",
      dataIndex: "client_person_name",
      key: "client_person_name",
      width: rem(134),
      className: "noPadding tl",
      style: { padding: 0 },
      render: (text, record) => (
        <span className="df flexColumn align-start">
          {renderEmptyOrValue(text, false, record.client_person_name_class)}

          {renderEmptyOrValue(
            record.client_email ? record.client_email.split("<")[0] : "", // Extract email up to '<' character
            false,
            record.client_email_class
          )}
        </span>
      ),
      sorter: (a, b) =>
        (a.client_person_name || "").localeCompare(b.client_person_name || ""),
    },
    {
      title: "Enquiry Subject",
      dataIndex: "client_subject",
      key: "client_subject",
      width: rem(174),
      className: "tl",
      render: (text, record) =>
        renderEmptyOrValue(text, "", record.client_subject_class),
      sorter: (a, b) =>
        (a.client_subject || "").localeCompare(b.client_subject || ""),
    },
    {
      title: `Buyer / Seller Time`,
      dataIndex: "client_email_time_utc",
      key: "client_email_time_utc",
      className: "noPadding",

      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),
      width: rem(134),
      sorter: DateSorter("client_email_time_utc"),
    },
    {
      title: `Quotation Email Time`,
      dataIndex: "quotation_time_utc",
      key: "quotation_time_utc",
      className: "noPadding",

      sorter: DateSorter("quotation_time_utc"),
      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),
      width: rem(134),
    },
    {
      title: `Rejected Time`,
      dataIndex: "rejection_date_time",
      key: "rejection_date_time",
      className: "noPadding",

      sorter: DateSorter("rejection_date_time"),
      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),
      width: rem(134),
    },
  ];

  const filteredUsers = useMemo(() => {
    return (users || [])
      .filter((user) => {
        const convertToLocalDate = (utcDateString) => {
          if (!utcDateString) return null;
          const utcDate = new Date(utcDateString);
          return new Date(
            utcDate.getFullYear(),
            utcDate.getMonth(),
            utcDate.getDate(),
            utcDate.getHours(),
            utcDate.getMinutes(),
            utcDate.getSeconds()
          );
        };
        const matchesFilteredData =
          (user?.new_ref_num || "")
            .toLowerCase()
            .includes((filteredData?.ack_number || "").toLowerCase()) &&
          (user?.sales_person_name || "")
            .toLowerCase()
            .includes((filteredData?.sales_person_name || "").toLowerCase()) &&
          (user?.client_person_name || "")
            .toLowerCase()
            .includes((filteredData?.client_person_name || "").toLowerCase()) &&
          (user?.client_subject || "")
            .toLowerCase()
            .includes((filteredData?.client_subject || "").toLowerCase()) &&
          (filteredData.client_email_from
            ? convertToLocalDate(user.client_email_time_utc) >=
              new Date(filteredData.client_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData.client_email_to
            ? convertToLocalDate(user.client_email_time_utc) <=
              new Date(filteredData.client_email_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData.sales_email_from
            ? convertToLocalDate(user.sales_email_time_utc) >=
              new Date(filteredData.sales_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData.sales_email_to
            ? convertToLocalDate(user.sales_email_time_utc) <=
              new Date(filteredData.sales_email_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData.ack_email_from
            ? convertToLocalDate(user.ack_time_utc) >=
              new Date(filteredData.ack_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData.ack_email_to
            ? convertToLocalDate(user?.ack_time_utc) <=
              new Date(filteredData?.ack_email_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData.quotation_email_from
            ? convertToLocalDate(user?.quotation_time_utc) >=
              new Date(filteredData?.quotation_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData.quotation_email_to
            ? convertToLocalDate(user?.quotation_time_utc) <=
              new Date(filteredData?.quotation_email_to).setHours(
                23,
                59,
                59,
                999
              )
            : true) &&
          (filteredData?.reject_date_from
            ? convertToLocalDate(user?.rejection_date_time) >=
              new Date(filteredData?.reject_date_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.reject_date_to
            ? convertToLocalDate(user?.rejection_date_time) <=
              new Date(filteredData?.reject_date_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData?.max_quote_value
            ? user?.currency_value
              ? Number(filteredData?.max_quote_value) >=
                Number(user?.currency_value?.replace(/,/g, ""))
              : false
            : true) &&
          (filteredData?.min_quote_value
            ? user?.currency_value
              ? Number(filteredData?.min_quote_value) <=
                Number(user?.currency_value?.replace(/,/g, ""))
              : false
            : true);

        const matchesSearchData = searchData
          ? (user?.new_ref_num || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_subject || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_mail || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_email || "")
              .toLowerCase()
              .includes(searchData.toLowerCase())
          : true;

        const SalesPersonName = filters?.sales_team
          ? (user?.sales_person_name || "")
              .toLowerCase()
              .includes((filters?.sales_team || "").toLowerCase())
          : true;
        return matchesFilteredData && matchesSearchData && SalesPersonName;
      })
      .map((user) => {
        // Highlight search matches in relevant fields
        const matchReferenceNumber =
          searchData &&
          user.new_ref_num?.toLowerCase().includes(searchData.toLowerCase());

        const matchClientPersonName =
          searchData &&
          user.client_person_name
            ?.toLowerCase()
            .includes(searchData.toLowerCase());

        const matchSalesPersonName =
          searchData &&
          user.sales_person_name
            ?.toLowerCase()
            .includes(searchData.toLowerCase());

        const matchClientSubject =
          searchData &&
          user.client_subject?.toLowerCase().includes(searchData.toLowerCase());

        const matchClientEmail =
          searchData &&
          user.client_email?.toLowerCase().includes(searchData.toLowerCase());

        return {
          ...user, // Return all original user data
          new_ref_num_class: matchReferenceNumber ? "highlighted-text" : "",
          client_person_name_class: matchClientPersonName
            ? "highlighted-text"
            : "",
          sales_person_name_class: matchSalesPersonName
            ? "highlighted-text"
            : "",
          client_subject_class: matchClientSubject ? "highlighted-text" : "",
          client_email_class: matchClientEmail ? "highlighted-text" : "",
        };
      });
  }, [users, filteredData, searchData, filters]);

  useEffect(() => {
    const order_placed = filteredUsers.filter(
      (item) => item.reminder_status === "order_placed"
    ).length;
    const total_Count = filteredUsers.length;
    const total_Pending = filteredUsers?.filter(
      (item) => item?.reminder_status === "pending"
    ).length;

    const total_success = filteredUsers?.filter(
      (item) => item?.reminder_status === "success"
    ).length;
    const total_rejected = filteredUsers?.filter(
      (item) => item?.reminder_status === "rejected"
    ).length;

    totalCount({
      total: total_Count,
      page_count: total_rejected,
      pending: total_Pending,
      success: total_success,
      order_placed: order_placed,
      rejected: total_rejected,
    });
  }, [filteredUsers, totalCount]);
  return (
    <DraggableTable
      handleOpen={handleOpen}
      filteredUsers={filteredUsers
        ?.filter((item) => item?.reminder_status === "rejected")
        ?.sort(
          (a, b) =>
            new Date(a?.rejection_date_time) - new Date(b?.rejection_date_time)
        )
        ?.reverse()}
      loading={loading}
      baseColumns={columns}
      drag={true}
      defaultPageSize={defaultPageSize}
      select={true}
      setSelectedData={setSelectedData}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
    />
  );
};

export default AwaitingOrderTable;
