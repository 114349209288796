import React from "react";
import { Dropdown, Space } from "antd";
import { rem } from "../Rem_func";
const SizeChanger = ({ count, items }) => {
  return (
    <div className="df pageSize">
      {" "}
      Show{" "}
      <span className="df">
        <Space wrap>
          <Dropdown menu={{ items }} placement="bottomLeft">
            <span
              className="filter-head pagecount-head"
              //   data-tooltip-id="tooltip-arrow"
              //   data-tooltip-content="Filter"
            >
              {count}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(16)}
                height={rem(16)}
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.23423 5.83432C4.38425 5.68434 4.5877 5.60009 4.79983 5.60009C5.01196 5.60009 5.21541 5.68434 5.36543 5.83432L7.99983 8.46872L10.6342 5.83432C10.708 5.75791 10.7963 5.69696 10.8939 5.65504C10.9915 5.61311 11.0965 5.59104 11.2027 5.59012C11.3089 5.5892 11.4143 5.60944 11.5126 5.64966C11.6109 5.68989 11.7002 5.74929 11.7753 5.8244C11.8505 5.89952 11.9099 5.98884 11.9501 6.08716C11.9903 6.18547 12.0106 6.29082 12.0096 6.39704C12.0087 6.50326 11.9866 6.60824 11.9447 6.70584C11.9028 6.80345 11.8418 6.89172 11.7654 6.96552L8.56543 10.1655C8.41541 10.3155 8.21196 10.3997 7.99983 10.3997C7.7877 10.3997 7.58425 10.3155 7.43423 10.1655L4.23423 6.96552C4.08425 6.8155 4 6.61205 4 6.39992C4 6.18779 4.08425 5.98434 4.23423 5.83432Z"
                  fill="#333333"
                />
              </svg>
            </span>
          </Dropdown>
        </Space>
      </span>
      Entries
    </div>
  );
};

export default SizeChanger;
