import React, { useEffect, useState } from "react";
import "./UserHome.scss";
// import { Dropdown, Space } from "antd";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import { rem } from "../../../Components/Rem_func";
import _http from "../../../Utils/Api/_http";
import SnackBar from "../../../Components/SnackBar";
// import Speedometer, {
//   Background,
//   Arc,
//   Needle,
//   Progress,
//   Marks,
//   Indicator,
// } from "react-speedometer";
// Register components
ChartJS.register(ArcElement, Tooltip, Legend);
const UserHome = () => {
  // const pieParams = { height: 143, width: 143, margin: { right: 5 } };
  const [userStatus, setUserStatus] = useState();
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };
  const data = {
    datasets: [
      {
        labels: ["Active", "Inactive"],
        data: [
          userStatus?.active_users_count || 0,
          userStatus?.inactive_users_count || 0,
        ], // Your data values
        backgroundColor: [
          "rgba(30, 102, 65, 1)",
          "rgba(99, 200, 155, 1)",
          // "rgba(239, 244, 251, 1)",
        ], // Colors for each slice
        hoverBackgroundColor: [
          "rgba(30, 102, 65, 1)",
          "rgba(99, 200, 155, 1)",
          // "rgba(239, 244, 251, 1)",
        ], // Hover colors
        borderWidth: 0.1,
        borderColor: "rgba(239, 244, 251, 1)", // Border color for slices
        cutout: "85%",
        borderRadius: 5,
      },
    ],
  };
  const options = {
    rotation: -135, // Start from bottom center
    circumference: 270,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataset = context.dataset;
            const dataIndex = context.dataIndex;
            const label = dataset.labels[dataIndex];

            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
  };

  const fetchData = async () => {
    try {
      const response = await _http.get("/get-active-users");
      setUserStatus(response.data);
    } catch (error) {
      setToast({ error: true });
      setError("Error Fetching User Data");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="user_home">
      <div className="upper">
        <p className="userM">User Management</p>
      </div>

      <div className="mid">
        <Doughnut data={data} options={options} />

        {/* <Speedometer
          value={userStatus?.active_users_count || 0}
          max={100}
          width={200}
          height={148}
          angle={220}
          lineCap="round"
          accentColor="#1E6641"
        >
          <Arc arcWidth={20} />
          <Progress arcWidth={20} />
          <Needle color="blue" />
        </Speedometer> */}
      </div>

      <div className="below">
        <div className="com below-userData">
          <div className="comcontaineruser">
            <p className="per below-userStatus">
              {userStatus?.active_users_count}
            </p>
            <p className="enq below-useractive">Active Users</p>
            <p
              className="line-blockuser"
              style={{ backgroundColor: "#1E6641" }}
            ></p>
          </div>
        </div>
        <div className="com below-userData">
          <div className="comcontaineruser">
            <p className="per below-userStatus">
              {userStatus?.inactive_users_count}
            </p>
            <p className="enq below-useractive">Inactive Users</p>
            <p
              className="line-blockuser"
              style={{ backgroundColor: "#DEDEDE" }}
            ></p>
          </div>
        </div>
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </div>
  );
};

export default UserHome;
