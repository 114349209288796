import React from "react";
import SalesTracker from "../STcomponents/SalesTracker/SalesTracker";
import RejectOrderTable from "./RejectOrderTable";

const AwaitingOrder = () => {
  return (
    <>
      <SalesTracker
        title={"Order Rejected"}
        UserDataTable={RejectOrderTable}
        api={"/api/users_db"}
      />
    </>
  );
};

export default AwaitingOrder;
