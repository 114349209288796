import React from "react";
import accessDenied from "../../Assets/QuizMobile/accessDenied.svg";
import logoImg from "../../Assets/QuizMobile/spiraLogo1.svg";

export default function MobileAccessDenies() {
  return (
    <div className="access-denies-container">
      <div className="logo-img">
        <img src={logoImg} alt="error" />
      </div>
      <div className="denied_img">
        <img src={accessDenied} alt="error" />
      </div>
    </div>
  );
}
