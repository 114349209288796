import { Drawer } from "antd";
import React from "react";
import "./FilterSlider.scss";
const FilterSlider = ({
  setOpenfilter,
  width,
  title,
  openfilter,
  handleOpenChange,
  hide,
  DashBoardFilter,
  userList,
  setFilteredData,
  setFilters,
  filteredData,
  roles,
  names,
}) => {
  return (
    <Drawer
      className="edit-Drawer"
      title="Filter"
      placement="right"
      open={openfilter}
      onOpenChange={handleOpenChange}
      onClose={() => setOpenfilter(false)}
      width={width} // Width of the sliding menu
      height={816}
    >
      <DashBoardFilter
        hide={hide}
        title={title}
        userList={userList || " "}
        setFilteredData={setFilteredData}
        filteredData={filteredData}
        setFilters={setFilters}
        roles={roles || " "}
        names={names || " "}
      />
    </Drawer>
  );
};

export default FilterSlider;
