import { createGlobalStyle } from "styled-components";
import { rem } from "./Rem_func";
const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: ${rem(8)};
    height: ${rem(8)};
  }

::-webkit-scrollbar-thumb {
    background-color: #011c226b;
    background-clip: content-box;
    border-radius: ${rem(10)};
    width: ${rem(8)};
  }

::-webkit-scrollbar-thumb:hover {
    background-color: #011c2299;
  }
`;

// Other global styles can be added here

export default GlobalStyle;
