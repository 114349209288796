import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import { combineReducers } from "redux";

// Create a slice for the app state, managing the sidebar toggle
const SideBarSlice = createSlice({
  name: "app",
  initialState: {
    isToggleOn: true,
  },
  reducers: {
    toggle: (state) => {
      state.isToggleOn = !state.isToggleOn;
    },
  },
});

// Create a slice for managing user data
const UserDataSlice = createSlice({
  name: "userdata",
  initialState: {
    value: "",
  },
  reducers: {
    setUserdata: (state, action) => {
      state.value = action.payload;
    },
  },
});

const DataCount = createSlice({
  name: "datacount",
  initialState: {
    value: "",
  },
  reducers: {
    setdatacount: (state, action) => {
      state.value = action.payload;
    },
  },
});
const imageSlice = createSlice({
  name: "image",
  initialState: {
    isimageOn: true,
  },
  reducers: {
    toggleImage: (state) => {
      state.isimageOn = !state.isimageOn;
    },
  },
});
// Combine all slices into a single rootReducer
const rootReducer = combineReducers({
  // Sidebar slice with persistence settings
  app: persistReducer(
    { key: "root", storage: sessionStorage, whitelist: ["isToggleOn"] },
    SideBarSlice.reducer
  ),
  // User data slice with persistence settings
  userdata: persistReducer(
    { key: "root2", storage: sessionStorage, whitelist: ["value"] },
    UserDataSlice.reducer
  ),
  datacount: persistReducer(
    { key: "root3", storage: sessionStorage, whitelist: ["value"] },
    DataCount.reducer
  ),
  image: imageSlice.reducer,
});

// Export actions and selectors for each slice
export const { toggle } = SideBarSlice.actions;
export const selectIsToggleOn = (state) => state.app.isToggleOn;

export const { setUserdata } = UserDataSlice.actions;
export const selectUserData = (state) => state.userdata.value;

export const { setdatacount } = DataCount.actions;
export const selectDataCount = (state) => state.datacount.value;

export const { toggleImage } = imageSlice.actions;
export const selectIsImageOn = (state) => state.image.isimageOn;
// Export the combined rootReducer
export default rootReducer;
