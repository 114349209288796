import React, { useEffect, useState } from "react";
import { Empty } from "antd";
import searchIcon from "./../../Assets/UserIcon/usersearchicon.svg";
import Delete from "./../../Assets/Quiz/Delete.svg";
// import Pdf from "./../../Assets/Quiz/PDF.svg";
import document from "./../../Assets/Quiz/Manual.svg";
import csv from "./../../Assets/Quiz/csv.svg";
import xlsx from "./../../Assets/Quiz/xlsx.svg";
import { useNavigate } from "react-router-dom";
import _http from "../../Utils/Api/_http";
import SnackBar from "../../Components/SnackBar";
import Button from "../../Components/Button/Button";
import { rem } from "../../Components/Rem_func";
// import Animation from "../../Assets/Quiz/Animation.gif";
import QRbutton from "../../Assets/Quiz/QRbutton.svg";
import QuizModel from "./QuizModel";
import QrModel from "./QrModel";

const QuizRecent = () => {
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [qrpng, setQrPng] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [searchValue, SetSearchValue] = useState("");
  const [apidata, setApidata] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [poolname, setPoolname] = useState("");
  //getting the pool data from the admin questions api
  const [Pools, setPools] = useState();
  const [data, setData] = useState([
    {
      title: "",
      question_text: "",
      choices: [
        { choice_text: "", is_correct: false },
        { choice_text: "", is_correct: false },
        { choice_text: "", is_correct: false },
        { choice_text: "", is_correct: false },
      ],
    },
  ]);

  const calculateTime = (registeredTime) => {
    const registeredDate = new Date(registeredTime);
    const currentDate = new Date();

    const diffMs = currentDate - registeredDate; // Difference in milliseconds
    const totalSeconds = Math.floor(diffMs / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const totalDays = Math.floor(totalHours / 24);

    if (totalDays > 0) {
      return `${totalDays} day${totalDays > 1 ? "s" : ""} ago`;
    } else if (totalHours > 0) {
      return `${totalHours} hour${totalHours > 1 ? "s" : ""} ago`;
    } else if (totalMinutes > 0) {
      return `${totalMinutes} minute${totalMinutes > 1 ? "s" : ""} ago`;
    } else {
      return `${totalSeconds} second${totalSeconds > 1 ? "s" : ""} ago`;
    }
  };

  // fetching api

  const RecentQuestion = async () => {
    setLoading(true);
    try {
      const response = await _http.get("/quiz/Get-question-pools");
      setApidata(response.data);
      setFilteredData(response.data);

      setToast(
        (response.data.message && { submit: true }) ||
          (response.data.error && { error: true })
      );
      setError(response.data.message || response.data.error);
      setLoading(false);
    } catch (er) {
      setError("Error Fetching  Data");
      setToast({ error: true });
    }
  };
  useEffect(() => {
    RecentQuestion();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const showModal = async (id, name) => {
    setIsModalOpen(true);
    setLoading(true);
    try {
      const response = await _http.post(
        "/quiz/generate-qr-code",
        {
          id: id,
        },
        {
          responseType: "blob",
        }
      );
      sessionStorage.setItem("QuizId", id);

      const url = URL.createObjectURL(response.data);
      setQrPng(url);
      setLoading(false);
    } catch (err) {
      setToast({ error: true });
      setError("Error Generating Qr");
      setLoading(false);
    }
    setPoolname(name);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const deleteQuestionPool = async (id) => {
    setLoading(true);
    try {
      await _http.delete("quiz/question-pools", {
        data: {
          id: id,
        },
      });
      setLoading(false);
      setTimeout(() => {
        setError("Question Pool Deleted");
        setToast({ submit: true });
      }, 1000);

      RecentQuestion();
    } catch (er) {
      setLoading(false);
    }
  };
  const Get_Recent_Question = async (id) => {
    try {
      const response = await _http.post("quiz/Admin_Questions", {
        id: id,
      });
      // title = response.data.pool.pool_name;
      setPools(response.data.pool);
      setData(response.data.questions);
    } catch (err) {
      setApidata("");
      setToast({ error: true });
      setError("❌ Something Went Wrong! Please Try Again");
    }
  };

  useEffect(() => {
    const filtered = (apidata || [])?.filter((data) =>
      data?.pool_name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchValue, apidata]);
  return (
    <section className="up-31">
      <div className="data1">
        <div className="btn1">
          <div className="q-btn-mode1">
            <button
              onClick={() => {
                navigate("/SpiraQuiz");
              }}
            >
              Upload Questions
            </button>
            <button
              className="active1"
              onClick={() => {
                navigate("/SpiraQuiz/Recent");
              }}
            >
              Recent
            </button>
          </div>
          <div className="quiz-sh-fl">
            {apidata?.length > 0 && (
              <div className="user-search df">
                <input
                  type="text"
                  placeholder="Search"
                  name="user"
                  value={searchValue}
                  onChange={(e) => SetSearchValue(e.target.value)}
                />
                <img src={searchIcon} alt="icon" />
              </div>
            )}

            {/* <Popover
              trigger="click"
              open={true}
              placement="bottomRight"
              // onOpenChange={handleOpenChange}
              // content={
              //   <FilterUser
              //     // hide={hide}
              //     // roles={roles}
              //     // names={names}
              //     // userList={userList}
              //     // filteredData={filteredData}
              //     // setFilteredData={setFilteredData}
              //   />
              // }
            >
              <Space>
                <span
                  className="filter-head"
                  data-tooltip-id="tooltip-arrow"
                  data-tooltip-content="Filter"
                >
                  <img src={filterOn ? filteron : filter} alt="icon" />{" "}
                  <img src={filterOn ? green_down : down} alt="icon" />
                </span>
              </Space>
            </Popover> */}
          </div>
        </div>
        {loading ? (
          <div className="spinners"></div>
        ) : (
          <>
            {filteredData?.length > 0 ? (
              <div className="up-box1">
                {/* <QuizDrag Comp={clicked} /> */}
                {filteredData?.map((item, index) => (
                  <div className="q-details" key={item.id}>
                    <div className="q-title">
                      <div
                        className="q-pooler"
                        onClick={() => {
                          Get_Recent_Question(item.id);
                          setOpen(true);
                          setPoolname(item.pool_name);
                        }}
                      >
                        <img
                          src={
                            item.source ===
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              ? xlsx
                              : item.source === "text/csv"
                              ? csv
                              : document
                          }
                          alt=""
                        />
                        <span>
                          {item?.pool_name}{" "}
                          <p>{`${calculateTime(item?.registered_time)}`}</p>
                        </span>
                      </div>

                      <div className="q-para">
                        <p className="schedule">
                          Scheduled On
                          <span>
                            {item?.schedule_time
                              ? new Date(item?.schedule_time)?.toLocaleString()
                              : "Not Scheduled"}
                          </span>
                        </p>

                        <p className="duration">
                          <h1>Duration</h1>
                          <span>{item?.duration_in_minutes}</span>
                          <p>mins</p>
                        </p>

                        <p className="questions">
                          No of Questions
                          <span>{item?.question_count}</span>
                        </p>

                        <img
                          src={QRbutton}
                          alt="qrbtn"
                          onClick={() => {
                            showModal(item.id, item.pool_name);
                          }}
                        />

                        <img
                          src={Delete}
                          alt="delete"
                          onClick={() => deleteQuestionPool(item.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Empty>
                <Button
                  btnname="Create One"
                  width={130}
                  height={50}
                  fs={14}
                  fw={500}
                  border={`${rem(2)} solid #1E6641`}
                  onclick={() => navigate("/SpiraQuiz")}
                />
              </Empty>
            )}
          </>
        )}
      </div>

      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      {open && (
        <QuizModel
          recent={true}
          formdata={data}
          setFormdata={setData}
          poolname={poolname}
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          Get_Recent_Question={Get_Recent_Question}
          RecentQuestion={RecentQuestion}
          Pools={Pools}
        />
      )}
      <QrModel
        png={qrpng}
        poolname={poolname}
        open={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleClose={closeModal}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </section>
  );
};

export default QuizRecent;
