import React from "react";
import PropTypes from "prop-types";
import { Space, Switch } from "antd";
const CheckBox = ({
  direction,
  checked,
  unchecked,
  onchange,
  handleBg,
  width,
  height,
  defaultValue,
}) => (
  <Space direction={direction}>
    <Switch
      checkedChildren={checked}
      unCheckedChildren={unchecked}
      onChange={onchange}
      size="default"
      style={{
        backgroundColor: handleBg,
        width: width,
        height: height,
      }}
      defaultChecked
    />
  </Space>
);

CheckBox.propTypes = {
  direction: PropTypes.string,
  checked: PropTypes.string,
  unchecked: PropTypes.string,
  colorPrimary: PropTypes.string,
};
CheckBox.defaultProps = {
  direction: "vertical",
  checked: "1",
  unchecked: "0",
};
export default CheckBox;
