import React from "react";
import SalesTrackers from "../STcomponents/SalesTracker/SalesTracker";
import ReportTable from "./ReportTable.jsx";

const Report = () => {
  return (
    <>
      <SalesTrackers
        title={"Report"}
        UserDataTable={ReportTable}
        api={"/api/report_user_db"}
        reports={true}
      />
    </>
  );
};

export default Report;
