import React, { useEffect } from "react";
import "./STeditPopup.scss";
import { Modal } from "antd";
// import Button from "../../../../Components/Button/Button";
import InputField from "../../../../Components/InputField/InputField";
import { rem } from "../../../../Components/Rem_func";
import Button from "../../../../Components/Button/Button";
import TextField from "../../../../Components/TextField/TextField";
const EditPopup = ({ user, handleClose, open, AwaitingQuotes }) => {
  // State to manage user data in the form
  const userData = {
    ack_number: user?.new_ref_num,
    sales_person_name: user?.sales_person_name,
    sales_mail: user?.sales_mail,
    client_person_name: user?.client_person_name,
    client_email: user?.client_email,
    ack_time: user?.ack_time_utc
      ? new Date(user?.ack_time_utc)?.toLocaleString()
      : "",
    quotation_time: user?.quotation_time_utc
      ? new Date(user?.quotation_time_utc)?.toLocaleString()
      : "",
    client_cc: user?.client_cc,
    client_subject: user?.client_subject,
    sales_email_time: user?.sales_email_time_utc
      ? new Date(user?.sales_email_time_utc)?.toLocaleString()
      : "",
    client_email_time: user?.client_email_time_utc
      ? new Date(user?.client_email_time_utc)?.toLocaleString()
      : "",
    reminder_status: user?.reminder_status,
    rejection_date_time: user?.rejection_date_time
      ? new Date(user?.rejection_date_time)?.toLocaleString()
      : "",
    reason: user?.reason,
    currency_value: user?.currency_value,
    currency: user?.currency,
    order_date: user?.final_order_time
      ? new Date(user?.final_order_time)?.toLocaleString()
      : "",
    order_value: user?.final_order_currency,
    mail_body: user?.plain_text,
  };

  useEffect(() => {
    if (open) {
      const firstFocusableElement = document.querySelector(".user-popup input");
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
  }, [open]);
  return (
    <>
      <Modal
        centered
        open={open}
        title="View Details"
        // onOk={() => setOpen(true)}
        onCancel={handleClose}
        width={rem(638)}
        height={rem(613)}
        className="edit-popup"
      >
        <div className="user-popup ">
          {/* Input fields for user details */}
          <div
            className="flexBox flexColumn StComp-mainContainer"
            style={{ gap: rem(22) }}
          >
            <div
              className="StComp-ack flexBox flexColumn"
              // style={{ width: rem(264), height: rem(36) }}
            >
              <div style={{ width: rem(264) }}>
                <InputField
                  label={"ack_number"}
                  labelName={"ACK Number"}
                  type={"text"}
                  fieldData={userData.ack_number}
                  readOnly={true}
                  styles={{ width: rem(264) }}
                />
              </div>
            </div>{" "}
            <div>
              <h1 className="StComp-Popup-Header">Sales</h1>
              <div className="StComp-Popup flexBox">
                <div
                  style={{
                    width: rem(264),
                  }}
                >
                  <InputField
                    label={"sales_person_name"}
                    labelName={"Team Member"}
                    type={"text"}
                    placeholder={""}
                    fieldData={userData?.sales_person_name?.toLowerCase()}
                    readOnly={true}
                    styles={{
                      textTransform: "capitalize",
                    }}
                  />
                </div>
                <div style={{ width: rem(264) }}>
                  <InputField
                    label={"sales_mail"}
                    labelName={"Person Mail"}
                    type={"text"}
                    placeholder={""}
                    fieldData={userData?.sales_mail}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
            <div>
              <h1 className="StComp-Popup-Header">Buyer / Seller Details</h1>
              <div className="StComp-Popup flexBox">
                <div style={{ width: "100%" }}>
                  <InputField
                    label={"client_person_name"}
                    labelName={"POC"}
                    type={"text"}
                    placeholder={""}
                    fieldData={userData?.client_person_name}
                    readOnly={true}
                    styles={{ textTransform: "capitalize" }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <TextField
                    label={"Email Body"}
                    labelName={"Email Body"}
                    type={"text"}
                    fieldData={userData?.mail_body?.trim()}
                    readOnly={true}
                    styles={{ textTransform: "capitalize" }}
                    height={rem(110)}
                  />
                </div>
                <div style={{ width: rem(264) }}>
                  <InputField
                    label={"client_email"}
                    labelName={"Email"}
                    type={"text"}
                    placeholder={""}
                    fieldData={userData?.client_email}
                    readOnly={true}
                  />
                </div>
                <div style={{ width: rem(264) }}>
                  <InputField
                    label={"client_email_time"}
                    labelName={"Time"}
                    type={"text"}
                    placeholder={""}
                    fieldData={userData?.client_email_time || ""}
                    readOnly={true}
                  />
                </div>

                <div style={{ width: rem(264) }}>
                  <InputField
                    label={"client_cc"}
                    labelName={"Client CC"}
                    type={"text"}
                    placeholder={""}
                    fieldData={userData?.client_cc}
                    readOnly={true}
                  />
                </div>
                <div style={{ width: rem(264) }}>
                  <InputField
                    label={"sales_email_time"}
                    labelName={"Sales to AI"}
                    type={"text"}
                    placeholder={""}
                    fieldData={userData?.sales_email_time || ""}
                    readOnly={true}
                  />
                </div>
                <div style={{ width: rem(264) }}>
                  <InputField
                    label={"client_subject"}
                    labelName={"Enquiry Subject"}
                    type={"text"}
                    placeholder={""}
                    fieldData={userData?.client_subject}
                    readOnly={true}
                  />
                </div>
                <div style={{ width: rem(264) }}>
                  <InputField
                    label={"ack_time"}
                    labelName={"AI Ack"}
                    type={"text"}
                    placeholder={""}
                    fieldData={userData?.ack_time || ""}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
            {!AwaitingQuotes && userData?.reminder_status !== "pending" && (
              <>
                <div>
                  <h1 className="StComp-Popup-Header">Quote</h1>
                  <div className="StComp-Popup flexBox">
                    <div style={{ width: rem(264) }}>
                      <InputField
                        label={"currency_value"}
                        labelName={"Value"}
                        type={"text"}
                        placeholder={""}
                        fieldData={userData?.currency_value || ""}
                        readOnly={true}
                      />
                    </div>
                    <div style={{ width: rem(264) }}>
                      <InputField
                        label={"quotation_time"}
                        labelName={"Receive Time"}
                        type={"text"}
                        placeholder={""}
                        fieldData={userData?.quotation_time || ""}
                        readOnly={true}
                      />
                    </div>
                    <div style={{ width: rem(264) }}>
                      <InputField
                        label={"currency"}
                        labelName={"Currency"}
                        type={"text"}
                        placeholder={""}
                        fieldData={userData?.currency || ""}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {!AwaitingQuotes &&
              userData?.reminder_status === "order_placed" && (
                <>
                  <div>
                    <h1 className="StComp-Popup-Header">Ordered</h1>
                    <div className="StComp-Popup flexBox">
                      <div style={{ width: rem(264) }}>
                        <InputField
                          label={"order_value"}
                          labelName={"Value"}
                          placeholder={"Order Value"}
                          fieldData={userData.order_value}
                          readOnly={true}
                        />
                      </div>
                      <div style={{ width: rem(264) }}>
                        <InputField
                          label={"order_date"}
                          labelName={"Date"}
                          placeholder={""}
                          fieldData={userData.order_date}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            {userData?.reminder_status === "rejected" && (
              <>
                <div>
                  <h1 className="StComp-Popup-Header">Order Rejected</h1>
                  <div className="StComp-Popup flexBox">
                    <div style={{ width: "100%" }}>
                      <TextField
                        labelName={"Rejected Reason"}
                        type={"text"}
                        fieldData={userData?.reason?.trim()}
                        readOnly={true}
                        styles={{ textTransform: "capitalize" }}
                        height={rem(50)}
                      />
                    </div>
                    <div style={{ width: rem(264) }}>
                      <InputField
                        labelName={"Rejected Date"}
                        placeholder={"Order Value"}
                        fieldData={userData?.rejection_date_time}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="df justify-end  stbtn">
              <Button
                btnname="Close"
                width={119}
                height={38}
                bg="#FFFFFF"
                cl="#1E6641"
                br={5}
                fs={14}
                fw={500}
                onclick={handleClose}
                border={`${rem(1)} solid #1E6641`}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditPopup;
