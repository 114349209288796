import React, { useEffect, useState } from "react";
import { Empty } from "antd";
import searchIcon from "./../../Assets/UserIcon/usersearchicon.svg";
import { useNavigate } from "react-router-dom";
import _http from "../../Utils/Api/_http";
import SnackBar from "../../Components/SnackBar";
import { rem } from "../../Components/Rem_func";
import document from "./../../Assets/Quiz/Manual.svg";
import csv from "./../../Assets/Quiz/csv.svg";
import xlsx from "./../../Assets/Quiz/xlsx.svg";
import "../Quiz/Quiz.scss";
import "./QreReport.scss";
import QRbutton from "../../Assets/Quiz/QRbutton.svg";
import { ForkLeft } from "@mui/icons-material";

const QuizRecent = () => {
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchValue, SetSearchValue] = useState("");
  const [apidata, setApidata] = useState([]);
  const [filteredData, setFilteredData] = useState();

  //   const [data, setData] = useState([]);

  const RecentQuestion = async () => {
    setLoading(true);
    try {
      const response = await _http.get(`/quiz/Get-question-pools`);
      setApidata(response.data);
      setFilteredData(response.data);
      setToast(
        (response.data.message && { submit: true }) ||
          (response.data.error && { error: true })
      );
      setError(response.data.message || response.data.error);
    } catch (er) {
      setToast({ er: true });
      setError("Error Fetching  Data");
    }
    setLoading(false);
  };
  useEffect(() => {
    RecentQuestion();
  }, []);

  const navigate = useNavigate();

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };
  const calculateTime = (registeredTime) => {
    const registeredDate = new Date(registeredTime);
    const currentDate = new Date();

    const diffMs = currentDate - registeredDate; // Difference in milliseconds
    const totalSeconds = Math.floor(diffMs / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const totalDays = Math.floor(totalHours / 24);

    if (totalDays > 0) {
      return `${totalDays} day${totalDays > 1 ? "s" : ""} ago`;
    } else if (totalHours > 0) {
      return `${totalHours} hour${totalHours > 1 ? "s" : ""} ago`;
    } else if (totalMinutes > 0) {
      return `${totalMinutes} minute${totalMinutes > 1 ? "s" : ""} ago`;
    } else {
      return `${totalSeconds} second${totalSeconds > 1 ? "s" : ""} ago`;
    }
  };

  const handleReportTable = (id) => {
    navigate(`/SpiraQuiz/ReportTable?id=${id}`);
  };

  useEffect(() => {
    const filtered = apidata?.filter((data) =>
      data?.pool_name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchValue, apidata]);
  return (
    <section className="quizz">
      <div className="df container-head1 w100">
        <span className="sales">Qre /</span>
        <span className="sales-title">Qre Report </span>
      </div>
      <div className="container-head df w100">
        <div style={{ width: "20%" }}>
          <span className="title">QRE Report</span>
        </div>
      </div>
      {/* <div className="df qre-rpt-box">
        <div className="qrerpt-box df ">
          <div
            className="rectangle"
            style={{ borderTop: `${rem(25)} solid #1FA1D9` }}
          ></div>
          <div className="reptcontainer df flexColumn justify-center">
            <span className="rpt-value">600</span>
            <span className="rpt-title">Total QR Codes</span>
          </div>
        </div>
        <div className="qrerpt-box df ">
          <div
            className="rectangle"
            style={{ borderTop: `${rem(25)} solid #FF0404` }}
          ></div>
          <div className="reptcontainer df flexColumn justify-center">
            <span className="rpt-value">600</span>
            <span className="rpt-title">Active QR Codes</span>
          </div>
        </div>
        <div className="qrerpt-box df ">
          <div
            className="rectangle"
            style={{ borderTop: `${rem(25)} solid #F5BE4F` }}
          ></div>
          <div className="reptcontainer df flexColumn justify-center">
            <span className="rpt-value">600</span>
            <span className="rpt-title">Archived QR Codes</span>
          </div>
        </div>
        <div className="qrerpt-box df ">
          <div
            className="rectangle"
            style={{ borderTop: `${rem(25)} solid #1FA1D9` }}
          ></div>
          <div className="reptcontainer df flexColumn justify-center">
            <span className="rpt-value">600</span>
            <span className="rpt-title">Total Scans</span>
          </div>
        </div>
        <div className="qrerpt-box df ">
          <div
            className="rectangle"
            style={{ borderTop: `${rem(25)} solid #1FA1D9` }}
          ></div>
          <div className="reptcontainer df flexColumn justify-center">
            <span className="rpt-value">600</span>
            <span className="rpt-title">Quiz QR Codes</span>
          </div>
        </div>
        <div className="qrerpt-box df ">
          <div
            className="rectangle"
            style={{ borderTop: `${rem(25)} solid #1FA1D9` }}
          ></div>
          <div className="reptcontainer df flexColumn justify-center">
            <span className="rpt-value">600</span>
            <span className="rpt-title">Catalogue QR Codes</span>
          </div>
        </div>
        <div className="qrerpt-box df ">
          <div
            className="rectangle"
            style={{ borderTop: `${rem(25)} solid #1FA1D9` }}
          ></div>
          <div className="reptcontainer df flexColumn justify-center">
            <span className="rpt-value">600</span>
            <span className="rpt-title">Photo QR Codes</span>
          </div>
        </div>
      </div> */}

      <div className="recentquizPage">
        <div className="up-31">
          <div className="data1">
            <div className="btn1" style={{ justifyContent: "flex-end" }}>
              <div
                className="quiz-sh-fl "
                style={{ justifyContent: "flex-end" }}
              >
                {apidata?.length > 0 && (
                  <div className="user-search df">
                    <input
                      type="text"
                      placeholder="Search"
                      name="user"
                      value={searchValue}
                      onChange={(e) => SetSearchValue(e.target.value)}
                    />
                    <img src={searchIcon} alt="icon" />
                  </div>
                )}
              </div>
              <div className="RecentQuestionsHeader">
                <div className="lftrecent">
                  <span>Quiz Title</span>
                </div>
                <div className="rgtrecent">
                  <span className="schedule">Scheduled On</span>
                  <span>Duration</span>
                  <span className="questions">No of Questions</span>
                  <span>QR Code</span>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="spinners"></div>
            ) : (
              <>
                {filteredData?.length > 0 ? (
                  <div className="up-box1">
                    {filteredData?.map((item, index) => (
                      <div
                        className="q-details"
                        key={item.id}
                        onClick={() => {
                          handleReportTable(item.id);
                        }}
                        style={{ paddingRight: rem(10) }}
                      >
                        <div className="q-title">
                          <div className="q-pooler">
                            <div className="quizrecentpng">
                              <img
                                src={
                                  item.source ===
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    ? xlsx
                                    : item.source === "text/csv"
                                    ? csv
                                    : document
                                }
                                alt=""
                              />
                            </div>
                            <span>
                              {item?.pool_name}{" "}
                              <p>{`${calculateTime(item.registered_time)}`}</p>
                            </span>
                          </div>

                          <div className="q-para" style={{ gap: rem(47) }}>
                            <p className="schedule">
                              <span>
                                {item?.schedule_time
                                  ? new Date(
                                      item?.schedule_time
                                    )?.toLocaleString()
                                  : "Not Scheduled"}
                              </span>
                            </p>

                            <p className="duration">
                              <span>{item?.duration_in_minutes} Mins</span>
                            </p>

                            <p className="questions">
                              <span>{item?.question_count}</span>
                            </p>
                            <img
                              src={QRbutton}
                              alt="qrbtn"
                              className="recentqrbtn"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Empty></Empty>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={"Quiz Uploaded Successfully"}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </section>
  );
};

export default QuizRecent;
