import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Modal } from "antd";
import "../SalesTracker/table.scss";
import _http from "../../Utils/Api/_http";
import view from "../../Assets/feedback/view.png";
import { rem } from "../../Components/Rem_func";
// import moment from "moment";
import DraggableTable from "../../Components/TableDrag_func";
import { setdatacount } from "./../../Redux/Reducers";
import { useDispatch } from "react-redux";

const FeedbackTable = ({ filteredData, totalCount, defaultPageSize }) => {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const dispatch = useDispatch();

  // Columns configuration for Ant Design Table
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await _http.get(`/api/get_all_feedback_data`);
      setFeedback(response?.data);
      dispatch(setdatacount(response.data.length));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const filteredFeedBack = useMemo(() => {
    return (feedback || [])?.filter((feedbackItem) => {
      return (
        (feedbackItem?.company_name || "")
          .toLowerCase()
          .includes((filteredData?.company_name || "").toLowerCase()) &&
        (feedbackItem?.client_name || "")
          .toLowerCase()
          .includes((filteredData?.client_name || "").toLowerCase()) &&
        (feedbackItem?.client_disignation || "")
          .toLowerCase()
          .includes((filteredData?.client_disignation || "").toLowerCase()) &&
        (feedbackItem?.email_address || "")
          .toLowerCase()
          .includes((filteredData?.email_address || "").toLowerCase()) &&
        (feedbackItem?.telephone_number || "")
          .toLowerCase()
          .includes((filteredData?.telephone_number || "")?.toLowerCase()) &&
        (!filteredData?.quality_rate ||
          feedbackItem?.quality_rate?.toLowerCase() ===
            (filteredData?.quality_rate || "")?.toLowerCase()) &&
        (!filteredData?.services_experience_rate ||
          feedbackItem?.services_experience_rate?.trim()?.toLowerCase() ===
            filteredData?.services_experience_rate?.trim()?.toLowerCase()) &&
        (!filteredData?.technical_enquires_rate ||
          feedbackItem?.technical_enquires_rate?.trim()?.toLowerCase() ===
            filteredData?.technical_enquires_rate?.trim()?.toLowerCase()) &&
        (!filteredData?.team_communication_rate ||
          feedbackItem?.team_communication_rate?.toLowerCase() ===
            filteredData?.team_communication_rate?.toLowerCase()) &&
        (!filteredData?.team_help_rate ||
          feedbackItem?.team_help_rate?.toLowerCase() ===
            (filteredData?.team_help_rate || "")?.toLowerCase()) &&
        (!filteredData?.product_quality_punctuality_rate ||
          feedbackItem?.product_quality_punctuality_rate?.toLowerCase() ===
            (
              filteredData?.product_quality_punctuality_rate || ""
            ).toLowerCase()) &&
        (!filteredData?.customer_statisfaction_rate ||
          feedbackItem?.customer_statisfaction_rate?.toLowerCase() ===
            filteredData?.customer_statisfaction_rate?.toLowerCase()) &&
        (!filteredData?.service_provider_rate ||
          feedbackItem?.service_provider_rate.toLowerCase() ===
            (filteredData?.service_provider_rate || "").toLowerCase()) &&
        (feedbackItem?.about_team_product_service || "")
          .toLowerCase()
          .includes(
            (filteredData?.about_team_product_service || "").toLowerCase()
          ) &&
        (filteredData?.form_timestamp_from
          ? new Date(feedbackItem?.form_timestamp?.split("T")[0]) >=
            new Date(filteredData?.form_timestamp_from)
          : true) &&
        (filteredData?.form_timestamp_to
          ? new Date(feedbackItem?.form_timestamp?.split("T")[0]) <=
            new Date(filteredData?.form_timestamp_to)
          : true)
      );
    });
  }, [feedback, filteredData]);

  // to render the datas in the table
  const renderEmptyOrValue = (text, TT, cln, Type) => {
    const dateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

    // Check if text matches the date-time format
    const isValidDateTime = dateTimePattern.test(text);

    // Return 'DataNR' if text is falsy
    if (!text) {
      return <span className="empty">--</span>;
    }

    // Handle date or time types
    if (Type === "date" || Type === "time") {
      if (isValidDateTime) {
        const [datePart, timePart] = new Date(text)
          ?.toLocaleString()
          ?.split(", ");
        return <span>{Type === "date" ? datePart : timePart}</span>;
      } else {
        return <span>MismatchF</span>; // Return this if the format does not match
      }
    }

    // Default case: Return the text with optional class and capitalization
    return (
      <span
        className={`${cln} w100 overflowData`}
        style={{ textTransform: TT ? "capitalize" : "none" }}
      >
        {text}
      </span>
    );
  };
  const DateSorter = (dataIndex) => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    const dateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/; // YYYY-MM-DD HH:MM:SS format

    return (a, b) => {
      // Get the date strings from the respective dataIndex
      const dateA = a[dataIndex];
      const dateB = b[dataIndex];

      // Check if the date format is valid (either datePattern or dateTimePattern)
      const isValidDateA =
        datePattern.test(dateA) || dateTimePattern.test(dateA);
      const isValidDateB =
        datePattern.test(dateB) || dateTimePattern.test(dateB);

      if (isValidDateA && isValidDateB) {
        // Both dates are valid, compare them as Date objects
        return new Date(dateA) - new Date(dateB);
      } else if (isValidDateA) {
        // Only dateA is valid, so it comes before
        return 1;
      } else if (isValidDateB) {
        // Only dateB is valid, so it comes before
        return -1;
      } else {
        // Neither date is valid, treat them as equal
        return 0;
      }
    };
  };

  const fetchFile = async (ids, endpoint) => {
    setLoading(true);
    try {
      const response = await _http.get(`/api/${endpoint}/${ids}`, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      setModalContent(
        <embed
          width={"100%"}
          height={"100%"}
          src={URL.createObjectURL(blob)}
          type="application/pdf"
        />
      );
      setModalVisible(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: "Time Stamp",
      dataIndex: "form_timestamp",
      key: "form_timestamp",
      width: rem(134),
      className: "noPadding",
      sorter: DateSorter("form_timestamp"),
      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),
    },
    // {
    //   title: "Date",
    //   dataIndex: "form_date",
    //   key: "form_date",
    //   className: " ",
    //   sorter: (a, b) => (a.form_date || "").localeCompare(b.form_date || ""),
    //   width: rem(104),
    //   render: (text) =>
    //     renderEmptyOrValue(
    //       moment(text, "MM/DD/YYYY").format("DD/MM/YYYY"),
    //       true
    //     ),
    // },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      className: " ",
      sorter: (a, b) =>
        (a.company_name || "").localeCompare(b.company_name || ""),
      width: rem(120),
      render: (text) => renderEmptyOrValue(text, true),
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      className: " ",
      sorter: (a, b) =>
        (a.client_name || "").localeCompare(b.client_name || ""),
      width: rem(124),
      render: (text) => renderEmptyOrValue(text.toLowerCase(), true),
    },
    {
      title: "Client Designation ",
      dataIndex: "client_disignation",
      key: "client_disignation",
      className: " ",

      sorter: (a, b) =>
        (a.client_disignation || "").localeCompare(b.client_disignation || ""),
      width: rem(134),
      render: (text) => renderEmptyOrValue(text, true),
    },
    {
      title: "Telephone Number",
      dataIndex: "telephone_number",
      key: "telephone_number",
      className: " ",
      sorter: (a, b) =>
        (a.telephone_number || "").localeCompare(b.telephone_number || ""),
      width: rem(134),
      render: (text) => renderEmptyOrValue(text, true),
    },

    {
      title: "Email Address",
      dataIndex: "email_address",
      key: "email_address",
      className: " ",

      sorter: (a, b) =>
        (a.email_address || "").localeCompare(b.email_address || ""),
      width: rem(164),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Quality Rate",
      dataIndex: "quality_rate",
      key: "quality_rate",
      width: rem(104),
      sorter: (a, b) =>
        (a.quality_rate || "").localeCompare(b.quality_rate || ""),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Service Experience Rate",
      dataIndex: "services_experience_rate",
      key: "services_experience_rate",
      sorter: (a, b) =>
        (a.services_experience_rate || "").localeCompare(
          b.services_experience_rate || ""
        ),

      width: rem(154),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Technical Enquires Rate",
      dataIndex: "technical_enquires_rate",
      key: "technical_enquires_rate",
      sorter: (a, b) =>
        (a.technical_enquires_rate || "").localeCompare(
          b.technical_enquires_rate || ""
        ),

      width: rem(134),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Team Communication Rate",
      dataIndex: "team_communication_rate",
      key: "team_communication_rate",
      sorter: (a, b) =>
        (a.team_communication_rate || "").localeCompare(
          b.team_communication_rate || ""
        ),

      width: rem(174),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Team Help Rate",
      dataIndex: "team_help_rate",
      key: "team_help_rate ",
      sorter: (a, b) =>
        (a.team_help_rate || "").localeCompare(b.team_help_rate || ""),

      width: rem(134),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Product Quality Punctuality Rate",
      dataIndex: "product_quality_punctuality_rate",
      key: "product_quality_punctuality_rate",
      sorter: (a, b) =>
        (a.product_quality_punctuality_rate || "").localeCompare(
          b.product_quality_punctuality_rate || ""
        ),

      width: rem(154),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Customer Satisfaction Rate",
      dataIndex: "customer_statisfaction_rate",
      key: "customer_statisfaction_rate",
      sorter: (a, b) =>
        (a.customer_statisfaction_rate || "").localeCompare(
          b.customer_statisfaction_rate || ""
        ),

      width: rem(154),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Service Provider Rate",
      dataIndex: "service_provider_rate",
      key: "service_provider_rate",
      className: " ",
      sorter: (a, b) =>
        (a.service_provider_rate || "").localeCompare(
          b.service_provider_rate || ""
        ),

      width: rem(134),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "About Team Product Service",
      dataIndex: "about_team_product_service",
      key: "about_team_product_service",
      sorter: (a, b) =>
        (a.about_team_product_service || "").localeCompare(
          b.about_team_product_service || ""
        ),

      width: rem(154),
      className: " ",

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Other Feedback",
      dataIndex: "other_feedback",
      key: "other_feedback",
      className: " ",
      sorter: (a, b) =>
        (a.other_feedback || "" || "").localeCompare(b.other_feedback || ""),

      width: rem(200),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      width: rem(64),
      render: (text, record) => (
        <div
          onClick={() => fetchFile(text, "download_docx")}
          style={{ cursor: "pointer" }}
        >
          <img
            src={view}
            alt="img"
            style={{ width: rem(24), height: rem(13) }}
          />
        </div>
      ),
    },
    {
      title: "Email Screenshot",
      dataIndex: "id",
      key: "id",
      width: rem(104),
      render: (text, record) => (
        <div
          onClick={() => fetchFile(text, "get_feedback_data")}
          style={{ cursor: "pointer" }}
        >
          <img
            src={view}
            alt="img"
            style={{ width: rem(24), height: rem(13) }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    totalCount(filteredFeedBack.length); // Set total count directly
  }, [filteredFeedBack, totalCount]);
  return (
    <div>
      <DraggableTable
        filteredUsers={filteredFeedBack}
        loading={loading}
        baseColumns={columns}
        drag={false}
        defaultPageSize={defaultPageSize}
      />

      <Modal
        // title="Docx Preview"
        className="feedback-pdf"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default FeedbackTable;
