import React, { useState, useEffect } from "react";
import QreReportTable from "./QreReportTable.jsx";
import "../SalesTracker/STcomponents/SalesTracker/SalesTracker.scss";
import SizeChanger from "../../Components/PageSizeChanger/SizeChanger.jsx";
import { rem } from "../../Components/Rem_func.jsx";
import Button from "../../Components/Button/Button.jsx";
import _http from "../../Utils/Api/_http.jsx";
import SnackBar from "../../Components/SnackBar.jsx";
const QreReport = () => {
  const [count, setCount] = useState("50");
  const PageCount = ["10", "20", "50", "100"];
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const [cert, setCert] = useState(false);
  const [quizID, setQuizId] = useState("");
  const items = PageCount.map((page) => ({
    key: page,
    label: (
      <div
        style={{ width: "100%" }}
        className="pageCount"
        onClick={() => setCount(page)}
      >
        {page}
      </div>
    ),
  }));
  const handletoast = () => {
    setToast({ submit: false, error: false });
  };
  useEffect(() => {
    // Extract the reference_number from the URL query string
    const searchParams = new URLSearchParams(window.location.search);
    const ids = searchParams.get("id");

    if (ids) {
      setQuizId(ids);
    }
  }, []);

  const deleteCert = async () => {
    try {
      await _http.delete("/quiz/delete-certificates");
    } catch (error) {
      console.log(error);
    }
  };
  const SendCertificate = async () => {
    setCert(true);
    try {
      await _http.post("quiz/distribute-certificates", { pool_id: quizID });
      setError("Certificate Send Successfully");
      setToast({ submit: true });
      deleteCert();
    } catch (error) {
      setError("Error Sending Certficates");
      setToast({ error: true });
    }

    setCert(false);
  };
  return (
    <section>
      <div className="container1" style={{ paddingTop: rem(53) }}>
        <div className="container-head df">
          <p className="title">QRE Report</p>
        </div>
        <div className="container-head2 df">
          <SizeChanger count={count} items={items} />
          <Button
            btnname={!cert ? "Distribute Cert" : "Distributing..."}
            onclick={SendCertificate}
            height={49}
            // width={256}
            fs={20}
            br={4}
            disabled={cert}
          />
        </div>

        <QreReportTable defaultPageSize={count} />
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </section>
  );
};

export default QreReport;
