import React, { useState, useEffect } from "react";
import "./STeditPopup.scss";
import { Modal } from "antd";
// import Button from "../../../../Components/Button/Button";
import InputField from "../../../../Components/InputField/InputField";
import { rem } from "../../../../Components/Rem_func";

const EditPopup = ({
  user,
  handleClose,
  open,
  updatingFun,
  handleUpdateToast,
  AwaitingQuotes,
  handleOrder,
  reports,
}) => {
  // State to manage user data in the form
  const [userData, setUserData] = useState({
    ack_number: user?.new_ref_num,
    sales_person_name: user?.sales_person_name,
    sales_mail: user?.sales_mail,
    client_person_name: user?.client_person_name,
    client_email: user?.client_email,
    ack_time: user?.ack_time_utc
      ? new Date(user?.ack_time_utc)?.toLocaleString()
      : "",
    quotation_time: user?.quotation_time_utc
      ? new Date(user?.quotation_time_utc)?.toLocaleString()
      : "",
    client_cc: user?.client_cc,
    client_subject: user?.client_subject,
    sales_email_time: user?.sales_email_time_utc
      ? new Date(user?.sales_email_time_utc)?.toLocaleString()
      : "",
    client_email_time: user?.client_email_time_utc
      ? new Date(user?.client_email_time_utc)?.toLocaleString()
      : "",
    reminder_status: user?.reminder_status,
    currency_value: user?.currency_value,
    currency: user?.currency,
    order_date: user?.final_order_time
      ? new Date(user?.final_order_time)?.toLocaleString()
      : "",
    order_value: user?.final_order_value,
  });
  // const [furtherError, setFurtherErrors] = useState({});
  const formatDate = (dateString) => {
    return dateString?.split(" ")[0];
  };

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const maxDate = getCurrentDate();

  useEffect(() => {
    if (open) {
      const firstFocusableElement = document.querySelector(".user-popup input");
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
  }, [open]);
  // const handleOrderPlaced = async () => {
  //   const requiredFields = ["order_value", "order_date"];
  //   const emptyFields = [];

  //   setFurtherErrors((prevData) => {
  //     const newData = { ...prevData };
  //     requiredFields.forEach((field) => {
  //       if (!userData[field] || userData[field].toString().trim() === "") {
  //         newData[`${field}_error`] = true;
  //         emptyFields.push(field);
  //       } else {
  //         newData[`${field}_error`] = false;
  //       }
  //     });
  //     return newData;
  //   });

  //   // Focus on all empty fields sequentially
  //   emptyFields.forEach((fieldId) => {
  //     const fieldElement = document.getElementById(fieldId);
  //     if (fieldElement) {
  //       fieldElement.focus();
  //     }
  //   });
  //   if (emptyFields.length > 0) {
  //     console.log("All fields are required", emptyFields.length);
  //     handleUpdateToast(false, true, "All fields are required");

  //     return;
  //   }

  //   if (!userData.order_value || !userData.order_date) {
  //     return;
  //   } else {
  //     const updateddata = {
  //       reminder_status: "order_placed",
  //       order_value: userData.order_value,
  //       order_date_time: userData.order_date,
  //     };
  //     handleOrder(user.reference_number, updateddata, user.sales_mail);
  //   }
  // };
  return (
    <>
      <Modal
        centered
        open={open}
        // onOk={() => setOpen(true)}
        onCancel={handleClose}
        width={rem(808)}
        // height={}
        className="edit-popup"
      >
        <div className="user-popup ">
          {/* Input fields for user details */}
          <div className="flexBox popup sales-popup">
            <div style={{ width: rem(300) }}>
              <InputField
                label={"ack_number"}
                labelName={"ACK Number"}
                type={"text"}
                fieldData={userData.ack_number}
                readOnly={true}
              />
            </div>{" "}
            <div style={{ width: rem(300) }}>
              <InputField
                label={"sales_person_name"}
                labelName={"Sales Team Member"}
                type={"text"}
                placeholder={""}
                fieldData={userData?.sales_person_name?.toLowerCase()}
                readOnly={true}
                styles={{ textTransform: "capitalize" }}
              />
            </div>{" "}
            <div style={{ width: rem(300) }}>
              <InputField
                label={"sales_mail"}
                labelName={"Sales Person Mail"}
                type={"text"}
                placeholder={""}
                fieldData={userData?.sales_mail}
                readOnly={true}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <InputField
                label={"client_person_name"}
                labelName={"Buyer Seller POC"}
                type={"text"}
                placeholder={""}
                fieldData={userData?.client_person_name}
                readOnly={true}
                styles={{ textTransform: "capitalize" }}
              />
            </div>{" "}
            <div style={{ width: rem(300) }}>
              <InputField
                label={"client_email"}
                labelName={"Buyer Email"}
                type={"text"}
                placeholder={""}
                fieldData={userData?.client_email}
                readOnly={true}
              />
            </div>{" "}
            <div style={{ width: rem(300) }}>
              <InputField
                label={"client_email_time"}
                labelName={"Buyer / Seller Time"}
                type={"text"}
                placeholder={""}
                fieldData={userData?.client_email_time || ""}
                readOnly={true}
              />
            </div>
            {/* </div> */}
            <div style={{ width: rem(300) }}>
              <InputField
                label={"client_cc"}
                labelName={"Client CC"}
                type={"text"}
                placeholder={""}
                fieldData={userData?.client_cc}
                readOnly={true}
              />
            </div>
            <div
              className="popup-line positionAbsolute"
              style={{ top: "10", left: "45%", height: "85%" }}
            ></div>
            {/* <div className="flexBox popup"> */}
            <div style={{ width: rem(300) }}>
              <InputField
                label={"sales_email_time"}
                labelName={"Sales to AI"}
                type={"text"}
                placeholder={""}
                fieldData={userData?.sales_email_time || ""}
                readOnly={true}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <InputField
                label={"client_subject"}
                labelName={"Enquiry Subject"}
                type={"text"}
                placeholder={""}
                fieldData={userData?.client_subject}
                readOnly={true}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <InputField
                label={"ack_time"}
                labelName={"AI Ack"}
                type={"text"}
                placeholder={""}
                fieldData={userData?.ack_time || ""}
                readOnly={true}
              />
            </div>
            {!AwaitingQuotes && userData?.reminder_status !== "pending" && (
              <>
                <div style={{ width: rem(300) }}>
                  <InputField
                    label={"currency_value"}
                    labelName={"Quoted Value"}
                    type={"text"}
                    placeholder={""}
                    fieldData={userData?.currency_value || ""}
                    readOnly={true}
                  />
                </div>
                <div style={{ width: rem(300) }}>
                  <InputField
                    label={"quotation_time"}
                    labelName={"Quotation Receive Time"}
                    type={"text"}
                    placeholder={""}
                    fieldData={userData?.quotation_time || ""}
                    readOnly={true}
                  />
                </div>

                <div style={{ width: rem(300) }}>
                  <InputField
                    label={"currency"}
                    labelName={"Currency"}
                    type={"text"}
                    placeholder={""}
                    fieldData={userData?.currency || ""}
                    readOnly={true}
                  />
                </div>
              </>
            )}
            {!AwaitingQuotes &&
              userData?.reminder_status === "order_placed" && (
                <>
                  <div style={{ width: rem(300) }}>
                    <InputField
                      label={"order_date"}
                      labelName={"Ordered Date"}
                      type={
                        userData?.reminder_status === "order_placed"
                          ? "text"
                          : "date"
                      }
                      placeholder={""}
                      fieldData={userData.order_date}
                      setFormData={
                        userData?.reminder_status === "success"
                          ? setUserData
                          : undefined
                      }
                      // maxLength={50}
                      // hasError={furtherError.order_date_error}
                      readOnly={
                        userData?.reminder_status === "order_placed"
                          ? true
                          : false || reports
                      }
                      minLength={formatDate(user?.quotation_time)}
                      maxLength={maxDate}
                    />
                  </div>
                  <div style={{ width: rem(300) }}>
                    <InputField
                      label={"order_value"}
                      labelName={"Order Value"}
                      // type={"text"}
                      placeholder={"Order Value"}
                      fieldData={userData.order_value}
                      setFormData={
                        userData?.reminder_status === "success"
                          ? setUserData
                          : undefined
                      }
                      // maxLength={50}
                      // hasError={furtherError.order_value_error}
                      readOnly={
                        userData?.reminder_status === "order_placed"
                          ? true
                          : false || reports
                      }
                      pattern={"numberWithFloat"}
                      maxLength={10}
                    />
                  </div>
                </>
              )}
            {/* {userData.reminder_status === "success" && (
              <div>
                <span className="df api-btn">
                  <Button
                    onclick={handleOrderPlaced}
                    btnname={updatingFun ? "Updating..." : "Update"}
                    disabled={updatingFun}
                    fs={20}
                    height={41}
                    br={4}
                  />
                </span>
              </div>
            )} */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditPopup;
