import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./QualityHome.scss";
import { rem } from "../../../Components/Rem_func";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const QualityHome = () => {
  const data = {
    labels: ["00", "04", "08", "12", "14", "16", "18"],
    datasets: [
      {
        label: "MTC's Generated",
        data: [3, 2, 6, 4, 5, 7, 2],
        // backgroundColor: "rgba(30, 130, 76, 0.6)",
        backgroundColor: "#1e6641",
        borderWidth: 1,
        borderRadius: 10,
        barPercentage: 0.4, // Adjust bar width (smaller value increases gap)
        categoryPercentage: 0.8,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        // text: "Quality",
        align: "start",
        font: {
          size: 20,
          weight: "bold",
        },
        color: "#333",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#C0C0C0",
        },
        border: {
          display: false, // Hide the x-axis line
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
    },
  };

  return (
    <div className="quality_home">
      <div className="upper">
        {/* <div style={{ display: "flex", gap: rem(21) }}> */}
        <div className="">
          <p className="userM">Quality</p>
          <div className="df">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(21)}
              height={rem(19)}
              viewBox="0 0 21 19"
              fill="none"
            >
              <g clipPath="url(#clip0_931_4712)">
                <path
                  d="M6.56445 11.351L10.757 7.61182L14.9495 11.351H6.56445Z"
                  fill="#8FDBB5"
                />
              </g>
              <defs>
                <clipPath id="clip0_931_4712">
                  <rect
                    width="20.1242"
                    height="17.948"
                    fill="white"
                    transform="translate(0.695312 0.881348)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div className="qul">+2.45%</div>
          </div>
        </div>
        <p className="gen">MTC's Generated</p>
      </div>

      <div className="barChat" style={{ height: rem(230), overflowX: "auto" }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default QualityHome;
