import React, { useEffect, useState } from "react";
import "./MobileView.scss";
import spiraLogo2 from "../../../../Assets/QuizMobile/spiraLogo2.svg";
import _nonAuthHttp from "../../../../Utils/Api/_nonAuthHttp";
import { useNavigate } from "react-router-dom";

const SpiraCatalogue = () => {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(null); // State to track the selected item
  const [activeimg, setActiveimg] = useState(false);
  const [apidata, setApidata] = useState({});

  const handleSizer = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex(null);
      setActiveimg(false);
    } else {
      setActiveimg(true);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const valuesList = Array.from(queryParams.values()); // Convert iterator to array
    console.log("Extracted values:", valuesList);

    // Call GetMedias with the extracted params after setting the state
    if (valuesList.length > 0) {
      GetMedias(valuesList);
    } else {
      navigate("/access-denied");
    }
  }, []);

  const GetMedias = async (data) => {
    try {
      const resp = await _nonAuthHttp.post("quiz/NameToLink", {
        file_names: data, // Sends as an array of strings
      });
      console.log("API response data:", resp.data);
      setApidata(resp.data.urls);
    } catch (err) {
      console.log(err?.response?.data?.message || "An error occurred");
    }
  };

  const isMP4 = (fileName) => fileName.endsWith(".mp4");
  // Check if there are any MP4 files
  const hasMP4 = Object.keys(apidata).some((fileName) => isMP4(fileName));
  
  // Determine if there is only one image
  const isSingleImage = Object.keys(apidata).length === 1;

  return (
    <div className="mobileView">
      <div className="MobileContents">
        <div className="MobileImg">
          <img src={spiraLogo2} alt="" />
        </div>
        <div className="containerContexts">
          <div className="containerbox"></div>
          <div
            className={
              (activeimg || hasMP4 || isSingleImage) ? "Mediacontents single-column" : "Mediacontents"
            }
          >
            {Object.entries(apidata).map(([fileName, url], index) =>
              isMP4(fileName) ? (
                <video key={index} controls>
                  <source src={url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  key={index}
                  src={url}
                  alt={fileName}
                  onClick={() => handleSizer(index)}
                />
              )
            )}
          </div>
        </div>
        <div className="MediaBtns">
          <button onClick={() => setActiveimg(false)}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default SpiraCatalogue;