import React, { useEffect, useMemo } from "react";
import { rem } from "../../../Components/Rem_func";
import DraggableTable from "../../../Components/TableDrag_func";

const UserDataTable = ({
  handleOpen,
  users,
  filteredData,
  loading,
  renderEmptyOrValue,
  defaultPageSize,
  totalCount,
  searchData,
  filters,
}) => {
  // Memoized filteredUsers to optimize performance
  const filteredUsers = useMemo(() => {
    return (users || [])
      .filter((user) => {
        const convertToLocalDate = (utcDateString) => {
          if (!utcDateString) return null;
          const utcDate = new Date(utcDateString);
          return new Date(
            utcDate.getFullYear(),
            utcDate.getMonth(),
            utcDate.getDate(),
            utcDate.getHours(),
            utcDate.getMinutes(),
            utcDate.getSeconds()
          );
        };

        // Filter based on filteredData
        const matchesFilteredData =
          (user?.new_ref_num || "")
            .toLowerCase()
            .includes((filteredData?.ack_number || "").toLowerCase()) &&
          (user?.client_person_name || "")
            .toLowerCase()
            .includes((filteredData?.client_person_name || "").toLowerCase()) &&
          (user?.sales_person_name || "")
            .toLowerCase()
            .includes((filteredData?.sales_person_name || "").toLowerCase()) &&
          (user?.client_subject || "")
            .toLowerCase()
            .includes((filteredData?.client_subject || "").toLowerCase()) &&
          (filteredData?.client_email_from
            ? convertToLocalDate(user?.client_email_time_utc) >=
              new Date(filteredData?.client_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.client_email_to
            ? convertToLocalDate(user?.client_email_time_utc) <=
              new Date(filteredData?.client_email_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData?.sales_email_from
            ? convertToLocalDate(user?.sales_email_time_utc) >=
              new Date(filteredData?.sales_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.sales_email_to
            ? convertToLocalDate(user?.sales_email_time_utc) <=
              new Date(filteredData?.sales_email_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData?.ack_email_from
            ? convertToLocalDate(user?.ack_time_utc) >=
              new Date(filteredData?.ack_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.ack_email_to
            ? convertToLocalDate(user?.ack_time_utc) <=
              new Date(filteredData?.ack_email_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData?.quotation_email_from
            ? convertToLocalDate(user?.quotation_time_utc) >=
              new Date(filteredData?.quotation_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.quotation_email_to
            ? convertToLocalDate(user?.quotation_time_utc) <=
              new Date(filteredData?.quotation_email_to).setHours(
                23,
                59,
                59,
                999
              )
            : true) &&
          (filteredData?.order_date_from
            ? convertToLocalDate(user?.final_order_time) >=
              new Date(filteredData?.order_date_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.order_date_to
            ? convertToLocalDate(user?.final_order_time) <=
              new Date(filteredData?.order_date_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData?.reject_date_from
            ? convertToLocalDate(user?.rejection_date_time) >=
              new Date(filteredData?.reject_date_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.reject_date_to
            ? convertToLocalDate(user?.rejection_date_time) <=
              new Date(filteredData?.reject_date_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData?.max_quote_value
            ? user?.currency_value
              ? Number(filteredData?.max_quote_value) >=
                Number(user?.currency_value?.replace(/,/g, ""))
              : false
            : true) &&
          (filteredData?.min_quote_value
            ? user?.currency_value
              ? Number(filteredData?.min_quote_value) <=
                Number(user?.currency_value?.replace(/,/g, ""))
              : false
            : true) &&
          (filteredData?.max_order_value
            ? user?.final_order_currency
              ? Number(filteredData?.max_order_value) >=
                Number(user?.final_order_currency?.replace(/,/g, "") || 0)
              : false
            : true) &&
          (filteredData?.min_order_value
            ? user?.final_ordfinal_order_currencyer_value
              ? Number(filteredData?.min_order_value) <=
                Number(user?.final_order_currency?.replace(/,/g, ""))
              : false
            : true);
        // (filteredData.)

        // Search logic: check if any field contains the searchData string
        const matchesSearchData = searchData
          ? (user?.new_ref_num || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_subject || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_mail || "")
              .toLowerCase()
              .includes(searchData.toLowerCase())
          : true;
        const SalesPersonName = filters?.sales_team
          ? (user?.sales_person_name || "")
              .toLowerCase()
              .includes((filters?.sales_team || "").toLowerCase())
          : true;
        return matchesFilteredData && matchesSearchData && SalesPersonName;
      })
      .map((user) => {
        // Highlight search matches in relevant fields
        const matchReferenceNumber =
          searchData &&
          user?.new_ref_num?.toLowerCase().includes(searchData?.toLowerCase());

        const matchClientPersonName =
          searchData &&
          user?.client_person_name
            ?.toLowerCase()
            .includes(searchData?.toLowerCase());

        const matchSalesPersonName =
          searchData &&
          user?.sales_person_name
            ?.toLowerCase()
            .includes(searchData?.toLowerCase());

        const matchClientSubject =
          searchData &&
          user?.client_subject
            ?.toLowerCase()
            .includes(searchData?.toLowerCase());

        return {
          ...user, // Return all original user data
          new_ref_num_class: matchReferenceNumber ? "highlighted-text" : "",
          client_person_name_class: matchClientPersonName
            ? "highlighted-text"
            : "",
          sales_person_name_class: matchSalesPersonName
            ? "highlighted-text"
            : "",
          client_subject_class: matchClientSubject ? "highlighted-text" : "",
        };
      });
  }, [users, filteredData, searchData, filters]);

  // Columns configuration for Ant Design Table
  const columns = [
    {
      title: "ACK Number",
      dataIndex: "new_ref_num",
      key: "new_ref_num",
      width: rem(124),

      className: "",
      sorter: (a, b) =>
        (a.new_ref_num || "").localeCompare(b.new_ref_num || ""),
      render: (text, record) =>
        renderEmptyOrValue(text, "", record.new_ref_num_class),
    },
    {
      title: "Sales Team Member",
      dataIndex: "sales_person_name",
      key: "sales_person_name",
      className: "",
      sorter: (a, b) =>
        (a.sales_person_name || "").localeCompare(b.sales_person_name || ""),
      width: rem(136),
      render: (text, record) =>
        renderEmptyOrValue(
          text?.toLowerCase(),
          true,
          record.sales_person_name_class
        ),
    },
    {
      title: "Status",
      dataIndex: "reminder_status",
      key: "reminder_status",
      // sorter: (a, b) =>
      //   (a?.reminder_status || "").localeCompare(b?.reminder_status || ""),
      width: rem(146),
      render: (text, record) => {
        if (text === "order_placed") {
          return <span className="op status">Order Placed</span>;
        } else if (text === "success") {
          return <span className="awo status">Awaiting Order</span>;
        } else if (text === "pending") {
          return <span className="aqo status">Awaiting Quote</span>;
        } else if (text === "rejected") {
          return <span className="orej status">Order Rejected</span>;
        } else if (!text) {
          return "--";
        } else {
          return "MismatchF";
        }
      },
      className: "",
    },
    {
      title: "Awaiting Quote",
      dataIndex: "client_email_time_utc",
      key: "client_email_time_utc",
      sorter: (a, b) => {
        const quoteDateA = new Date(a.client_email_time_utc || "");
        const quoteAgeingDaysA = !isNaN(quoteDateA)
          ? Math.floor((new Date() - quoteDateA) / (1000 * 60 * 60 * 24))
          : null;

        const quoteDateB = new Date(b.client_email_time_utc || "");
        const quoteAgeingDaysB = !isNaN(quoteDateB)
          ? Math.floor((new Date() - quoteDateB) / (1000 * 60 * 60 * 24))
          : null;

        return quoteAgeingDaysA - quoteAgeingDaysB;
      },
      width: rem(104),
      render: (text, record) => {
        const quoteDate = new Date(text); // 'text' is the UTC date
        const currentUtcDate = new Date().toISOString(); // Get the current time in UTC format

        const quoteAgeingDays = !isNaN(quoteDate.getTime())
          ? Math.floor(
              (new Date(currentUtcDate).getTime() - quoteDate.getTime()) /
                (1000 * 60 * 60 * 24)
            )
          : null;

        return (
          <span>
            {text ? (
              quoteAgeingDays ? (
                `${quoteAgeingDays || 0} days`
              ) : quoteAgeingDays === 0 ? (
                "0 days"
              ) : (
                <span className="empty">MismatchF</span>
              )
            ) : (
              <span className="empty">--</span>
            )}
          </span>
        );
      },
    },
    {
      title: "Quote Value",
      dataIndex: "currency_value",
      key: "currency_value",
      sorter: (a, b) => {
        // Ensure that both 'a.order_value' and 'b.order_value' are clean numeric strings
        const cleanValueA = a.currency_value?.replace(/[^0-9.-]+/g, ""); // Remove all non-numeric characters except period (.) and minus (-)
        const cleanValueB = b.currency_value?.replace(/[^0-9.-]+/g, "");

        // Parse the cleaned values as floats
        const valueA = parseFloat(cleanValueA) || 0;
        const valueB = parseFloat(cleanValueB) || 0;

        // Compare the two numerical values
        return valueA - valueB;
      },

      width: rem(104),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Awaiting Order",
      dataIndex: "quotation_time_utc",
      key: "quotation_time_utc",
      sorter: (a, b) => {
        const quoteDateA = new Date(a.quotation_time_utc || "");
        const quoteAgeingDaysA = !isNaN(quoteDateA)
          ? Math.floor((new Date() - quoteDateA) / (1000 * 60 * 60 * 24))
          : null;

        const quoteDateB = new Date(b.quotation_time_utc || "");
        const quoteAgeingDaysB = !isNaN(quoteDateB)
          ? Math.floor((new Date() - quoteDateB) / (1000 * 60 * 60 * 24))
          : null;

        return quoteAgeingDaysA - quoteAgeingDaysB;
      },
      width: rem(104),
      render: (text, record) => {
        const quoteDate = new Date(text); // 'text' is the UTC date
        const currentUtcDate = new Date().toISOString(); // Get the current time in UTC format

        const quoteAgeingDays = !isNaN(quoteDate.getTime())
          ? Math.floor(
              (new Date(currentUtcDate).getTime() - quoteDate.getTime()) /
                (1000 * 60 * 60 * 24)
            )
          : null;

        return (
          <span>
            {text
              ? quoteAgeingDays !== null
                ? `${quoteAgeingDays} days`
                : "--"
              : "--"}
          </span>
        );
      },
    },
    {
      title: "Order Value",
      dataIndex: "final_order_currency",
      key: "final_order_currency",
      sorter: (a, b) => {
        // Ensure that both 'a.order_value' and 'b.order_value' are clean numeric strings
        const cleanValueA = a.final_order_currency?.replace(/[^0-9.-]+/g, ""); // Remove all non-numeric characters except period (.) and minus (-)
        const cleanValueB = b.final_order_currency?.replace(/[^0-9.-]+/g, "");

        // Parse the cleaned values as floats
        const valueA = parseFloat(cleanValueA) || 0;
        const valueB = parseFloat(cleanValueB) || 0;

        // Compare the two numerical values
        return valueA - valueB;
      },

      width: rem(104),
      render: (text) => renderEmptyOrValue(text?.replace(/,/g, "")),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      sorter: (a, b) => (a.currency || "").localeCompare(b.currency || ""),
      width: rem(104),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Order Closure Days",
      dataIndex: "order_closure_days",
      key: "order_closure_days",
      sorter: (a, b) => {
        const orderDateA = a.final_order_time
          ? new Date(a.final_order_time)
          : null;
        const clientEmailA = a.client_email_time_utc
          ? new Date(a.client_email_time_utc)
          : null;
        const orderClosureDaysA =
          orderDateA && clientEmailA
            ? Math.floor((orderDateA - clientEmailA) / (1000 * 60 * 60 * 24))
            : null;

        const orderDateB = b.final_order_time
          ? new Date(b.final_order_time)
          : null;
        const clientEmailB = b.client_email_time_utc
          ? new Date(b.client_email_time_utc)
          : null;
        const orderClosureDaysB =
          orderDateB && clientEmailB
            ? Math.floor((orderDateB - clientEmailB) / (1000 * 60 * 60 * 24))
            : null;

        if (orderClosureDaysA === null && orderClosureDaysB === null) return 0;
        if (orderClosureDaysA === null) return -1;
        if (orderClosureDaysB === null) return 1;

        return orderClosureDaysA - orderClosureDaysB;
      },
      width: rem(134),
      render: (text, record) => {
        const orderDate = record.final_order_time
          ? new Date(record.final_order_time)
          : null;
        const clientEmail = record.client_email_time_utc
          ? new Date(record.client_email_time_utc)
          : null;

        const orderClosureDays =
          orderDate && clientEmail
            ? Math.floor((orderDate - clientEmail) / (1000 * 60 * 60 * 24))
            : null;
        return (
          <span>
            {orderClosureDays !== null ? (
              `${orderClosureDays} days`
            ) : (
              <span className="empty">--</span>
            )}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    const total_Pending = filteredUsers.filter(
      (item) => item.reminder_status === "pending"
    ).length;
    const total_Count = filteredUsers.length;
    const order_placed = filteredUsers?.filter(
      (item) => item?.reminder_status === "order_placed"
    ).length;

    const total_success = filteredUsers?.filter(
      (item) => item?.reminder_status === "success"
    ).length;

    const total_rejected = filteredUsers?.filter(
      (item) => item?.reminder_status === "rejected"
    ).length;

    totalCount({
      total: total_Count,
      page_count: total_Count,

      pending: total_Pending,
      success: total_success,
      order_placed: order_placed,
      rejected: total_rejected,
    });
  }, [filteredUsers, totalCount]);
  return (
    <DraggableTable
      handleOpen={handleOpen}
      filteredUsers={filteredUsers}
      loading={loading}
      baseColumns={columns}
      drag={true}
      defaultPageSize={defaultPageSize}
    />
  );
};

export default UserDataTable;
