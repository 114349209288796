import React, { createContext, useContext, useState } from "react";
import {
  closestCenter,
  DndContext,
  // DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { Table } from "antd";
import { rem } from "./Rem_func";

const DragIndexContext = createContext({
  active: -1,
  over: -1,
});
const dragActiveStyle = (dragState, id) => {
  const { active, over, direction } = dragState;
  // drag active style
  let style = {};
  if (active && active === id) {
    style = {
      backgroundColor: "gray",
      opacity: 0.5,
    };
  }
  // dragover dashed style
  else if (over && id === over && active !== over) {
    style =
      direction === "right"
        ? {
            borderRight: "1px dashed gray",
          }
        : {
            borderLeft: "1px dashed gray",
          };
  }
  return style;
};
const TableBodyCell = (props) => {
  const dragState = useContext(DragIndexContext);
  return (
    <td
      {...props}
      style={{
        ...props.style,
        ...dragActiveStyle(dragState, props.id),
      }}
    />
  );
};
const TableHeaderCell = (props) => {
  const dragState = useContext(DragIndexContext);
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: props.id,
  });
  const style = {
    ...props.style,
    cursor: "move",
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
          userSelect: "none",
        }
      : {}),
    ...dragActiveStyle(dragState, props.id),
  };
  return (
    <th
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {/* <DragIcon className="drag-icon" /> */}
      {props.children}
    </th>
  );
};

const DraggableTable = ({
  loading,
  handleOpen,
  defaultPageSize,
  baseColumns,
  filteredUsers,
  drag,
}) => {
  const [dragIndex, setDragIndex] = useState({
    active: -1,
    over: -1,
  });
  const pageSize = defaultPageSize;
  const [columns, setColumns] = useState(() =>
    baseColumns?.map((column, i) => ({
      ...column,
      key: `${i}`,
      onHeaderCell: () => ({
        id: `${i}`,
      }),
      onCell: () => ({
        id: `${i}`,
      }),
    }))
  );
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    })
  );
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setColumns((prevState) => {
        const activeIndex = prevState.findIndex((i) => i.key === active?.id);
        const overIndex = prevState.findIndex((i) => i.key === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
    setDragIndex({
      active: -1,
      over: -1,
    });
  };
  const onDragOver = ({ active, over }) => {
    const activeIndex = columns.findIndex((i) => i.key === active.id);
    const overIndex = columns.findIndex((i) => i.key === over?.id);
    setDragIndex({
      active: active.id,
      over: over?.id,
      direction: overIndex > activeIndex ? "right" : "left",
    });
  };
  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      collisionDetection={closestCenter}
    >
      <SortableContext
        items={columns.map((i) => i.key)}
        strategy={horizontalListSortingStrategy}
      >
        <div className="table-wrap">
          <DragIndexContext.Provider value={dragIndex}>
            <Table
              className="user-table"
              rowKey="key"
              columns={columns}
              dataSource={
                filteredUsers &&
                filteredUsers.map((item, index) => ({
                  ...item,
                  key: index,
                }))
              }
              style={handleOpen ? { cursor: "pointer" } : ""}
              scroll={{
                y: `calc(100vh - ${rem(330)})`,
              }}
              pagination={{
                // showQuickJumper: true,
                showSizeChanger: false,
                defaultPageSize: pageSize,
                pageSize, // Set the initial page size to 50
                itemRender: (current, type, originalElement) => {
                  if (type === "prev" || type === "next") {
                    return (
                      <span className="pagination-button">
                        {originalElement}
                      </span>
                    );
                  }
                  return originalElement;
                },
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                className: "paginate",
              }}
              onRow={(record) =>
                handleOpen
                  ? {
                      onClick: () => handleOpen(record),
                    }
                  : ""
              }
              loading={{
                spinning: loading,
                indicator: <div className="spinners"></div>,
              }}
              components={
                drag
                  ? {
                      header: {
                        cell: TableHeaderCell,
                      },
                      body: {
                        cell: TableBodyCell,
                      },
                    }
                  : ""
              }
            />
          </DragIndexContext.Provider>
        </div>
      </SortableContext>
    </DndContext>
  );
};
export default DraggableTable;
