import React from "react";
import { rem } from "./../Rem_func";

const Quality_upload = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={rem(42)}
        height={rem(42)}
        viewBox="0 0 42 42"
        fill="none"
      >
        <path
          d="M24.8281 4.81836V11.1114C24.8281 11.9636 25.1676 12.7826 25.7731 13.3864C26.3802 13.9908 27.202 14.3299 28.0586 14.3296H35.2774"
          stroke="#838383"
          strokeWidth="2.625"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.4375 14.9945V29.9885C35.4027 30.9692 35.1741 31.9333 34.7651 32.8254C34.356 33.7174 33.7745 34.5197 33.054 35.186C31.5973 36.5373 29.6636 37.2548 27.678 37.181H14.3955C13.4062 37.2268 12.4177 37.0765 11.4867 36.739C10.5556 36.4014 9.70054 35.8831 8.9705 35.214C8.24335 34.5456 7.65609 33.7396 7.24274 32.8426C6.82939 31.9456 6.59818 30.9755 6.5625 29.9885V12.009C6.59725 11.0282 6.82574 10.064 7.23481 9.17201C7.64389 8.27998 8.22546 7.47772 8.946 6.81147C10.4027 5.46017 12.3364 4.7426 14.322 4.81647H24.3215C25.8479 4.81158 27.3212 5.37632 28.4533 6.40022L33.6332 11.1637C34.1859 11.6399 34.6322 12.2271 34.943 12.8871C35.2539 13.5471 35.4223 14.2651 35.4375 14.9945Z"
          stroke="#838383"
          strokeWidth="2.625"
          strokeLinejoin="round"
        />
        <path
          d="M21 18.374V30.2285"
          stroke="#838383"
          strokeWidth="2.625"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M26.4394 23.3628L21.7756 18.6991C21.6747 18.5969 21.5545 18.5159 21.422 18.4605C21.2896 18.4052 21.1474 18.3767 21.0039 18.3767C20.8603 18.3767 20.7182 18.4052 20.5857 18.4605C20.4532 18.5159 20.333 18.5969 20.2321 18.6991L15.5684 23.3646"
          stroke="#838383"
          strokeWidth="2.625"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Quality_upload;
