import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./Reducers";
import { persistStore, PERSIST } from "redux-persist";

// Configure the Redux store with the provided rootReducer
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Ignore redux-persist actions for serializable check
      serializableCheck: {
        ignoredActions: PERSIST,
      },
    }),
});

// Create a persistor for persisting state across sessions
const persistor = persistStore(store);

// Export the configured Redux store and the persistor
export { store, persistor };
