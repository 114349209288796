import React, { useState, useEffect } from "react";
import "./FeedbackStats.scss";
import _http from "../../../Utils/Api/_http";
import SnackBar from "../../../Components/SnackBar";
import feedbackSent from "../../../Assets/Home/Feedback-sent.svg";
import feedbackPending from "../../../Assets/Home/Feedback-pending.svg";
import feedbackReceive from "../../../Assets/Home/Feedback-receive.svg";
import CurrencyBieChart from "../../SalesTracker/DashBoard/CurrencyBieChart";

const FeedbackStats = () => {
  const [FeedbackStats, setFeedbackstats] = useState();
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const fetchData = async () => {
    try {
      const response = await _http.get("/api/feedback_stats");
      setFeedbackstats(response.data);
    } catch (error) {
      setToast({ error: true });
      setError("Error Fetching Feedback Stats");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const statsData = [
    {
      label: "Sent",
      value: FeedbackStats?.total_clients || 0,
      image: feedbackSent,
      colour: "#236AF2",
      data1: FeedbackStats?.total_clients || 0,
      data2: FeedbackStats?.total_enquiries - FeedbackStats?.total_clients || 0,
      label1: "Sent",
      label2: "Others",
    },
    {
      label: "Received",
      value: FeedbackStats?.total_feedback_received || 0,
      image: feedbackReceive,
      colour: "#1E6641",
      data1: FeedbackStats?.total_feedback_received || 0,
      data2:
        FeedbackStats?.total_enquiries -
          FeedbackStats?.total_feedback_received || 0,
      label1: "Received",
      label2: "Others",
    },
    {
      label: "Pending",
      value: FeedbackStats?.total_feedback_pending || 0,
      image: feedbackPending,
      colour: "#FF3E3E",
      data1: FeedbackStats?.total_feedback_pending || 0,
      data2:
        FeedbackStats?.total_enquiries -
          Math.abs(FeedbackStats?.total_feedback_pending) || 0,
      label1: "Pending",
      label2: "Others",
    },
  ];

  return (
    <div className="feedback-stat-container">
      <p className="userM">Feedback Stats</p>
      <div className="feedback-stat">
        {statsData.map((stat, index) => (
          <div className="stats df" key={index}>
            <div className="df space-between w100 stat_main">
              <div className="stat_sent_div">
                <p className="stat_sent">{stat?.label}</p>
                <img src={stat?.image} alt={`${stat?.label} icon`} />
              </div>
              <p className="stat_data"> {Math?.abs(stat?.value)}</p>
            </div>
            <CurrencyBieChart
              colour1={stat?.colour}
              colour2={"#DCDCE0"}
              data1={Math.abs(stat?.data1)}
              data2={Math.abs(stat?.data2)}
              label1={stat?.label1}
              label2={stat?.label2}
            />
          </div>
        ))}
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </div>
  );
};

export default FeedbackStats;
