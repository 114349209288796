import React from "react";
import "./toggle.scss";
const ToggleSwitch = ({ onchange, checked }) => {
  return (
    <label className="switch">
      <input type="checkbox" onChange={onchange}/>
      <span className="slider round">
        <span className="toggle">
          <p>Edit</p>
          <p>Add</p>
        </span>
      </span>
    </label>
  );
};

export default ToggleSwitch;
