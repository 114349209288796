import React, { useCallback, useEffect, useRef, useState } from "react";
import logoImg from "../../Assets/QuizMobile/spiraLogo1.svg";
import _nonAuthHttp from "../../Utils/Api/_nonAuthHttp";
import SnackBar from "../../Components/MobSnackBar";
import MobileLoader from "./MobileLoader";

export default function QuizQuestions({ setQuizState }) {
  const [questionList, setQuestionList] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [isRank, setIsRank] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const [timeDuration, setTimeDuration] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [showBtn, setShowBtn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const timerRef = useRef(null);

  const participant_id = sessionStorage.getItem("participantsId");
  const pool_title = sessionStorage.getItem("pool_title");
  const pool_id = sessionStorage.getItem("pool_id");
  const participant_email = sessionStorage.getItem("participant_email");

  const fetchQuestionsData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await _nonAuthHttp.post("/quiz/Get_Question", {
        pool_id: Number(pool_id),
        participant_id: Number(participant_id),
      });
      setQuestionList(res.data.questions);
      const duration = res.data.pool.duration_in_minutes;
      setTimeDuration(duration * 60); // Set the timeDuration from API
      setTimeLeft(duration * 60);
      setShowBtn(true);
    } catch (error) {
      console.log(error);
      setError("Internal Server Error");
      setToast({ error: true });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchQuestionsData();
  }, [fetchQuestionsData]);

  const reminderFunction = async () => {
    try {
      // Create a new FormData object
      const formData = new FormData();
      formData.append("email_id", participant_email);
      formData.append(
        "url",
        `https://spira-qa.dtskill.com/quiz-admin/?pool=${pool_id}`
      );

      // Send the FormData payload
      await _nonAuthHttp.post("/quiz/reminder-email", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type as multipart/form-data
        },
      });
    } catch (error) {
      console.log(error);
      setError("Internal Server Error");
      setToast({ error: true });
    }
  };

  useEffect(() => {
    if (timeLeft > 0) {
      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerRef.current); // Stop the timer at 0
            showAnsHandler(false);
            return 0;
          }
          return prevTime - 1; // Decrement the timer by 1 second
        });
      }, 1000); // 1 second interval
      return () => clearInterval(timerRef.current); // Cleanup timer on component unmount
    }
  }, [timeLeft]);

  const thirdDuration = Math.floor(timeDuration / 3);

  const getBackgroundColor = () => {
    if (timeLeft <= thirdDuration) {
      return "#ff4d4d"; // Red for last third of the time
    } else if (timeLeft <= 2 * thirdDuration) {
      return "#ffd700"; // Yellow for the second third of the time
    }
    return "#1e6641"; // Default background color (first third of the time)
  };

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const timeLeft_second = timeLeft * 60;
  const timeDuration_second = timeDuration * 60;

  const widthPercentage = (timeLeft_second / timeDuration_second) * 100;

  const fetchCorrectAnswer = async () => {
    try {
      await _nonAuthHttp.post("/quiz/participant-answers", {
        user_id: Number(participant_id),
        pool_id: Number(pool_id),
      });
    } catch (error) {
      console.log(error);
      setError("Internal Server Error");
      setToast({ error: true });
    }
  };

  const totalTimeTaken = timeDuration - timeLeft;

  const showAnsHandler = async (submitted) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setLoading(true);
    clearInterval(timerRef.current);
    try {
      await _nonAuthHttp.post("/quiz/submit-answers", {
        participant_id: Number(participant_id),
        pool_id: Number(pool_id),
        submitted: submitted,
        total_time_taken: Math?.ceil(totalTimeTaken / 60),
        answers: Object.keys(selectedAnswers).map((questionId) => ({
          participant: Number(participant_id),
          question: parseInt(questionId),
          chosen_choice: selectedAnswers[questionId],
          pool: Number(pool_id),
        })),
      });
      setError("Quiz Successfully submitted");
      setToast({ submit: true });
      fetchCorrectAnswer();
      if (!submitted) {
        reminderFunction();
      }
      setIsRank(true);
    } catch (error) {
      console.log(error);
      setError(
        (error?.response?.data?.error ===
          "Participant has already submitted answers." &&
          error?.response?.data?.error) ||
          "Internal Server Error"
      );
      setToast({ error: true });
    } finally {
      setLoading(false);
    }
  };

  const handleRank = () => {
    // navigate("/ranking");
    setQuizState("ranking");

    sessionStorage.setItem("register", false);
    sessionStorage.setItem("quiz", false);
    sessionStorage.setItem("question", false);
    sessionStorage.setItem("ranking", true);
  };

  const handleOptionChange = (questionId, choiceId) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionId]: choiceId, // Store the selected choice for the question
    });
  };

  const getCorrectAnswer = (quesId) => {
    const question = questionList.find((q) => q.question_id === quesId);
    if (!question) return null;

    const correctChoice = question.choices.find(
      (choice) => choice.is_correct === true
    );

    return correctChoice?.choice_text;
  };

  const isAnswerCorrect = (quesId, choiceId) => {
    const correctAnswerText = getCorrectAnswer(quesId);
    const selectedChoice = questionList
      .find((q) => q.question_id === quesId)
      ?.choices.find((choice) => choice.choice_id === choiceId)?.choice_text;
    return correctAnswerText === selectedChoice;
  };

  const getCorrectChoiceId = (quesId) => {
    const correctAnswerText = getCorrectAnswer(quesId);
    const correctChoice = questionList
      .find((q) => q.question_id === quesId)
      ?.choices.find((choice) => choice.choice_text === correctAnswerText);
    return correctChoice?.choice_id;
  };

  return (
    <>
      {loading && (
        <div
          style={{
            background: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <MobileLoader />
        </div>
      )}
      {!loading && (
        <div className="quiz-questions">
          <div className="logo-img">
            <img src={logoImg} alt="error" />
          </div>
          {!isRank && (
            <div className="time_left">
              <p
                className="timeLeft"
                style={{
                  display: isRank ? "none" : "",
                  color: getBackgroundColor(),
                }}
              >
                Time Left
              </p>
              <div className="timesred">
                <div
                  className="timeBar"
                  style={{
                    backgroundColor: getBackgroundColor(),
                    width: `${widthPercentage}%`,
                    transition: "background-color 0.5s ease",
                  }}
                >
                  <span className="times_progress">
                    {parseFloat(timeLeft / 60).toFixed(0)} Minutes
                  </span>
                </div>
              </div>
            </div>
          )}
          {!isRank && <p className="ques_head"> {pool_title} Questions</p>}
          {isRank && (
            <p className="ques_head_rank"> {pool_title} Questions & Answers</p>
          )}
          {isRank && (
            <div className="afterRank">
              <div className="leftTimeTaken">
                <p className="time_taken">Time Taken</p>
                <div className="big_timeBar">
                  <div className="timeBar_left">
                    {Math?.ceil(totalTimeTaken / 60)} Min
                  </div>
                </div>
              </div>
            </div>
          )}

          {questionList.map((ques, index) => (
            <div className="mcq_ques" key={index}>
              <div className="questioning_head">
                {index + 1}. {ques.question_text}
              </div>
              {ques.choices.map((choice, index) => {
                const letterOptions = ["A", "B", "C", "D"];
                const isCorrect = isAnswerCorrect(
                  ques.question_id,
                  choice.choice_id
                );
                const isSelected =
                  selectedAnswers[ques.question_id] === choice.choice_id;

                return (
                  <div
                    className={`questioning ${
                      isRank &&
                      (isCorrect ? "correct" : isSelected ? "wrong" : "")
                    }`}
                    key={choice.choice_id}
                  >
                    <input
                      type="radio"
                      name={`question_${ques.question_id}`}
                      checked={
                        isSelected ||
                        (ques.attended_question &&
                          ques.chosen_choice === choice.choice_text)
                      }
                      onChange={() =>
                        handleOptionChange(ques.question_id, choice.choice_id)
                      }
                      disabled={isRank}
                      style={{
                        accentColor:
                          isRank && isCorrect
                            ? "green"
                            : isRank && isSelected
                            ? "red"
                            : "",
                      }}
                      className="kk"
                    />

                    <p className="points">
                      {letterOptions[index]}. {choice.choice_text}
                    </p>
                  </div>
                );
              })}

              {isRank && (
                <>
                  <p className="ans_head">Correct Answer</p>
                  <p className="correct-answer">
                    {(() => {
                      const correctChoice = questionList
                        .find((q) => q.question_id === ques.question_id)
                        ?.choices.find(
                          (choice) =>
                            choice.choice_id ===
                            getCorrectChoiceId(ques.question_id)
                        );

                      const letterOptions = ["A", "B", "C", "D"]; // Array for labels

                      const correctIndex = questionList
                        .find((q) => q.question_id === ques.question_id)
                        ?.choices.findIndex(
                          (choice) =>
                            choice.choice_id ===
                            getCorrectChoiceId(ques.question_id)
                        );

                      return correctChoice && correctIndex !== -1
                        ? `${letterOptions[correctIndex]}. ${correctChoice.choice_text}`
                        : null;
                    })()}
                  </p>
                </>
              )}
            </div>
          ))}

          {showBtn && (
            <div className="btn_quiz">
              {!isRank && (
                <button
                  className="quizBtn"
                  onClick={() => showAnsHandler(true)}
                  disabled={loading}
                >
                  {loading ? "Fetching..." : "Submit & Show Answer"}
                </button>
              )}
              {isRank && (
                <button className="quizBtn" onClick={handleRank}>
                  Show Ranking
                </button>
              )}
            </div>
          )}
          <SnackBar
            message={errors}
            severity={"error"}
            handleClose={handletoast}
            Open={toast?.error}
          />
          <SnackBar
            message={errors}
            severity={"success"}
            handleClose={handletoast}
            Open={toast?.submit}
          />
        </div>
      )}
    </>
  );
}
