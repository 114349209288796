import React, { useCallback, useEffect, useState, useMemo } from "react";
import "./SalesHome.scss";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Filler,
  BarElement,
} from "chart.js";
import { Dropdown, Space, Menu } from "antd";
import _http from "../../../Utils/Api/_http";
import { rem } from "../../../Components/Rem_func";
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Tooltip,
  Filler
);
// Helper to get the start date for 6 months back

// Helper to get the month abbreviation (e.g., "JAN", "FEB")
const getMonthAbbreviation = (date) => {
  if (!(date instanceof Date) || isNaN(date)) {
    console.error("Invalid date provided:", date);
    return "Invalid Month";
  }

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  return months[date.getMonth()];
};

const SalesTracker = ({ access }) => {
  const [users, setUsers] = useState([]);
  const [branch, setBranch] = useState("");
  const [teamleads, setTeamleads] = useState("");
  const [userList, setUserList] = useState([]);
  const [graph, setgraph] = useState(false);
  const filteredTeamLeads = useMemo(() => {
    if (!branch)
      return userList.filter((user) => user.role_name === "Teamlead");

    return userList.filter(
      (user) => user.location === branch && user.role_name === "Teamlead"
    );
  }, [branch, userList]);
  // Fetch data logic
  const fetchData1 = useCallback(async () => {
    try {
      const response = await _http.get("/api/users_db");

      if (Array.isArray(response.data) && response.data.every(Array.isArray)) {
        const flattenedData = response.data.flat();
        setUsers(flattenedData.flat());
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchData1();
  }, [fetchData1]);

  const fetchData2 = useCallback(async () => {
    // setLoading(true);
    if (access.role_name === "employee") {
      return;
    }
    try {
      const response = await _http.get("/api/get_users");
      const userLists = response.data.Output.record.flat();
      setUserList(userLists.flat());
      // setRole(response?.data?.Output?.Role);
    } catch (error) {
      // setToast({ error: true });
      // setError("Error fetching data:", error.message);
    }
    // setLoading(false);
  }, [access]);

  useEffect(() => {
    fetchData2();
  }, [fetchData2]);
  const filterUsersByBranch = (users, branch, teamLead) => {
    // If neither branch nor teamLead is selected, return all users
    if (!branch && !teamLead) return users;

    // Filter by branch if a branch is selected
    let filteredUsers = branch
      ? users.filter((user) => user.location === branch)
      : users;
    // console.log(filteredUsers.filter((user) => user.sales_person_name===));
    const filteredemployees = userList
      .filter((user) => user.reporting_to === teamLead)
      .map((name) => name.user);

    if (teamLead) {
      filteredUsers = filteredUsers.filter((user) =>
        filteredemployees.includes(user.sales_person_name)
      );
    }

    return filteredUsers;
  };

  const filteredUsers = filterUsersByBranch(users, branch, teamleads);
  // Function to filter data for the last six months and count quotes, orders, and order value
  const getLastSixMonthsData = () => {
    const today = new Date();

    const sixMonthsAgo = new Date(today);
    sixMonthsAgo.setMonth(today.getMonth() - 6);

    const twelveMonthsAgo = new Date(today);
    twelveMonthsAgo.setMonth(today.getMonth() - 12);

    // Initialize an object to hold the counts and total order value
    const monthlyData = {};
    let totalOrderValueAEDFirstSixMonths = 0;
    let totalOrderValueUSDFirstSixMonths = 0;
    let totalOrderValueOMRFirstSixMonths = 0;
    let totalOrderValueAEDSecondSixMonths = 0;
    let totalOrderValueUSDSecondSixMonths = 0;
    let totalOrderValueOMRSecondSixMonths = 0;
    // Filter users with `client_email_time_utc` within the last six months
    const filteredUsersByClientEmailTime = filteredUsers.filter((user) => {
      const clientEmailTimeUTC = new Date(user.client_email_time_utc);
      const clientEmailLocalTime = new Date(
        clientEmailTimeUTC.getTime() -
          clientEmailTimeUTC.getTimezoneOffset() * 60000
      );

      // Check if the client email time falls within the last six months
      return (
        clientEmailLocalTime >= sixMonthsAgo && clientEmailLocalTime <= today
      );
    });

    const filteredUsersByClientEmailTimeby12 = filteredUsers.filter((user) => {
      const clientEmailTimeUTC = new Date(user.client_email_time_utc);
      const clientEmailLocalTime = new Date(
        clientEmailTimeUTC.getTime() -
          clientEmailTimeUTC.getTimezoneOffset() * 60000
      );

      // Check if the client email time falls within the last six months
      return (
        clientEmailLocalTime >= twelveMonthsAgo &&
        clientEmailLocalTime <= sixMonthsAgo
      );
    });
    // Initialize total counters for each currency

    filteredUsersByClientEmailTimeby12.forEach((user) => {
      const orderDateTime = new Date(user.client_email_time_utc);
      const orderValue = parseFloat(
        user.final_order_currency?.replace(/[^0-9.-]+/g, "")
      );
      const currency = user.currency; // Assuming this field holds the currency type

      // Check if order value is a valid number and the order date is within the last 12 months
      if (
        !isNaN(orderValue) &&
        orderDateTime >= twelveMonthsAgo
        // orderDateTime <= today
      ) {
        // Add to the appropriate currency total
        if (currency === "AED") {
          totalOrderValueAEDSecondSixMonths += orderValue;
        } else if (currency === "USD") {
          totalOrderValueUSDSecondSixMonths += orderValue;
        } else if (currency === "OMR") {
          totalOrderValueOMRSecondSixMonths += orderValue;
        }
      }
    });

    // Process each user in the filtered list
    filteredUsersByClientEmailTime.forEach((user) => {
      const clientEmailTime = new Date(user.client_email_time_utc);
      const monthKey = getMonthAbbreviation(clientEmailTime);

      // Initialize the data object for the month if it doesn't exist
      monthlyData[monthKey] = monthlyData[monthKey] || {
        totalCount: 0,
        pending: 0,
        success: 0,
        orders: 0,
      };

      // Increment total count for client emails
      monthlyData[monthKey].totalCount += 1;

      // Increment count based on reminder_status
      if (user.reminder_status === "pending") {
        monthlyData[monthKey].pending += 1;
      } else if (user.reminder_status === "success") {
        monthlyData[monthKey].success += 1;
      } else if (user.reminder_status === "order_placed") {
        monthlyData[monthKey].orders += 1;
      }
      const orderDateTime = new Date(user.client_email_time_utc);
      const orderValue = parseFloat(
        user.final_order_currency?.replace(/[^0-9.-]+/g, "")
      );
      const currency = user.currency;

      if (!isNaN(orderValue)) {
        if (orderDateTime >= sixMonthsAgo && orderDateTime <= today) {
          if (currency === "AED") {
            totalOrderValueAEDFirstSixMonths += orderValue;
          } else if (currency === "USD") {
            totalOrderValueUSDFirstSixMonths += orderValue;
          } else if (currency === "OMR") {
            totalOrderValueOMRFirstSixMonths += orderValue;
          }
        }
      }
    });
    let percentageChangeAED = 0;

    if (totalOrderValueAEDSecondSixMonths > 0) {
      percentageChangeAED =
        ((totalOrderValueAEDFirstSixMonths -
          totalOrderValueAEDSecondSixMonths) /
          totalOrderValueAEDSecondSixMonths) *
        100;
    }

    let percentageChangeUSD = 0;
    if (totalOrderValueUSDSecondSixMonths > 0) {
      percentageChangeUSD =
        ((totalOrderValueUSDFirstSixMonths -
          totalOrderValueUSDSecondSixMonths) /
          totalOrderValueUSDSecondSixMonths) *
        100;
    }

    let percentageChangeOMR = 0;
    if (totalOrderValueOMRSecondSixMonths > 0) {
      percentageChangeOMR =
        ((totalOrderValueOMRFirstSixMonths -
          totalOrderValueOMRSecondSixMonths) /
          totalOrderValueOMRSecondSixMonths) *
        100;
    }
    const percentageChange = {
      percentageChangeAED,
      percentageChangeOMR,
      percentageChangeUSD,
    };
    const totalOrderValue = {
      totalOrderValueAEDFirstSixMonths,
      totalOrderValueOMRFirstSixMonths,
      totalOrderValueUSDFirstSixMonths,
    };
    return { monthlyData, percentageChange, totalOrderValue };
  };

  const {
    monthlyData: filteredData,
    totalOrderValue,
    percentageChange,
  } = getLastSixMonthsData();

  const formatNumber = (num) => {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1) + "B"; // Convert to billions
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + "M"; // Convert to millions
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + "K"; // Convert to thousands
    } else {
      return num?.toString(); // If less than a thousand, return as is
    }
  };
  // Month order array
  const monthsOrder = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  // Sort the months and map to their respective counts
  const availableMonths = Object?.keys(filteredData || {})?.sort(
    (a, b) => monthsOrder.indexOf(a) - monthsOrder.indexOf(b)
  );

  const pendingData = availableMonths?.map(
    (month) => filteredData[month]?.pending || 0
  );

  const successData = availableMonths?.map(
    (month) => filteredData[month]?.success || 0
  );
  const orderPlacedData = availableMonths.map(
    (month) => filteredData[month]?.orders || 0
  );

  const clientEmailsData = availableMonths.map(
    (month) => filteredData[month]?.totalCount || 0
  );
  // Calculate total sums for each data set
  const totalPending = pendingData.reduce((sum, count) => sum + count, 0);
  const totalSuccess = successData.reduce((sum, count) => sum + count, 0);
  const totalOrderPlaced = orderPlacedData.reduce(
    (sum, count) => sum + count,
    0
  );
  const totalClientEmails = availableMonths.reduce(
    (sum, month) => sum + (filteredData[month]?.totalCount || 0),
    0
  );

  const awaiting_quote = (totalPending / totalClientEmails) * 100 || 0;
  const awaiting_order = (totalSuccess / totalClientEmails) * 100 || 0;
  const orderPlaced = (totalOrderPlaced / totalClientEmails) * 100 || 0;

  const data = {
    labels: availableMonths,
    datasets: [
      {
        // label: "Sales",
        data: clientEmailsData || [0],
        fill: true,
        backgroundColor: "transparent",
        borderColor: "#1E6641",
        borderWidth: 4,
        pointBorderColor: "#1E6641",
        pointBackgroundColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#1E6641",
        tension: 0.4, // Curved lines
        pointRadius: 6, // Increase the size of the pointer
        pointHoverRadius: 6, // Increase size on hover
      },
      {
        // label: "Revenue",
        data: orderPlacedData || 0,
        fill: true,
        backgroundColor: "transparent",
        borderColor: "#97D3B5",
        borderWidth: 4,
        pointBorderColor: "#97D3B5",
        pointBackgroundColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#97D3B5",
        tension: 0.4, // Curved lines
        pointRadius: 6, // Increase the size of the pointer
        pointHoverRadius: 6, // Increase size on hover
      },
    ],
  };
  const BarData = {
    labels: availableMonths,

    datasets: [
      {
        type: "bar",
        data: clientEmailsData || [0],
        backgroundColor: "#1E6641", // Dark green
        borderWidth: 1.5, // Border width
        borderColor: "#1E6641",
        barPercentage: 0.8, // Control the width of bars
      },
      {
        type: "bar",
        data: orderPlacedData || 0,
        backgroundColor: "#97D3B5", // Light green
        barPercentage: 0.8,
        borderColor: "#97D3B5",
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return ` ${context.raw}`;
          },
        },
      },
      legend: {
        display: false, // Remove the legend labels
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false, // Remove grid lines on the Y-axis
        },
        ticks: {
          display: false, // Remove tick marks on the Y-axis
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
      x: {
        grid: {
          display: false, // Remove grid lines on the X-axis
        },
        ticks: {
          color: "#C0C0C0", // Color of tick labels on the X-axis
        },
        border: {
          display: false, // Hide the x-axis line
        },
      },
    },
  };
  const BarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => ` ${context.raw}`,
        },
      },
      legend: {
        display: false, // Hide the legend
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false, // Hide grid lines
        },
        ticks: {
          display: false, // Hide tick marks
        },
        border: {
          display: false, // Hide axis line
        },
      },
      x: {
        grid: {
          display: false, // Hide grid lines
        },
        ticks: {
          color: "#C0C0C0", // Color of x-axis labels
        },
        border: {
          display: false, // Hide axis line
        },
      },
    },
  };
  const handleSelectBranch = ({ key }) => {
    // If "All" is selected, clear the branch selection
    if (key === "All" || key === branch) {
      setBranch("");
      setTeamleads("");
    } else {
      setBranch(key);
      setTeamleads("");
    }
  };
  // Handle team lead selection
  const handleSelectTeamLead = ({ key }) => {
    if (key === teamleads) {
      setTeamleads("");
    } else {
      setTeamleads(key);
    }
  };
  // Define team lead menu dynamically based on the filtered team leads
  const teamLeadMenu = (
    <Menu
      onClick={handleSelectTeamLead}
      items={
        filteredTeamLeads.length > 0
          ? filteredTeamLeads.map((teamLead) => ({
              key: teamLead.user,
              label: teamLead.user,
            }))
          : [{ key: "none", label: "No TeamLeads", disabled: true }]
      }
    />
  );

  // Define your branch menu items with Ant Design's Menu component
  const branchMenu = (
    <Menu
      onClick={handleSelectBranch}
      items={[
        { key: "All", label: "All" },
        { key: "Dubai", label: "Dubai" },
        { key: "Abu Dhabi", label: "Abu Dhabi" },
        { key: "Oman", label: "Oman" },
      ]}
    />
  );
  return (
    <div className="sales-home">
      <div className="df space-between">
        <p className="userM" style={{ paddingBottom: rem(5) }}>
          Sales Tracker
        </p>
        <div className="df" style={{ gap: rem(19) }}>
          {["admin", "Manager"].includes(access?.role_name) && (
            <div className="branch">
              <Dropdown overlay={teamLeadMenu}>
                <Space>
                  <span> {teamleads || "Teamlead"}</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={rem(19)}
                    height={rem(19)}
                    viewBox="0 0 19 19"
                    fill="none"
                  >
                    <path
                      d="M13.459 7.91667L9.50065 11.875L5.54232 7.91667L13.459 7.91667Z"
                      fill="#AAAAAA"
                    />
                  </svg>
                </Space>
              </Dropdown>
            </div>
          )}
          {access.role_name === "admin" && (
            <div className="branch">
              <Dropdown overlay={branchMenu}>
                <Space>
                  {branch || "Branch"}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={rem(19)}
                    height={rem(19)}
                    viewBox="0 0 19 19"
                    fill="none"
                  >
                    <path
                      d="M13.459 7.91667L9.50065 11.875L5.54232 7.91667L13.459 7.91667Z"
                      fill="#AAAAAA"
                    />
                  </svg>
                </Space>
              </Dropdown>
            </div>
          )}
          <div
            className="bar-icon"
            onClick={() => {
              setgraph(!graph);
            }}
          >
            {!graph ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(24)}
                height={rem(24)}
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_931_4705)">
                  <path
                    d="M6.4 9.2H6.6C7.37 9.2 8 9.83 8 10.6V17.6C8 18.37 7.37 19 6.6 19H6.4C5.63 19 5 18.37 5 17.6V10.6C5 9.83 5.63 9.2 6.4 9.2ZM12 5C12.77 5 13.4 5.63 13.4 6.4V17.6C13.4 18.37 12.77 19 12 19C11.23 19 10.6 18.37 10.6 17.6V6.4C10.6 5.63 11.23 5 12 5ZM17.6 13C18.37 13 19 13.63 19 14.4V17.6C19 18.37 18.37 19 17.6 19C16.83 19 16.2 18.37 16.2 17.6V14.4C16.2 13.63 16.83 13 17.6 13Z"
                    fill="#1E6641"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_931_4705">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(24)}
                height={rem(24)}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4 2V20H22V22H2V2H4ZM21.914 5.5L15.5 11.914L11.5 7.914L6.5 12.914L5.086 11.5L11.5 5.086L15.5 9.086L20.5 4.086L21.914 5.5ZM21.914 10.5L15.5 16.914L11.5 12.914L6.5 17.914L5.086 16.5L11.5 10.086L15.5 14.086L20.5 9.086L21.914 10.5Z"
                  fill="#1E6641"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
      <div style={{ width: "80%", height: rem(216), margin: "auto" }}>
        {graph ? (
          <Bar data={BarData} options={BarOptions} height={200} />
        ) : (
          <Line data={data} options={options} height={200} />
        )}
      </div>

      <div
        className="sales-bottom df space-between"
        style={{ paddingBottom: rem(21) }}
      >
        <div className="df" style={{ gap: rem(16) }}>
          <div>
            <div className="salespercentage">
              AED{" "}
              {formatNumber(totalOrderValue?.totalOrderValueAEDFirstSixMonths)}
            </div>
            <div className="df" style={{ gap: rem(8) }}>
              <div className="total">Total Sales</div>
              <div className="df ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={rem(21)}
                  height={rem(19)}
                  viewBox="0 0 21 19"
                  fill="none"
                  style={{
                    rotate:
                      percentageChange?.percentageChangeAED < 0 ? "180deg" : "",
                  }}
                >
                  <g clipPath="url(#clip0_931_4712)">
                    <path
                      d="M6.56445 11.351L10.757 7.61182L14.9495 11.351H6.56445Z"
                      fill={
                        percentageChange?.percentageChangeAED < 0
                          ? "#E56553"
                          : "#8FDBB5"
                      }
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_931_4712">
                      <rect
                        width="20.1242"
                        height="17.948"
                        fill="white"
                        transform="translate(0.695312 0.881348)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div
                  className="qul"
                  style={{
                    color:
                      percentageChange?.percentageChangeAED < 0
                        ? "#E56553"
                        : "",
                  }}
                >
                  {parseFloat(percentageChange?.percentageChangeAED)?.toFixed(
                    2
                  )}
                  %
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="salespercentage">
              OMR{" "}
              {formatNumber(totalOrderValue?.totalOrderValueUSDFirstSixMonths)}
            </div>
            <div className="df" style={{ gap: rem(8) }}>
              <div className="total">Total Sales</div>
              <div className="df">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={rem(21)}
                  height={rem(19)}
                  viewBox="0 0 21 19"
                  fill="none"
                  style={{
                    rotate:
                      percentageChange?.percentageChangeOMR < 0 ? "180deg" : "",
                  }}
                >
                  <g clipPath="url(#clip0_931_4712)">
                    <path
                      d="M6.56445 11.351L10.757 7.61182L14.9495 11.351H6.56445Z"
                      fill={
                        percentageChange?.percentageChangeOMR < 0
                          ? "#E56553"
                          : "#8FDBB5"
                      }
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_931_4712">
                      <rect
                        width="20.1242"
                        height="17.948"
                        fill="white"
                        transform="translate(0.695312 0.881348)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div
                  className="qul"
                  style={{
                    color:
                      percentageChange?.percentageChangeOMR < 0
                        ? "#E56553"
                        : "",
                  }}
                >
                  {parseFloat(percentageChange?.percentageChangeOMR)?.toFixed(
                    2
                  )}
                  %
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="salespercentage">
              USD{" "}
              {formatNumber(totalOrderValue?.totalOrderValueUSDFirstSixMonths)}
            </div>
            <div className="df" style={{ gap: rem(8) }}>
              <div className="total">Total Sales</div>
              <div className="df">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={rem(21)}
                  height={rem(19)}
                  viewBox="0 0 21 19"
                  fill="none"
                  style={{
                    rotate:
                      percentageChange?.percentageChangeUSD < 0 ? "180deg" : "",
                  }}
                >
                  <g clipPath="url(#clip0_931_4712)">
                    <path
                      d="M6.56445 11.351L10.757 7.61182L14.9495 11.351H6.56445Z"
                      fill={
                        percentageChange?.percentageChangeUSD < 0
                          ? "#E56553"
                          : "#8FDBB5"
                      }
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_931_4712">
                      <rect
                        width="20.1242"
                        height="17.948"
                        fill="white"
                        transform="translate(0.695312 0.881348)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div
                  className="qul"
                  style={{
                    color:
                      percentageChange?.percentageChangeUSD < 0
                        ? "#E56553"
                        : "",
                  }}
                >
                  {parseFloat(percentageChange?.percentageChangeUSD)?.toFixed(
                    2
                  )}
                  %
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="qul df" style={{ gap: rem(6) }}>
          <div className="tick">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(12)}
              height={rem(12)}
              viewBox="0 0 12 12"
              fill="none"
            >
              <g clipPath="url(#clip0_931_4718)">
                <path
                  d="M4.81935 7.50603L3.21518 5.90187C3.03643 5.72312 2.75227 5.72312 2.57352 5.90187C2.39477 6.08062 2.39477 6.36478 2.57352 6.54353L4.49393 8.46395C4.67268 8.6427 4.96143 8.6427 5.14018 8.46395L9.99851 3.6102C10.1773 3.43145 10.1773 3.14729 9.99851 2.96854C9.81976 2.78979 9.5356 2.78979 9.35685 2.96854L4.81935 7.50603Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_931_4718">
                  <rect
                    width="11"
                    height="11"
                    fill="white"
                    transform="translate(0.695312 0.0810547)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          On track
        </div>
      </div>
      <div className="df  justify-center ">
        <div className="flexBox tracker">
          <div className="com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(8)}
              height={rem(8)}
              viewBox="0 0 8 8"
              fill="none"
              style={{ marginTop: rem(5), marginRight: rem(8) }}
            >
              <circle cx="4" cy="4" r="4" fill="#1E6641" />
            </svg>
            <div>
              <p className="enq">Enquiries</p>
              <p className="per">100%</p>
            </div>
          </div>
          <div className="com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(8)}
              height={rem(8)}
              viewBox="0 0 8 8"
              fill="none"
              style={{ marginTop: rem(5), marginRight: rem(8) }}
            >
              <circle cx="4" cy="4" r="4" fill="#EB5757" />
            </svg>
            <div>
              <p className="enq">Awaiting Quote</p>
              <p className="per">{`${awaiting_quote?.toFixed(2)}%` || 0}</p>
            </div>
          </div>
          <div className="com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(8)}
              height={rem(8)}
              viewBox="0 0 8 8"
              fill="none"
              style={{ marginTop: rem(5), marginRight: rem(8) }}
            >
              <circle cx="4" cy="4" r="4" fill="#FFBC10" />
            </svg>
            <div>
              <p className="enq">Awaiting Order</p>
              <p className="per">{`${awaiting_order?.toFixed(2)}%`}</p>
            </div>
          </div>
          <div className="com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(8)}
              height={rem(8)}
              viewBox="0 0 8 8"
              fill="none"
              style={{ marginTop: rem(5), marginRight: rem(8) }}
            >
              <circle cx="4" cy="4" r="4" fill="#63C89B" />
            </svg>
            <div>
              <p className="enq">Order Placed</p>
              <p className="per">{`${orderPlaced?.toFixed(2)}%`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesTracker;
