// MainHome.jsx
import React from "react";
import SideBar from "../../Components/Sidebar/SideBar";
import { Outlet } from "react-router-dom";
import { selectIsToggleOn } from "../../Redux/Reducers";
import { useSelector } from "react-redux";
import "../../App.scss";
import { UserImageProvider } from "../../Components/UserImageProvider";

// MainHome component
const MainHome = () => {
  // Retrieve the state of the sidebar from the Redux store
  const isSidebarOpen = useSelector(selectIsToggleOn);

  // const paginationContainerWidth = 296;

  // useEffect hook to update scroll height on window resize
  // useEffect(() => {
  //   const calculateScrollY = () => {
  //     const remainingHeight = window.innerHeight - paginationContainerWidth;
  //     return rem(remainingHeight);
  //   };

  //   const handleResize = () => {
  //     const newScrollHeight = calculateScrollY();
  //     dispatch(setScrollHeight(newScrollHeight));
  //   };
  //   handleResize();
  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [dispatch]);

  // MainHome component JSX
  return (
    <>
      <UserImageProvider>
        {/* Main content container with dynamic class based on sidebar state */}
        <main
          className={`main-container ${
            isSidebarOpen ? "" : "min-sidebar"
          } content ${isSidebarOpen ? "with-sidebar" : "without-sidebar"}`}
        >
          {/* Sidebar component */}
          <SideBar />

          {/* React Router Outlet for rendering nested routes */}
          <>
            <Outlet />
          </>
        </main>
      </UserImageProvider>
    </>
  );
};

export default MainHome;
