/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import Line from "./../../Assets/Quiz/Line.svg";
import upload from "./../../Assets/Quiz/upload.svg";
import csv2 from "./../../Assets/Quiz/csv2.svg";
import xlsx2 from "./../../Assets/Quiz/xlsx2.svg";
import _http from "../../Utils/Api/_http";
import "./Quiz.scss";
import SnackBar from "../../Components/SnackBar";
import { useNavigate } from "react-router-dom";
import QuizModel from "./QuizModel";
import QuizRecent from "./QuizRecent";

const Quiz = () => {
  const navigate = useNavigate();
  const [uploaded, setUploaded] = useState(false);
  const [questions, setQuestions] = useState([
    {
      title: "",
      question_text: "",
      choices: [
        { choice_text: "", is_correct: false },
        { choice_text: "", is_correct: false },
        { choice_text: "", is_correct: false },
        { choice_text: "", is_correct: false },
      ],
    },
  ]);
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const [open, setOpen] = React.useState(false);

  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    navigate("/SpiraQuiz");
    setQuestions([
      {
        title: "",
        question_text: "",
        choices: [
          { choice_text: "", is_correct: false },
          { choice_text: "", is_correct: false },
          { choice_text: "", is_correct: false },
          { choice_text: "", is_correct: false },
        ],
      },
    ]);
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log("Dropped files:", files);
    uploadPdf(files);
  };
  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const sampleData = async (fileType) => {
    try {
      // Create a FormData object
      const formData = new FormData();
      // Append the file type to the FormData (replace "file_type" with the actual key used by your API)
      formData.append("file_type", fileType);

      // Make the API call to receive the file
      const response = await _http.post("/quiz/serve-sample-file", formData, {
        responseType: "blob", // Expecting a blob response to handle file data
      });

      // Create a Blob from the response
      const blob = new Blob([response.data], { type: fileType });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element for downloading the file
      const link = document.createElement("a");
      link.href = url;

      // Set the file name based on the type
      const fileExtension = fileType === "csv" ? "csv" : "xlsx";
      link.setAttribute("download", `SampleQuiz.${fileExtension}`);

      // Append the link to the document, trigger the download, and remove the link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the object URL
      window.URL.revokeObjectURL(url);
    } catch (err) {
      // Handle errors
      setToast({ error: true });
      setError("Something went wrong❌");
    }
  };

  // Function to add a new question

  const uploadPdf = async (file) => {
    if (!file) {
      setToast({ error: true });
      setError("Please select a file");
      setLoading(false);
      // setIsDropped(false);
    } else {
      setLoading(true);
      // setIsDropped(true);
      try {
        const formData = new FormData();
        formData.append("file", file[0]);
        const response = await _http.post("/quiz/FileUpload_QA", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // Check if response.data.result is an array
        if (Array.isArray(response.data.result)) {
          const apiData = response.data.result;

          // Transform the API response
          const formattedData = apiData.map((item) => ({
            title: "",
            question_text: item.question_text,
            choices: item.choice_text.map((choice) => ({
              choice_text: choice,
              is_correct: choice === item.correct_answer,
            })),
          }));

          setQuestions(formattedData);
        }

        setOpen(true);
        setUploaded(true);
        sessionStorage.setItem("QuizUpload", file[0].name);
        sessionStorage.setItem("Type", file[0].type);
        console.log(file[0].type);
        // navigate("/SpiraQuiz/Upload");
        // alert(file);
        setToast(
          (response.data.message && { submit: true }) ||
            (response.data.error && { error: true })
        );
        setError(response.data.message || response.data.error);
      } catch (error) {
        setToast({ error: true });
        setError("Invalid File ⚠️");
        setUploaded(false);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <section className="quizz">
        {loading ? (
          <div className="spinners">
            {/* <img src={Animation} draggable="false" alt="" /> */}
          </div>
        ) : (
          <>
            <div className="df container-head1 w100">
              <span className="sales">Qre /</span>
              <span className="sales-title">Qre Configure</span>
            </div>
            <div className="container-head df w100">
              <div style={{ width: "20%" }}>
                <span className="title">QRE Configure</span>
              </div>
            </div>
            <span className="q-btns">
              <button className="q-btn1 main_btn">Quiz</button>
              <button
                className="q-btn1"
                onClick={() => navigate("./catalogue")}
              >
                Catalogue
              </button>
              <button className="q-btn1" onClick={() => navigate("./photo")}>
                Photo
              </button>
            </span>
            <div className="mainquizcontainer">
              <div className="q-up">
                <span className="q-up-upld">Upload the File Here</span>
                <div className="q-up1">
                  <span className="q-up-up1d">File and Data Format</span>
                  <div className="container">
                    <div className={`q-upload`}>
                      <div
                        className="drag1"
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragEnter}
                        onDrop={handleDrop}
                        onClick={handleFileUpload}
                      >
                        <img src={upload} alt="up-svg" className="up-svg" />
                        <p>
                          Click to Upload or Drag and Drop your Files <br />
                          <span>Supports .xlsx .csv Files</span>
                        </p>
                      </div>
                    </div>
                    <div className="dldbuttons">
                      <span onClick={() => sampleData("csv")}>
                        <img src={csv2} alt="" className="btnIcons" />
                        Sample.csv
                      </span>
                      <span onClick={() => sampleData("xlsx")}>
                        <img src={xlsx2} alt="" className="btnIcons" />
                        Sample.xlsx
                      </span>
                    </div>

                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      accept=".csv,.xlsx"
                      onChange={(e) => {
                        uploadPdf(e.target.files);
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* line  */}

              <div className="q-line">
                <div className="lineabs">
                  <img src={Line} />
                </div>

                <h1 className="q-txt">Or</h1>
              </div>

              {/* right side  */}
              <div className="q-down">
                <div className="qf-form" onClick={() => setOpen(true)}>
                  <h1>Enter Questions Manually</h1>
                  <form className="qf-data">
                    <p>Enter the question Here</p>
                    <div className="q-radio">
                      <label>
                        <input
                          type="radio"
                          value="option1"
                          name="quizoption"
                          color="red"
                        />
                        A. Enter the option here
                      </label>
                    </div>
                    <div className="q-radio">
                      <label>
                        <input type="radio" name="quizoption" value="option2" />
                        B. Enter the option here
                      </label>
                    </div>
                    <div className="q-radio">
                      <label>
                        <input type="radio" name="quizoption" value="option3" />
                        C. Enter the option here
                      </label>
                    </div>
                    <div className="q-radio">
                      <label>
                        <input type="radio" name="quizoption" value="option3" />
                        D. Enter the option here
                      </label>
                    </div>
                  </form>
                </div>
                {open && (
                  <QuizModel
                    formdata={questions}
                    setFormdata={setQuestions}
                    open={open}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    upload={uploaded}
                  />
                )}
              </div>
            </div>
            <div className="recentquizPage">
              <QuizRecent setLoading={setLoading} />
            </div>
          </>
        )}

        <SnackBar
          message={errors}
          severity={"error"}
          handleClose={handletoast}
          Open={toast?.error}
        />
        <SnackBar
          message={errors}
          severity={"success"}
          handleClose={handletoast}
          Open={toast?.submit}
        />
      </section>
    </>
  );
};

export default Quiz;
