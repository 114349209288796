import React from "react";
import logoImg from "../../Assets/QuizMobile/spiraLogo1.svg";

export default function ScheduleError() {
  return (
    <div className="schedule_error">
      <div className="schedule_logo">
        <img src={logoImg} alt="error" />
      </div>

      <div className="scheduler_main">
        <p className="main_p">
          We’re sorry, the quiz hasn't been scheduled yet.{" "}
        </p>
      </div>
    </div>
  );
}
