const SalesTable = ({ employeesArray, formatNumber }) => {
  return (
    <div className="quote_home sales-dashboard-table w100">
      <div className="quote-home-table">
        <table>
          <thead>
            <tr>
              <th className="tl">Sales Team Member</th>
              <th>Enquiries</th>
              <th>Conversion %(Enquiries)</th>
              <th>Conversion %(Awarded)</th>
              <th>Awarded-AED</th>
              <th>Awarded-USD</th>
              <th>Awarded-OMR</th>
            </tr>
          </thead>
          <tbody>
            {employeesArray?.name?.length ? (
              employeesArray.name.map((employeeName, index) => {
                // Destructure and provide default values to avoid undefined errors
                const {
                  USD = 0,
                  AED = 0,
                  OMR = 0,
                } = employeesArray.orderValueSums[index] || {};
                return (
                  <tr key={index}>
                    <td className="textCaps tl">{employeeName || "DataNR"}</td>
                    <td>{employeesArray.count[index] || 0}</td>
                    <td>
                      {employeesArray.order_count[index]
                        ? `${(
                            (employeesArray.order_count[index] /
                              employeesArray.count[index]) *
                            100
                          )?.toFixed(2)}%`
                        : "0.00%"}
                    </td>
                    <td>
                      {employeesArray.quote_count[index]
                        ? `${(
                            (employeesArray.order_count[index] /
                              employeesArray.quote_count[index]) *
                            100
                          )?.toFixed(2)}%`
                        : "0.00%"}
                    </td>
                    <td>{formatNumber(AED.toFixed(2))}</td>
                    {/* Order Value Sum in AED */}
                    <td>{formatNumber(USD.toFixed(2))}</td>
                    {/* Order Value Sum in USD */}
                    <td>{formatNumber(OMR.toFixed(2))}</td>
                    {/* Order Value Sum in OMR */}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="7">No Sales Person found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SalesTable;
