import React from "react";
import SalesTrackers from "../STcomponents/SalesTracker/SalesTracker";
import EnquiresTable from "./EnquiresTable";

const Enquires = () => {
  return (
    <>
      <SalesTrackers
        title={"All Enquiries"}
        UserDataTable={EnquiresTable}
        api={"/api/users_db"}
      />
    </>
  );
};

export default Enquires;
