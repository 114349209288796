import React from "react";
import { Bar } from "react-chartjs-2";
import { rem } from "../../../Components/Rem_func";
// import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  //   ChartDataLabels,
  Tooltip,
  Legend
);

const DeclineReason = ({ users, formatNumber }) => {
  const reasonData = [
    "Technical issue",
    "Price issue",
    "Delivery time issues",
    "ICV Value is not matched",
    "Quality Issue From Spira",
    "Others",
  ];

  const rejectedOrders = users.filter(
    (user) => user.reminder_status === "rejected"
  );
  console.log(rejectedOrders);

  const reasonCount = reasonData.reduce((acc, reason) => {
    acc[reason] = 0;
    return acc;
  }, {});

  rejectedOrders.forEach((user) => {
    const reason = user.reason;
    if (reasonData.includes(reason)) {
      reasonCount[reason] += 1;
    } else {
      reasonCount["Others"] += 1;
    }
  });
  console.log(reasonCount);

  const data = {
    labels: [
      "Technical", // Word broken into two lines
      "Price",
      "Delivery",
      "ICV Value",
      "Quality",
      "Others",
    ],
    datasets: [
      // {
      //   type: "line",
      //   label: "Opportunity ",
      //   data: [1, 0, 3, 0, 0, 0],
      //   borderColor: "#E0E7ED",
      //   borderWidth: 1.5,
      //   pointBackgroundColor: "#E0E7ED",
      //   pointBorderColor: "#E0E7ED",
      //   pointRadius: 5,
      //   fill: false,
      //   order: 1, // Ensures the line is drawn on top of the bars
      // },
      {
        type: "bar",
        data: reasonData.map((reason) => reasonCount[reason] || 0),
        // backgroundColor: "rgba(30, 102, 65, 1)",
        backgroundColor: "#609379",
        // hoverBackgroundColor: "rgba(31, 103, 66, 0.31)",
        barPercentage: 0.3,
        borderRadius: 10,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 8,
          },
          color: "#636363",
          align: "middle", // Aligns the tick labels cross-wise
          maxRotation: 0, // Maximum rotation for tick labels
          minRotation: 0,
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 5,

          font: {
            size: 10,
          },
          color: "#636363",
          beginAtZero: true,
          callback: function (value) {
            return value; // Add 'M' after the value for millions
          },
        },
        title: {
          display: false,
          //   text: "Awarded value",
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
      y1: {
        grid: {
          display: false,
          drawOnChartArea: false,
        },

        title: {
          display: false,
        },
        border: {
          display: false, // Hide the y-axis line
        },
        ticks: {
          display: false,
        },
      },
      x1: {
        title: {
          display: false,
        },
        border: {
          display: false, // Hide the y-axis line
        },
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            // Get the data value
            const value = tooltipItem.raw;

            // Format the tooltip text
            return `Count: ${value}`;
          },
        },
      },
    },
    // datalabels: {
    //   display: true,
    //   align: "end",
    //   anchor: "end",
    //   color: "#FFF",
    //   font: {
    //     size: 10,
    //     weight: "bold",
    //   },
    //   formatter: function (value) {
    //     return value + "M";
    //   },
    // },
  };

  return (
    <div className="revenue-graph df flexColumn space-between ">
      <div className="revenue-title df justify-center">Decline Reason</div>

      <div
        style={{
          width: "100%",
          height: rem(277),
        }}
      >
        <Bar data={data} options={options} />
      </div>
      <div className="df revenue-label">
        <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#609379"
              // fill="#BEC8D0"
            />
          </svg>
          Rejected Count
        </div>
        {/* <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#BEC8D0"
            />
          </svg>
          Opportunity
        </div> */}
      </div>
    </div>
  );
};

export default DeclineReason;
