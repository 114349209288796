import React, { useState } from "react";
import "./ChangePassword.scss";
import { Modal } from "antd";
import { rem } from "../../Components/Rem_func";
// import SnackBar from "../../Components/SnackBar";
import Button from "../../Components/Button/Button";
import _http from "../../Utils/Api/_http";
import FaEye from "../../Components/Svg/FaEye";
import FaEyeSlash from "../../Components/Svg/FaEyeSlash";

const ChangePassword = ({ openmodel, handleClose, handleSaveToast }) => {
  const [formdata, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    retypePassword: "",
  });

  const [loading, setloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [newshowPassword, setNewShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setNewShowPassword(!newshowPassword);
  };
  const validatePasswordStrength = (password) => {
    const errors = [];
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    // const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      errors.push(`${minLength} characters long`);
    }
    if (!hasUpperCase) {
      errors.push("one uppercase letter");
    }
    if (!hasNumber) {
      errors.push("one number");
    }
    if (!hasSpecialChar) {
      errors.push("one special character");
    }

    if (errors.length > 0) {
      return `Password must contain ${errors.join(", ")}`;
    }

    return null;
  };
  // Event handler for input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the regex patterns based on input type
    let pattern = /^[^\s]+$/;

    // Allow empty value to let the user delete input
    if (!pattern || value === "" || pattern.test(value)) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value.replace(/\s+/g, " "),
      }));
    }
  };
  const changePasswordHandler = async (e) => {
    e.preventDefault();
    if (
      !formdata.oldPassword ||
      !formdata.newPassword ||
      !formdata.retypePassword
    ) {
      handleSaveToast(false, true, "Please fill all the input fields");
      return;
    }
    if (formdata.newPassword !== formdata.retypePassword) {
      handleSaveToast(false, true, "Password is not matching");
      return;
    }

    const passwordErrors = validatePasswordStrength(formdata.newPassword);
    if (passwordErrors) {
      handleSaveToast(false, true, passwordErrors);
      return;
    }

    const formData = new FormData();
    formData.append("old_password", formdata?.oldPassword);
    formData.append("new_password", formdata?.newPassword);
    setloading(true);
    try {
      const res = await _http.post("/api/change-password", formData);
      console.log(res);
      if (res.status === 200) {
        handleSaveToast(true, false, res?.data?.message);
      }
      setFormData({
        oldPassword: "",
        newPassword: "",
        retypePassword: "",
      });
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        handleSaveToast(false, true, error.response.data.error);
      } else {
        handleSaveToast(
          false,
          true,
          error.response.data.error || "Server error please try again"
        );
      }
    }
    setloading(false);
  };

  return (
    <Modal
      centered
      open={openmodel}
      onCancel={handleClose}
      width={rem(589)}
      // height={rem(619)}
      className="edit-popup"
    >
      <div className="flexBox login change-password">
        <div className={`login-card login-remove-border`}>
          <form className="login-card-form">
            <h1> Change Password </h1>

            <label>Old Password</label>

            <input
              type="text"
              name="oldPassword"
              id="oldPassword"
              placeholder="********"
              value={formdata.oldPassword}
              onChange={handleChange}
              className="txtBox"
              readOnly={loading}
            />
            <label>New Password</label>
            <div className="password-input txtBox">
              <input
                type={!newshowPassword ? "text" : "password"}
                name="newPassword"
                id="newPassword"
                placeholder="********"
                value={formdata.newPassword}
                onChange={handleChange}
                readOnly={loading}
              />
              <span
                className="password-toggle-icon"
                onClick={togglePasswordVisibility1}
              >
                {newshowPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            <label>Retype Password</label>
            <div className="password-input txtBox">
              <input
                type={!showPassword ? "text" : "password"}
                name="retypePassword"
                id="retypePassword"
                placeholder="********"
                value={formdata.retypePassword}
                onChange={handleChange}
                // className="txtBox"
                readOnly={loading}
              />
              <span
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            <div className="login-btn">
              {/* Login button component with loading state handling */}
              <Button
                disabled={loading}
                onclick={changePasswordHandler}
                btnname={loading ? "changing..." : "Change Password"}
                width={"100%"}
                height={57}
                fs={20}
                fw={600}
                br={4}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    changePasswordHandler();
                  }
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePassword;
