import timerLogo from "../../Assets/QuizMobile/TimePageLogo.svg";
import timerDot from "../../Assets/QuizMobile/dot.svg";

export default function TimerPage({ timeLeft }) {
  // Convert timeLeft to days, hours, minutes, and seconds
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return (
    <div className="timer_page">
      <div className="timer_logo">
        <img src={timerLogo} alt="error" />
      </div>
      <div className="mid-timer">
        <p className="timer-title">Your Quiz start in</p>
        <div className="timing">
          <div className="start_t">
            <div className="main_p">
              <p className="runTime">{days}</p>
              <span className="day_sty">Days</span>
            </div>

            <div className="dot_sty">
              <img src={timerDot} alt="error" />
              <img src={timerDot} alt="error" />
            </div>
          </div>
          <div className="start_t">
            <div className="main_p">
              <p className="runTime">{hours}</p>
              <span className="day_sty">Hours</span>
            </div>

            <div className="dot_sty">
              <img src={timerDot} alt="error" />
              <img src={timerDot} alt="error" />
            </div>
          </div>
          <div className="start_t">
            <div className="main_p">
              <p className="runTime">{minutes}</p>
              <span className="day_sty">Mins</span>
            </div>

            <div className="dot_sty">
              <img src={timerDot} alt="error" />
              <img src={timerDot} alt="error" />
            </div>
          </div>
          <div className="start_t">
            <div className="main_p">
              <p className="runTime">{seconds}</p>
              <span className="day_sty">Secs</span>
            </div>
          </div>
        </div>
        <p className="txt-foot">You can login only after the quiz go live</p>
      </div>
    </div>
  );
}
