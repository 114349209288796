import React from "react";
import { rem } from "../Rem_func";
const FaEyeSlash = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={rem(21)}
        height={rem(14)}
        viewBox="0 0 21 14"
        fill="none"
      >
        <path
          d="M10.0238 0.324707C5.69481 0.324707 1.99784 3.01735 0.5 6.81821C1.99784 10.6191 5.69481 13.3117 10.0238 13.3117C14.3528 13.3117 18.0498 10.6191 19.5476 6.81821C18.0498 3.01735 14.3528 0.324707 10.0238 0.324707ZM10.0238 11.1472C7.6342 11.1472 5.69481 9.20782 5.69481 6.81821C5.69481 4.4286 7.6342 2.48921 10.0238 2.48921C12.4134 2.48921 14.3528 4.4286 14.3528 6.81821C14.3528 9.20782 12.4134 11.1472 10.0238 11.1472ZM10.0238 4.22081C8.58658 4.22081 7.42641 5.38098 7.42641 6.81821C7.42641 8.25544 8.58658 9.41562 10.0238 9.41562C11.461 9.41562 12.6212 8.25544 12.6212 6.81821C12.6212 5.38098 11.461 4.22081 10.0238 4.22081Z"
          fill="#A9A9A9"
        />
        <path
          d="M0.5 12.2294L20.5 1.27704"
          stroke="#A9A9A9"
          strokeWidth="1.90476"
        />
      </svg>
    </div>
  );
};

export default FaEyeSlash;
