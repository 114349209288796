// Importing necessary dependencies and styles
import React from "react";
// import "./UserCards.scss"; // Custom styles for the component

// Functional component for user cards displaying user statistics
const SalesCards = ({ salesList }) => {
  // Data for user cards, including title, icon, count, and bar color
  const userCardData = [
    {
      title: "Enquiries",
      count: salesList?.total, // Total user count (length of user list)
      barcolor: "#059586", // Bar color for styling
    },
    {
      title: "Awaiting Quote",
      count: salesList?.pending, // Placeholder count for active users
      barcolor: "#FF9B85", // Bar color for styling
    },
    {
      title: "Awaiting Order",
      count: salesList?.success, // Placeholder count for inactive users
      barcolor: "#FFE18D", // Bar color for styling
    },
    {
      title: "Order Placed",
      count: salesList?.order_placed, // Placeholder count for inactive users
      barcolor: "#97D3B5", // Bar color for styling
    },
    {
      title: "Order Rejected",
      count: salesList?.rejected, // Placeholder count for inactive users
      barcolor: "#CB6A6A", // Bar color for styling
    },
  ];

  // Rendering user cards based on the provided data
  return (
    <div className="df user-cards">
      {/* Mapping through userCardData array to generate user cards */}
      {userCardData?.map((item, index) => (
        <div
          className="df user-box"
          key={index}
          style={{ backgroundColor: item?.barcolor }} // Applying bar color as background
        >
          <div className="df user-container">
            {/* Displaying user title and count */}
            <div className="df user-left">
              <span className="user-title">{item?.title}</span>
              <span className="user-count">{item?.count}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SalesCards;
