import React, { useMemo, useEffect } from "react";
import { rem } from "../../../Components/Rem_func";
import DraggableTable from "../../../Components/TableDrag_func";

const AQTable = ({
  handleOpen,
  users,
  filteredData,
  loading,
  renderEmptyOrValue,
  totalCount,
  defaultPageSize,
  DateSorter,
  searchData,
  filters,
}) => {
  // useMemo hook to filter and format user data based on filter criteria
  const filteredUsers = useMemo(() => {
    return (users || [])
      .filter((user) => {
        const convertToLocalDate = (utcDateString) => {
          if (!utcDateString) return null;
          const utcDate = new Date(utcDateString);
          return new Date(
            utcDate.getFullYear(),
            utcDate.getMonth(),
            utcDate.getDate(),
            utcDate.getHours(),
            utcDate.getMinutes(),
            utcDate.getSeconds()
          );
        };
        const matchesFilteredData =
          (user?.new_ref_num || "")
            .toLowerCase()
            .includes((filteredData?.ack_number || "").toLowerCase()) &&
          (user?.sales_person_name || "")
            .toLowerCase()
            .includes((filteredData?.sales_person_name || "").toLowerCase()) &&
          (user?.client_person_name || "")
            .toLowerCase()
            .includes((filteredData?.client_person_name || "").toLowerCase()) &&
          (user?.client_subject || "")
            .toLowerCase()
            .includes((filteredData?.client_subject || "").toLowerCase()) &&
          (filteredData.client_email_from
            ? convertToLocalDate(user.client_email_time_utc) >=
              new Date(filteredData.client_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData.client_email_to
            ? convertToLocalDate(user.client_email_time_utc) <=
              new Date(filteredData.client_email_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData.sales_email_from
            ? convertToLocalDate(user.sales_email_time_utc) >=
              new Date(filteredData.sales_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData.sales_email_to
            ? convertToLocalDate(user.sales_email_time_utc) <=
              new Date(filteredData.sales_email_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData.ack_email_from
            ? convertToLocalDate(user.ack_time_utc) >=
              new Date(filteredData.ack_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData.ack_email_to
            ? convertToLocalDate(user?.ack_time_utc) <=
              new Date(filteredData?.ack_email_to).setHours(23, 59, 59, 999)
            : true);
        // Search logic: check if any field contains the searchData string
        const matchesSearchData = searchData
          ? (user?.new_ref_num || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_subject || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_mail || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_email || "")
              .toLowerCase()
              .includes(searchData.toLowerCase())
          : true;
        const SalesPersonName = filters?.sales_team
          ? (user?.sales_person_name || "")
              .toLowerCase()
              .includes((filters?.sales_team || "").toLowerCase())
          : true;
        return matchesFilteredData && matchesSearchData && SalesPersonName;
      })
      .map((user) => {
        // Highlight search matches in relevant fields
        const matchReferenceNumber =
          searchData &&
          user.new_ref_num?.toLowerCase().includes(searchData.toLowerCase());

        const matchClientPersonName =
          searchData &&
          user.client_person_name
            ?.toLowerCase()
            .includes(searchData.toLowerCase());

        const matchSalesPersonName =
          searchData &&
          user.sales_person_name
            ?.toLowerCase()
            .includes(searchData.toLowerCase());

        const matchClientSubject =
          searchData &&
          user.client_subject?.toLowerCase().includes(searchData.toLowerCase());

        const matchClientEmail =
          searchData &&
          user.client_email?.toLowerCase().includes(searchData.toLowerCase());

        return {
          ...user, // Return all original user data
          new_ref_num_class: matchReferenceNumber ? "highlighted-text" : "",
          client_person_name_class: matchClientPersonName
            ? "highlighted-text"
            : "",
          sales_person_name_class: matchSalesPersonName
            ? "highlighted-text"
            : "",
          client_subject_class: matchClientSubject ? "highlighted-text" : "",
          client_email_class: matchClientEmail ? "highlighted-text" : "",
        };
      });
  }, [users, filteredData, searchData, filters]);

  // Configuration for table columns
  const columns = [
    {
      title: "ACK Number",
      dataIndex: "new_ref_num",
      key: "new_ref_num",
      width: rem(124),
      className: "",
      sorter: (a, b) =>
        (a.new_ref_num || "").localeCompare(b.new_ref_num || ""),
      render: (text, record) =>
        renderEmptyOrValue(text, "", record.new_ref_num_class),
    },
    {
      title: "Sales Team Member",
      dataIndex: "sales_person_name",
      key: "sales_person_name",
      className: "",
      sorter: (a, b) =>
        (a.sales_person_name || "").localeCompare(b.sales_person_name || ""),
      width: rem(136),
      render: (text, record) =>
        renderEmptyOrValue(
          text?.toLowerCase(),
          true,
          record.sales_person_name_class
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: rem(154),
      render: (text, record) => {
        return <span className="aqo status">Awaiting Quote</span>;
      },
      className: "",
    },
    {
      title: "Awaiting Quote",
      dataIndex: "client_email_time_utc",
      key: "client_email_time_utc",
      width: rem(114),
      render: (text, record) => {
        const quoteDate = new Date(text); // 'text' is the UTC date
        const currentUtcDate = new Date().toISOString(); // Get the current time in UTC format

        const quoteAgeingDays = !isNaN(quoteDate.getTime())
          ? Math.floor(
              (new Date(currentUtcDate).getTime() - quoteDate.getTime()) /
                (1000 * 60 * 60 * 24)
            )
          : null;
        return (
          <span>
            {text ? (
              quoteAgeingDays ? (
                `${quoteAgeingDays || 0} days`
              ) : quoteAgeingDays === 0 ? (
                "0 days"
              ) : (
                <span className="empty">MismatchF</span>
              )
            ) : (
              <span className="empty">--</span>
            )}
          </span>
        );
      },
      sorter: (a, b) => {
        const quoteDateA = new Date(a.client_email_time_utc || "");
        const quoteAgeingDaysA = !isNaN(quoteDateA)
          ? Math.floor((new Date() - quoteDateA) / (1000 * 60 * 60 * 24))
          : null;

        const quoteDateB = new Date(b.client_email_time_utc || "");
        const quoteAgeingDaysB = !isNaN(quoteDateB)
          ? Math.floor((new Date() - quoteDateB) / (1000 * 60 * 60 * 24))
          : null;

        return quoteAgeingDaysA - quoteAgeingDaysB;
      },
    },

    {
      title: "Buyer Seller POC",
      dataIndex: "client_person_name",
      key: "client_person_name",
      width: rem(134),
      className: "noPadding tl",

      render: (text, record) => (
        <span className="df flexColumn align-start">
          {renderEmptyOrValue(text, false, record.client_person_name_class)}

          {renderEmptyOrValue(
            record.client_email,
            false,
            record.client_email_class
          )}
        </span>
      ),
      sorter: (a, b) =>
        (a.client_person_name || "").localeCompare(b.client_person_name || ""),
    },
    {
      title: "Enquiry Subject",
      dataIndex: "client_subject",
      key: "client_subject",
      width: rem(164),
      className: "tl",
      render: (text, record) =>
        renderEmptyOrValue(text, "", record.client_subject_class),
      sorter: (a, b) =>
        (a.client_subject || "").localeCompare(b.client_subject || ""),
    },

    {
      title: `Buyer / Seller Time`,
      dataIndex: "client_email_time_utc",
      key: "client_email_time_utc",
      className: "noPadding",

      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),
      width: rem(134),
      sorter: DateSorter("client_email_time_utc"),
    },
    {
      title: `Sales to AI`,
      dataIndex: "sales_email_time_utc",
      key: "sales_email_time_utc",
      className: "noPadding",

      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),
      width: rem(114),
      sorter: DateSorter("sales_email_time_utc"),
    },
    {
      title: `AI Ack`,
      dataIndex: "ack_time_utc",
      key: "ack_time_utc",
      className: "noPadding",

      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),
      width: rem(114),
      sorter: DateSorter("ack_time_utc"),
    },
  ];
  useEffect(() => {
    const total_Pending = filteredUsers.filter(
      (item) => item.reminder_status === "pending"
    ).length;

    const total_Count = filteredUsers.length;
    const order_placed = filteredUsers?.filter(
      (item) => item?.reminder_status === "order_placed"
    ).length;

    const total_success = filteredUsers?.filter(
      (item) => item?.reminder_status === "success"
    ).length;

    const total_rejected = filteredUsers?.filter(
      (item) => item?.reminder_status === "rejected"
    ).length;

    totalCount({
      total: total_Count,
      page_count: total_Pending,
      pending: total_Pending,
      success: total_success,
      order_placed: order_placed,
      rejected: total_rejected,
    });
  }, [filteredUsers, totalCount]);
  return (
    <DraggableTable
      handleOpen={handleOpen}
      filteredUsers={filteredUsers.filter(
        (item) => item.reminder_status === "pending"
      )}
      loading={loading}
      baseColumns={columns}
      drag={true}
      defaultPageSize={defaultPageSize}
    />
  );
};

export default AQTable;
