import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./SalesTracker.scss";
import EditPopup from "../STeditPopup/STeditPopup";
// import EditPopup1 from "../STeditPopup/STeditPopup1";
import down from "../../../../Assets/EnquiresIcon/down.svg";
import filter from "../../../../Assets/EnquiresIcon/filter.svg";
import filteron from "../../../../Assets/EnquiresIcon/filteron.svg";
import greenDown from "../../../../Assets/EnquiresIcon/green_down.svg";
import _http from "../../../../Utils/Api/_http";
import FilterEnquires from "../Filter/FilterEnquires";
import { Popover, Space } from "antd";
import SnackBar from "../../../../Components/SnackBar";
import SizeChanger from "../../../../Components/PageSizeChanger/SizeChanger";
// import { rem } from "../../../../Components/Rem_func";
import { setdatacount } from "../../../../Redux/Reducers";
import { useDispatch } from "react-redux";
import EmailPopup from "../STeditPopup/EmailPopup";
import CrossIcon from "../../../../Components/Svg/CrossIcon";
import { rem } from "../../../../Components/Rem_func";
import searchIcon from "../../../../Assets/UserIcon/usersearchicon.svg";
import SalesHeader from "../../DashBoard/SalesHeader";
const SalesTracker = ({
  title,
  UserDataTable,
  api,
  AwaitingOrder,
  OrderPlaced,
  AwaitingQuotes,
  emailE,
  reports,
}) => {
  // State variables
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [loading, setLoading] = useState(false);
  const [openfilter, setOpenfilter] = useState(false);
  const [filteredData, setFilteredData] = useState({
    ack_number: "",
    sales_person_name: "",
    client_person_name: "",
    client_subject: "",
    client_email_from: "",
    client_email_to: "",
    sales_email_from: "",
    sales_email_to: "",
    ack_email_from: "",
    ack_email_to: "",
    quotation_email_from: "",
    quotation_email_to: "",
    order_date_from: "",
    order_date_to: "",
    min_quote_value: "",
    max_quote_value: "",
    min_order_value: "",
    max_order_value: "",
  });
  const [filters, setFilters] = useState({
    location: "",
    quick_filter: "Last week",
    year: "",
    quarter: "",
    currency: "",
  });
  const [searchData, setSeaarchData] = useState("");
  // const [updateBtn, setUpdateBtn] = useState(false);
  const [filterOn, setFilterOn] = useState(false);
  const [toast, setToast] = useState({ delete: false, error: false });
  const [errors, setError] = useState("");
  const dispatch = useDispatch();

  // Function to handle closing snackbar
  const handletoast = () => {
    setToast({ error: false, delete: false });
  };

  // const handleOrder = async (reference_number, updatedData, email) => {
  //   const formData = new FormData();
  //   formData.append("email", email);
  //   formData.append("jsondata", JSON.stringify(updatedData));
  //   setUpdateBtn(true);
  //   try {
  //     await _http.put(`/api/update/${reference_number}`, formData);
  //     setError("Successfully updated");
  //     setToast({ delete: true });
  //     handleClose();
  //     fetchData();
  //   } catch (error) {
  //     setToast({ error: true });
  //     setError(error.message);
  //   }
  //   setUpdateBtn(false);
  // };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const response = await _http.get(api);

      if (Array.isArray(response.data) && response.data.every(Array.isArray)) {
        const flattenedData = response.data.flat();

        setUsers(flattenedData.flat());
        dispatch(setdatacount(flattenedData?.flat()?.length));
      } else {
        setUsers(response.data);
        dispatch(setdatacount(response.data.length));
      }
    } catch (error) {
      setToast({ error: true });
      setError("Error fetching data:", error?.message);
      console.log(error);
    }
    setLoading(false);
  }, [api, dispatch]);
  // Function to compute date ranges based on quick filters
  const computeDateRange = (quickFilter) => {
    const now = new Date();
    let startDate;

    switch (quickFilter) {
      case "Yesterday":
        startDate = new Date(now.setDate(now.getDate() - 1));
        break;
      case "Last 3 days":
        startDate = new Date(now.setDate(now.getDate() - 3));
        break;
      case "Last week":
        startDate = new Date(now.setDate(now.getDate() - 7));
        break;
      case "Last 2 weeks":
        startDate = new Date(now.setDate(now.getDate() - 14));
        break;
      case "Last 3 weeks":
        startDate = new Date(now.setDate(now.getDate() - 21));
        break;
      case "Last month":
        startDate = new Date(now.setMonth(now.getMonth() - 1));
        break;
      case "Last 3 months":
        startDate = new Date(now.setMonth(now.getMonth() - 3));
        break;
      case "Last 6 months":
        startDate = new Date(now.setMonth(now.getMonth() - 6));
        break;
      case "Last 9 months":
        startDate = new Date(now.setMonth(now.getMonth() - 9));
        break;
      case "Last year":
        startDate = new Date(now.setFullYear(now.getFullYear() - 1));
        break;
      default:
        startDate = null;
    }

    return startDate;
  };
  const isWithinQuarter = (date, quarter) => {
    const month = date.getMonth() + 1; // JavaScript months are 0-based, so add 1

    switch (quarter) {
      case "Q1":
        return month >= 1 && month <= 3; // January to March
      case "Q2":
        return month >= 4 && month <= 6; // April to June
      case "Q3":
        return month >= 7 && month <= 9; // July to September
      case "Q4":
        return month >= 10 && month <= 12; // October to December
      default:
        return false;
    }
  };

  const filteredUsers = useMemo(() => {
    const {
      year,
      start_date,
      end_date,
      quarter,
      currency,
      location,
      quick_filter,
    } = filters;

    // If no filters are applied, return original users
    if (
      year.length === 0 &&
      !start_date &&
      !end_date &&
      quarter.length === 0 &&
      !currency &&
      !location &&
      !quick_filter
    ) {
      return users || [];
    }

    // Define common date and filter conditions
    return (
      (users || []).filter((item) => {
        const emailDateUTC =
          title !== "Email"
            ? item?.client_email_time_utc
            : item?.from_email_time;

        if (!emailDateUTC) {
          return false; // Exclude items with no email date
        }

        // Convert UTC email date to local time (proper handling)
        const emailDate = new Date(emailDateUTC);
        const localEmailDate = new Date(
          emailDate.getFullYear(),
          emailDate.getMonth(),
          emailDate.getDate(),
          emailDate.getHours(),
          emailDate.getMinutes(),
          emailDate.getSeconds()
        );

        const yearOfEmail = localEmailDate.getFullYear();

        // Year filter check
        const isYearIncluded =
          year.length > 0 ? year.includes(yearOfEmail) : true;

        // Date range checks
        const isAfterStartDate = start_date
          ? localEmailDate >= new Date(start_date)
          : true;
        const isBeforeEndDate = end_date
          ? localEmailDate <= new Date(end_date)
          : true;

        // Quarter filter check
        const isInQuarter =
          quarter.length > 0
            ? quarter.some((q) => isWithinQuarter(localEmailDate, q))
            : true;

        // Currency, branch, and quick filter checks
        const isCurrencyMatched = currency
          ? currency.includes(item?.currency)
          : true;

        const isLocationMatched = location
          ? location.includes(item?.location)
          : true;

        // Quick filter handling
        let quickFilterStartDate = quick_filter
          ? computeDateRange(quick_filter)
          : null;
        const isWithinQuickFilter = quickFilterStartDate
          ? localEmailDate >= quickFilterStartDate
          : true;

        // Return true if all conditions are met
        return (
          isYearIncluded &&
          isAfterStartDate &&
          isBeforeEndDate &&
          isInQuarter &&
          isCurrencyMatched &&
          isLocationMatched &&
          isWithinQuickFilter
        );
      }) || []
    );
  }, [filters, users, title]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Function to handle opening user details
  const handleOpen = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  // toast function for update
  const handleUpdateToast = (submit, error, message) => {
    setToast({
      submit: submit,
    });
    setToast({
      error: error,
    });
    setError(message);
  };

  // Function to handle closing user details
  const handleClose = () => setOpen(false);

  // Function to hide filter popover
  const hide = () => {
    setOpenfilter(false);
  };

  // Function to handle filter change
  const handleOpenChange = (newOpen) => {
    setOpenfilter(newOpen);
  };

  // Function to check if any filters are applied
  const checkIfFiltersAreApplied = (filters) => {
    return Object.values(filters).some((value) => value !== "");
  };

  // Update filterOn whenever filteredData changes
  useEffect(() => {
    setFilterOn(checkIfFiltersAreApplied(filteredData));
  }, [filteredData]);

  const renderEmptyOrValue = (text, TT, cln, Type) => {
    const dateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

    // Check if text matches the date-time format
    const isValidDateTime = dateTimePattern.test(text);

    // Return 'DataNR' if text is falsy
    if (!text) {
      return <span className="empty">--</span>;
    }

    // Handle date or time types
    if (Type === "date" || Type === "time") {
      if (isValidDateTime) {
        const [datePart, timePart] = new Date(text)
          ?.toLocaleString()
          ?.split(", ");
        return <span>{Type === "date" ? datePart : timePart}</span>;
      } else {
        return <span>MismatchF</span>; // Return this if the format does not match
      }
    }

    // Default case: Return the text with optional class and capitalization
    return (
      <span
        className={`${cln} w100 overflowData`}
        style={{ textTransform: TT ? "capitalize" : "none" }}
      >
        {text}
      </span>
    );
  };
  const DateSorter = (dataIndex) => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    const dateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/; // YYYY-MM-DD HH:MM:SS format

    return (a, b) => {
      // Get the date strings from the respective dataIndex
      const dateA = a[dataIndex];
      const dateB = b[dataIndex];

      // Check if the date format is valid (either datePattern or dateTimePattern)
      const isValidDateA =
        datePattern.test(dateA) || dateTimePattern.test(dateA);
      const isValidDateB =
        datePattern.test(dateB) || dateTimePattern.test(dateB);

      if (isValidDateA && isValidDateB) {
        // Both dates are valid, compare them as Date objects
        return new Date(dateA) - new Date(dateB);
      } else if (isValidDateA) {
        // Only dateA is valid, so it comes before
        return 1;
      } else if (isValidDateB) {
        // Only dateB is valid, so it comes before
        return -1;
      } else {
        // Neither date is valid, treat them as equal
        return 0;
      }
    };
  };

  const [count, setCount] = useState("50");
  const [totalcount, setTotalCount] = useState("0");
  const PageCount = ["10", "20", "50", "100"];

  const items = PageCount.map((page) => ({
    key: page,
    label: (
      <div
        style={{ width: "100%" }}
        className="pageCount"
        onClick={() => setCount(page)}
      >
        {page}
      </div>
    ),
  }));
  // Handle removing a filter
  // Handle removing a filter
  const removeThisFilter = (key) => {
    setFilteredData((prev) => {
      const updatedFilters = { ...prev, [key]: "" }; // Clear the filter
      console.log(updatedFilters); // This will log the updated state
      return updatedFilters;
    });
  };

  // Handle input change with regex check
  const handleInputChange = (e) => {
    const { value } = e.target;
    const pattern = /^[A-Za-z0-9\s]*$/;

    if (pattern.test(value)) {
      // Normalize spaces to a single space
      const normalizedValue = value?.replace(/\s+/g, " ").trim();

      // Update filteredData for specific keys based on user input
      setSeaarchData(normalizedValue);
    }
  };

  // JSX rendering
  return (
    <section>
      <div className={`container1`}>
        <div
          style={{
            paddingBottom: rem(4),
            // paddingTop: title === "Email" ? rem(53) : {},
          }}
        >
          {/* {title !== "Email" && ( */}
          <SalesHeader
            setFilters={setFilters}
            filters={filters}
            title={title}
            // filteredData={filteredData}
            setFilteredData={setFilteredData}
          />
          {/* )} */}
        </div>
        <div className="container-head df">
          <p className="title">{title}</p>
          <div className="total-count-box">{totalcount}</div>
        </div>
        <div className="container-head2 df">
          <SizeChanger count={count} items={items} />
          <div className="df" style={{ gap: rem(16) }}>
            <div className="user-search df">
              <img src={searchIcon} alt="icon" />
              <input
                // type="text"
                placeholder="Search..."
                value={searchData || ""}
                onChange={handleInputChange}
                name="user"
              />
            </div>
            {title !== "Email" && (
              <Popover
                trigger="click"
                open={openfilter}
                placement="bottomRight"
                onOpenChange={handleOpenChange}
                content={
                  <FilterEnquires
                    hide={hide}
                    filteredData={filteredData}
                    setFilteredData={setFilteredData}
                    title={title}
                    setFilters={setFilters}
                  />
                }
              >
                <Space>
                  <span
                    className="filter-head"
                    data-tooltip-id="tooltip-arrow"
                    data-tooltip-content="Filter"
                  >
                    <img src={filterOn ? filteron : filter} alt="icon" />{" "}
                    <img src={filterOn ? greenDown : down} alt="icon" />
                  </span>
                </Space>
              </Popover>
            )}
          </div>
        </div>

        <div className="df filter-section">
          {filterOn && filteredData && (
            <div
              className="filtered-data-item df"
              style={{ paddingBottom: rem(6) }}
            >
              {Object.entries(filteredData).map(
                ([key, value]) =>
                  !Array.isArray(value) &&
                  value && (
                    <span key={key} className="df filtered-option">
                      <span className="filtered-value">
                        {key
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase())}
                        :{value || "N/A"}
                      </span>
                      <span
                        onClick={() => removeThisFilter(key)}
                        style={{ cursor: "pointer" }}
                      >
                        <CrossIcon />
                      </span>
                    </span>
                  )
              )}
            </div>
          )}
        </div>
        <UserDataTable
          handleOpen={handleOpen}
          users={filteredUsers}
          filteredData={filteredData}
          loading={loading}
          renderEmptyOrValue={renderEmptyOrValue}
          DateSorter={DateSorter}
          defaultPageSize={count}
          totalCount={setTotalCount}
          searchData={searchData}
        />
        {open && selectedUser && !emailE && (
          <EditPopup
            open={open}
            user={selectedUser}
            handleClose={handleClose}
            // updatingFun={updateBtn}
            handleUpdateToast={handleUpdateToast}
            AwaitingQuotes={AwaitingQuotes}
            // handleOrder={handleOrder}
            AwaitingOrder={AwaitingOrder}
            reports={reports}
          />
        )}
        {open && selectedUser && emailE && (
          <EmailPopup
            open={open}
            user={selectedUser}
            handleClose={handleClose}
          />
        )}
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast.delete}
      />
    </section>
  );
};

export default SalesTracker;
