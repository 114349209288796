import React from "react";
import SalesTracker from "../STcomponents/SalesTracker/SalesTracker";
import AQTable from "./AQTable";
const AwaitingQuotes = () => {
  return (
    <>
      <SalesTracker
        title={"Awaiting Quote"}
        UserDataTable={AQTable}
        api={"/api/users_db"}
        AwaitingQuotes={true}
      />
    </>
  );
};

export default AwaitingQuotes;
