import React, { useRef, useState } from "react";
import "./QuizDocs.scss";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import _http from "../../../Utils/Api/_http";
import SnackBar from "../../../Components/SnackBar";
import { Tooltip } from "antd";
import QuizShare from "../QuizShare";
import share from "../../../Assets/Quiz/Share.svg";
import download from "../../../Assets/Quiz/Download.svg";
import searchIcon from "./../../../Assets/UserIcon/usersearchicon.svg";
import upload from "./../../../Assets/Quiz/upload.svg";
// import Video from "./../../../Assets/Quiz/QreCat&Photo/Video.svg"

export default function QuizDocs() {
  const navigate = useNavigate();
  const { section } = useParams();
  const [errors, setError] = useState("");
  const [toast, setToast] = useState({ submit: false, error: false });
  const [qrcode, setQrcode] = useState(false);
  const fileRef = useRef(null);
  const [qrpng, setQrPng] = useState(null);
  const [Value, setValue] = useState();
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };
  const navigateHandler = (section) => {
    navigate(`/SpiraQuiz/${section}`);
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log("Dropped files:", files);
    uploadMedia(files);
  };
  const handleFileUpload = () => {
    fileRef.current.click();
  };
  function GenerateLink(e) {
    const value = e.target.value;
    setValue(value);
    if (value?.length !== "") {
      setActive(true);
    } else {
      setValue("");
      setToast({ errors: true });
      setError("Can't Submit Empty ⚠️");
    }
  }
  const LinkConvertorQr = async (val) => {
    if (section === "catalogue") {
      try {
        const response = await _http.post(
          "/quiz/OnlineLink_to_QR",
          {
            links: val,
          },
          { responseType: "blob" }
        );
        const url = URL.createObjectURL(response.data);
        setQrPng(url);
        setQrcode(true);
        setValue("");
        setTimeout(() => {
          setError("Qr Code Generated");
          setToast({ submit: true });
        }, 1000);
      } catch (er) {
        console.log(er);
      }
    } else {
      try {
        const response = await _http.post(
          "/quiz/OnlineLink_to_QR",
          {
            links: val,
          },
          { responseType: "blob" }
        );
        const url = URL.createObjectURL(response.data);
        setQrPng(url);
        setQrcode(true);
        setValue("");
        setTimeout(() => {
          setError("Qr Code Generated");
          setToast({ submit: true });
        }, 1000);
      } catch (er) {
        console.log(er);
      }
    }
  };
  function handleSubmit() {
    if (Value?.length >= 10) {
      LinkConvertorQr(Value);
    } else {
      setError("Link Value Should be 10 Charactors");
      setToast({ errors: true });
    }
  }
  const uploadMedia = async (file) => {
    if (!file) {
      setToast({ error: true });
      setError("Please select a file");
      setLoading(false);
      // setIsDropped(false);
    } else {
      setLoading(true);
      if (section === "catalogue") {
        try {
          const formData = new FormData();
          formData.append("video", file[0]);
          const response = await _http.post("/quiz/uploadVideoS3", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log(response.data);
        } catch (error) {
          setToast({ error: true });
          setError("Invalid File ⚠️");
        }
      } else {
        try {
          const formData = new FormData();
          formData.append("file", file[0]);
          const response = await _http.post("/quiz/uploadImageS3", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log(response.data);
        } catch (error) {
          setToast({ error: true });
          setError("Invalid File ⚠️");
        }
      }

      setLoading(false);
    }
  };

  return (
    <section className="parent_container">
      {loading ? (
        <div className="spinners">
          {/* <img src={Animation} draggable="false" alt="" /> */}
        </div>
      ) : (
        <>
          <div className="upper_one">
            <div className="left_upper">
              <p className="docs_title">QRE Configure</p>
              <span className="q-btns">
                <button
                  className="q-btn1"
                  onClick={() => navigate("/SpiraQuiz")}
                >
                  Quiz
                </button>
                <button
                  className={`q-btn1 ${
                    section === "catalogue" ? "main_btn" : ""
                  }`}
                  onClick={() => navigateHandler("catalogue")}
                >
                  Catalogue
                </button>
                <button
                  className={`q-btn1 ${section === "photo" ? "main_btn" : ""}`}
                  onClick={() => navigateHandler("photo")}
                >
                  Photo
                </button>
              </span>
            </div>
            <div className="middle_upper">
              <p
                className="dropdown_sty"
                onDragEnter={handleDragEnter}
                onDragOver={handleDragEnter}
                onDrop={handleDrop}
                onClick={handleFileUpload}
              >
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept={`${section === "photo" ? ".png,.jpeg" : ".mp4"}`}
                  onChange={(e) => {
                    uploadMedia(e.target.files);
                  }}
                  ref={fileRef}
                />
                <img src={upload} alt="up-svg" className="up-svg" />
                <span className="file-name">Drop or Browser Files</span>
              </p>

              <p className="file_size">
                {` Upto ${
                  section === "catalogue" ? "125MB" : "25MB"
                }. File name without and special character `}
              </p>
            </div>
            <div className="right_upper">
              <p>{section === "catalogue" ? "Video Link" : "Image Link"}</p>
              <div className="video_input">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M15.8174 7.01643C16.6081 6.21675 16.6119 4.98241 15.8207 4.19123C15.0295 3.39995 13.7856 3.39659 12.9968 4.19464L11.8911 5.26595C11.1246 6.02541 9.99473 4.85748 10.7792 4.11652L11.8661 3.06393C12.5631 2.35881 13.4845 2.00907 14.4078 2.00977C15.3311 2.01043 16.2552 2.36175 16.9542 3.06079C18.3524 4.45888 18.3437 6.73888 16.9575 8.14105C16.9551 8.14169 16.9536 8.14331 16.9512 8.1441L12.9313 12.153C11.6634 13.4173 9.67173 13.546 8.2649 12.5106C7.4113 11.8778 8.35473 10.5956 9.21286 11.2222C9.99536 11.798 11.0833 11.7375 11.8022 11.0207L15.8174 7.01643Z"
                    fill="#3D3D3D"
                  />
                  <path
                    d="M4.18121 12.9837C3.39068 13.7834 3.39004 15.0146 4.18121 15.8058C4.97242 16.5973 6.21441 16.6053 7.00245 15.8072L8.11205 14.739C8.87473 13.8783 10.106 15.1476 9.22243 15.8837L8.13548 16.9349C6.74165 18.3452 4.44551 18.339 3.0474 16.9409C1.64893 15.5428 1.65492 13.2659 3.04105 11.8637C3.04347 11.8607 3.045 11.8592 3.0474 11.8576L7.06726 7.84869C7.70121 7.21659 8.51995 6.86468 9.35205 6.80394C10.1842 6.74331 11.0334 6.97184 11.7368 7.48956C12.6391 8.11646 11.649 9.45442 10.7857 8.77484C10.0032 8.19904 8.91997 8.25951 8.20107 8.97636L4.18121 12.9837Z"
                    fill="#3D3D3D"
                  />
                </svg>
                <input
                  type="text"
                  placeholder="Paste the link here"
                  value={Value}
                  className="inp_vid"
                  onChange={GenerateLink}
                />
              </div>
            </div>
          </div>
          <div className="below_one">
            <div className="left">
              <div className="nav">
                <div className="left_nav">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <rect
                      x="0.714286"
                      y="1.21429"
                      width="18.5714"
                      height="18.5714"
                      rx="9.28571"
                      stroke="#A2B7AE"
                      stroke-width="1.42857"
                    />
                    <path
                      d="M9.38831 11.1128H5.71484V9.88831H9.38831V6.21484H10.6128V9.88831H14.2863V11.1128H10.6128V14.7863H9.38831V11.1128Z"
                      fill="#1E6641"
                    />
                  </svg>
                  <span className="folder_title">Add New Folder</span>
                </div>
                <div className="right_nav">
                  <div className="user-search df">
                    <input
                      type="text"
                      placeholder="Search"
                      name="user"
                      value=""
                    />
                    <img src={searchIcon} alt="icon" />
                  </div>
                </div>
              </div>
              {/* <div className="folder_section">
                <div className="medias">
                  <div className="mediatitle">
                    <img src={Video} alt="" />
                    filename
                  </div>
                  <div className="mediaside">
                    date
                    delete
                  </div>
                </div>
              </div> */}
            </div>
            <div className="right">
              <button
                className={` ${active ? "active_generate_qr" : "generate_qr"}`}
                onClick={handleSubmit}
              >
                Generate QR Code
              </button>
              {qrcode && (
                <div className="qrcatlogmain">
                  <img src={qrpng} alt="QR Code" className="qrpngsclass" />
                  <div className="quizsocialcatlog">
                    <Tooltip
                      title={<QuizShare />}
                      placement={"bottom"}
                      color="#fff"
                    >
                      <img src={share} alt="" className="socialbtncatolog" />
                    </Tooltip>
                    <a
                      href={qrpng}
                      download={`QRCode_Link.png`}
                      onClick={(e) => {
                        const linker = document.createElement("a");
                        linker.href = qrpng;
                        linker.download = `QRCode_Link.png`;
                        linker.click();
                      }}
                    >
                      <img src={download} alt="" className="socialbtncatolog" />
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <SnackBar
            message={errors}
            severity={"error"}
            handleClose={handletoast}
            Open={toast?.error}
          />
          <SnackBar
            message={errors}
            severity={"success"}
            handleClose={handletoast}
            Open={toast?.submit}
          />
        </>
      )}
    </section>
  );
}
