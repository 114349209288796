import React from "react";

const SalesRange = ({ salesrange, formatNumber }) => {
  const conversationPercentAgainstEnquires =
    (salesrange.conversion.orderCount / salesrange.conversion.ackCount) * 100 ||
    0;

  const conversationPercentAgainstQuoted =
    (salesrange.conversion.orderCount / salesrange.conversion.quoteCount) *
      100 || 0;

  // Calculate strokeDasharray values for SVG based on percentage
  const calcStrokeDasharray = (percent) => {
    const fixedPercent = Math.max(0, Math.min(percent, 100)); // Ensure percent is between 0 and 100
    const dashValue = (fixedPercent / 100) * 50; // 50 is half of the circle
    return `${dashValue} ${51 - dashValue}`;
  };

  return (
    <div className="sales-range df">
      <div className="range-box flexBox">
        <div className="box df">
          <span className="label"># of Enquiries</span>
          <span className="value">{salesrange?.enquiries?.AED || 0}</span>
        </div>
        <div className="box df">
          <span className="label">Quoted (AED)</span>
          <span className="value">
            {formatNumber(salesrange?.quote?.AED) || 0}
          </span>
        </div>
        <div className="box df">
          <span className="label">Awarded (AED)</span>
          <span className="value">
            {formatNumber(salesrange?.awarded?.AED) || 0}
          </span>
        </div>
        <div className="box df">
          <span className="label"># of Enquiries</span>
          <span className="value">{salesrange?.enquiries?.USD || 0}</span>
        </div>
        <div className="box df">
          <span className="label">Quoted (USD)</span>
          <span className="value">
            {formatNumber(salesrange?.quote?.USD) || 0}
          </span>
        </div>
        <div className="box df">
          <span className="label">Awarded (USD)</span>
          <span className="value">
            {" "}
            {formatNumber(salesrange?.awarded?.USD) || 0}
          </span>
        </div>
        <div className="box df">
          <span className="label"># of Enquiries</span>
          <span className="value">{salesrange?.enquiries?.OMR || 0}</span>
        </div>
        <div className="box df">
          <span className="label">Quoted (OMR)</span>
          <span className="value">
            {formatNumber(salesrange?.quote?.OMR) || 0}
          </span>
        </div>
        <div className="box df">
          <span className="label">Awarded (OMR)</span>
          <span className="value">
            {" "}
            {formatNumber(salesrange?.awarded?.OMR) || 0}
          </span>
        </div>
        <div className="box df">
          <span className="label">Conversation % against Enquiries</span>
          <div className="range-bar">
            <div className="semi-circular-progress">
              <svg viewBox="0 0 36 18" className="semi-circular-chart">
                <path
                  className="semi-circle-bg"
                  d="M18 18 m -16 0 a 16 16 0 0 1 32 0"
                  stroke="#E0E7ED"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <path
                  className="semi-circle"
                  strokeDasharray={calcStrokeDasharray(
                    conversationPercentAgainstEnquires
                  )}
                  d="M18 18 m -16 0 a 16 16 0 0 1 32 0"
                  stroke="#1E6641"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <text
                  x="18"
                  y="12"
                  textAnchor="middle" // Align center horizontally
                  fontSize="6"
                  dominantBaseline={"mathematical"}
                >
                  {`${conversationPercentAgainstEnquires?.toFixed(2)}%`}
                </text>
              </svg>
            </div>
          </div>
        </div>
        <div className="box df">
          <span className="label">Conversation % against Quoted</span>
          <div className="range-bar">
            <div className="semi-circular-progress">
              <svg viewBox="0 0 36 18" className="semi-circular-chart">
                <path
                  className="semi-circle-bg"
                  d="M18 18 m -16 0 a 16 16 0 0 1 32 0"
                  stroke="#E0E7ED"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <path
                  className="semi-circle"
                  strokeDasharray={calcStrokeDasharray(
                    conversationPercentAgainstQuoted
                  )}
                  d="M18 18 m -16 0 a 16 16 0 0 1 32 0"
                  stroke="#1E6641"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <text
                  x="18"
                  y="12"
                  textAnchor="middle" // Align center horizontally
                  fontSize="6"
                  dominantBaseline={"mathematical"}
                >
                  {`${conversationPercentAgainstQuoted?.toFixed(2)}%`}
                </text>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesRange;
