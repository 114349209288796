import axios from "axios";
import { jwtDecode } from "jwt-decode";
import _nonAuthHttp from "./_nonAuthHttp";

const _http = axios.create({
  // baseURL: "https://spira.admin.dtskill.com",
  // baseURL: "http://127.0.0.1:8000",
   baseURL: "https://spira-qaapi.dtskill.com",
 // baseURL: "https://spira-dev-api.dtskill.com",
});

// Function to check if token is expired
const isTokenExpired = (token) => {
  if (!token) return true;

  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  // Check if the token has expired
  return decoded.exp < currentTime;
};
// Add a request interceptor to include the JWT token in the headers
_http.interceptors.request.use(
  async (config) => {
    let token = sessionStorage.getItem("SpirajwtToken");

    if (isTokenExpired(token)) {
      try {
        const response = await _nonAuthHttp.post(`/token/refresh/`, {
          refresh: sessionStorage.getItem("refreshToken"),
        });
        const newAccessToken = response.data.access_token;

        sessionStorage.setItem("refreshToken", response.data.refresh);
        sessionStorage.setItem("SpirajwtToken", response.data.access);
        token = newAccessToken;
      } catch (error) {
        if (error.message === "Network Error") {
          window.location.href = "/Login";
        }

        return Promise.reject(error);
      }
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
_http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to an expired token
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = sessionStorage.getItem("refreshToken");

      // Call the refresh token API
      try {
        const response = await _nonAuthHttp.post(
          "/token/refresh/",
          {
            refresh: sessionStorage.getItem("refreshToken"),
          },
          {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          }
        );

        const newAccessToken = response.data.access_token;

        sessionStorage.setItem("refreshToken", response.data.refresh);
        sessionStorage.setItem("SpirajwtToken", response.data.access);

        // Update the Authorization header and retry the original request
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

        return _http(originalRequest);
      } catch (error) {
        console.log(error);
        if (error.message === "Network Error") {
          window.location.href = "/Login";
        }
        // window.location.href = "/signin";

        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);
export default _http;
