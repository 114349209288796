// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-password {
  overflow-y: hidden;
}
.change-password .login-remove-border {
  border: none !important;
  overflow-y: hidden;
}
.change-password .login-card {
  padding-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/UserProfile/ChangePassword.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,uBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,cAAA;AAEJ","sourcesContent":[".change-password {\n  overflow-y: hidden;\n  .login-remove-border {\n    border: none !important;\n    overflow-y: hidden;\n  }\n  .login-card {\n    padding-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
