import React, { useMemo, useEffect } from "react";
import { rem } from "../../../Components/Rem_func";
import DraggableTable from "../../../Components/TableDrag_func";

const AQTable = ({
  handleOpen,
  users,
  filteredData,
  loading,
  renderEmptyOrValue,
  totalCount,
  defaultPageSize,
  DateSorter,
  searchData,
  filters,
}) => {
  // useMemo hook to filter and format user data based on filter criteria
  const filteredUsers = useMemo(() => {
    return (users || [])
      .filter((user) => {
        const convertToLocalDate = (utcDateString) => {
          if (!utcDateString) return null;
          const utcDate = new Date(utcDateString);
          return new Date(
            utcDate.getFullYear(),
            utcDate.getMonth(),
            utcDate.getDate(),
            utcDate.getHours(),
            utcDate.getMinutes(),
            utcDate.getSeconds()
          );
        };
        // Filter based on filteredData
        const matchesFilteredData =
          (user?.new_ref_num || "")
            .toLowerCase()
            .includes((filteredData?.ack_number || "").toLowerCase()) &&
          (user?.client_person_name || "")
            .toLowerCase()
            .includes((filteredData?.client_person_name || "").toLowerCase()) &&
          (user?.sales_person_name || "")
            .toLowerCase()
            .includes((filteredData?.sales_person_name || "").toLowerCase()) &&
          (user?.client_subject || "")
            .toLowerCase()
            .includes((filteredData?.client_subject || "").toLowerCase()) &&
          (filteredData?.client_email_from
            ? convertToLocalDate(user?.client_email_time_utc) >=
              new Date(filteredData?.client_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.client_email_to
            ? convertToLocalDate(user?.client_email_time_utc) <=
              new Date(filteredData?.client_email_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData?.sales_email_from
            ? convertToLocalDate(user?.sales_email_time_utc) >=
              new Date(filteredData?.sales_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.sales_email_to
            ? convertToLocalDate(user?.sales_email_time_utc) <=
              new Date(filteredData?.sales_email_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData?.ack_email_from
            ? convertToLocalDate(user?.ack_time_utc) >=
              new Date(filteredData?.ack_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.ack_email_to
            ? convertToLocalDate(user?.ack_time_utc) <=
              new Date(filteredData?.ack_email_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData?.quotation_email_from
            ? convertToLocalDate(user?.quotation_time_utc) >=
              new Date(filteredData?.quotation_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.quotation_email_to
            ? convertToLocalDate(user?.quotation_time_utc) <=
              new Date(filteredData?.quotation_email_to).setHours(
                23,
                59,
                59,
                999
              )
            : true) &&
          (filteredData?.order_date_from
            ? convertToLocalDate(user?.final_order_time) >=
              new Date(filteredData?.order_date_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.order_date_to
            ? convertToLocalDate(user?.final_order_time) <=
              new Date(filteredData?.order_date_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData?.max_quote_value
            ? user?.currency_value
              ? Number(filteredData?.max_quote_value) >=
                Number(user?.currency_value?.replace(/,/g, ""))
              : false
            : true) &&
          (filteredData?.reject_date_from
            ? convertToLocalDate(user?.rejection_date_time) >=
              new Date(filteredData?.reject_date_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.reject_date_to
            ? convertToLocalDate(user?.rejection_date_time) <=
              new Date(filteredData?.reject_date_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData?.min_quote_value
            ? user?.currency_value
              ? Number(filteredData?.min_quote_value) <=
                Number(user?.currency_value?.replace(/,/g, ""))
              : false
            : true) &&
          (filteredData?.max_order_value
            ? user?.final_order_currency
              ? Number(filteredData?.max_order_value) >=
                Number(user?.final_order_currency?.replace(/,/g, "") || 0)
              : false
            : true) &&
          (filteredData?.min_order_value
            ? user?.final_order_currency
              ? Number(filteredData?.min_order_value) <=
                Number(user?.final_order_currency?.replace(/,/g, ""))
              : false
            : true);
        // (filteredData.)

        // Search logic: check if any field contains the searchData string
        const matchesSearchData = searchData
          ? (user?.new_ref_num || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_subject || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_mail || "")
              .toLowerCase()
              .includes(searchData.toLowerCase())
          : true;

        const SalesPersonName = filters?.sales_team
          ? (user?.sales_person_name || "")
              .toLowerCase()
              .includes((filters?.sales_team || "").toLowerCase())
          : true;
        return matchesFilteredData && matchesSearchData && SalesPersonName;
      })
      .map((user) => {
        // Highlight search matches in relevant fields
        const matchReferenceNumber =
          searchData &&
          user?.new_ref_num?.toLowerCase().includes(searchData?.toLowerCase());

        const matchClientPersonName =
          searchData &&
          user?.client_person_name
            ?.toLowerCase()
            .includes(searchData?.toLowerCase());

        const matchSalesPersonName =
          searchData &&
          user?.sales_person_name
            ?.toLowerCase()
            .includes(searchData?.toLowerCase());

        const matchClientSubject =
          searchData &&
          user?.client_subject
            ?.toLowerCase()
            .includes(searchData?.toLowerCase());

        return {
          ...user, // Return all original user data
          new_ref_num_class: matchReferenceNumber ? "highlighted-text" : "",
          client_person_name_class: matchClientPersonName
            ? "highlighted-text"
            : "",
          sales_person_name_class: matchSalesPersonName
            ? "highlighted-text"
            : "",
          client_subject_class: matchClientSubject ? "highlighted-text" : "",
        };
      });
  }, [users, filteredData, searchData, filters]);

  // Configuration for table columns
  const columns = [
    {
      title: "Sales Team Member",
      dataIndex: "sales_person_name",
      key: "sales_person_name",
      className: "",
      sorter: (a, b) =>
        (a.sales_person_name || "").localeCompare(b.sales_person_name || ""),
      width: rem(136),
      render: (text, record) =>
        renderEmptyOrValue(
          text?.toLowerCase(),
          true,
          record.sales_person_name_class
        ),
    },
    {
      title: `Client/ Buyer Enquiry Date/Time`,
      dataIndex: "client_email_time_utc",
      key: "client_email_time_utc",
      className: "noPadding",
      width: rem(144),
      sorter: DateSorter("client_email_time_utc"),
      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),
    },

    {
      title: "Client / Buyer POC",
      dataIndex: "client_person_name",
      key: "client_person_name",
      width: rem(134),
      className: "noPadding tl",

      render: (text, record) => (
        <span className="df flexColumn align-start ">
          {renderEmptyOrValue(text, false, record.client_person_name_class)}

          {renderEmptyOrValue(
            record.client_email ? record.client_email.split("<")[0] : "", // Extract email up to '<' character
            false,
            record.client_email_class
          )}
        </span>
      ),
      sorter: (a, b) =>
        (a.client_person_name || "").localeCompare(b.client_person_name || ""),
    },
    {
      title: "Mail Subject",
      dataIndex: "client_subject",
      key: "client_subject",
      width: rem(164),
      className: "tl",
      render: (text, record) =>
        renderEmptyOrValue(text, "", record.client_subject_class),
      sorter: (a, b) =>
        (a.client_subject || "").localeCompare(b.client_subject || ""),
    },
    {
      title: `SalesP Frwd to AI Date/Time`,
      dataIndex: "sales_email_time_utc",
      key: "sales_email_time_utc",
      className: "noPadding",
      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),
      width: rem(134),
      sorter: DateSorter("sales_email_time_utc"),
    },

    {
      title: `AI ACK to SalesP Date/Time`,
      dataIndex: "ack_time_utc",
      key: "ack_time_utc",
      className: "noPadding",
      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),
      width: rem(134),
      sorter: DateSorter("ack_time_utc"),
    },

    {
      title: `SalesP Frwd Quote AI Date/Time`,
      dataIndex: "quotation_time_utc",
      key: "quotation_time_utc",
      className: "noPadding",
      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),
      width: rem(144),
      sorter: DateSorter("quotation_time_utc"),
    },

    {
      title: `SalesP to Order Date`,
      dataIndex: "final_order_time",
      key: "final_order_time",
      className: "noPadding",
      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),
      width: rem(134),
      sorter: DateSorter("final_order_time"),
    },

    {
      title: "SalesP Final Order Value",
      dataIndex: "final_order_currency",
      key: "final_order_currency",
      sorter: (a, b) => {
        const valueA = parseFloat(a.final_order_currency) || 0;
        const valueB = parseFloat(b.final_order_currency) || 0;
        return valueA - valueB;
      },
      width: rem(134),
      render: (text) => renderEmptyOrValue(text),
    },
  ];
  useEffect(() => {
    const total_Pending = filteredUsers.filter(
      (item) => item.reminder_status === "pending"
    ).length;
    const total_Count = filteredUsers.length;
    const order_placed = filteredUsers?.filter(
      (item) => item?.reminder_status === "order_placed"
    ).length;

    const total_success = filteredUsers?.filter(
      (item) => item?.reminder_status === "success"
    ).length;

    const total_rejected = filteredUsers?.filter(
      (item) => item?.reminder_status === "rejected"
    ).length;

    totalCount({
      total: total_Count,
      page_count: total_Count,
      pending: total_Pending,
      success: total_success,
      order_placed: order_placed,
      rejected: total_rejected,
    });
  }, [filteredUsers, totalCount]);
  return (
    <DraggableTable
      handleOpen={handleOpen}
      filteredUsers={filteredUsers}
      loading={loading}
      baseColumns={columns}
      drag={true}
      defaultPageSize={defaultPageSize}
    />
  );
};

export default AQTable;
