import React from "react";
import { Bar } from "react-chartjs-2";
import { rem } from "../../../Components/Rem_func";
// import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  //   ChartDataLabels,
  Tooltip,
  Legend
);

const QuotedResponse = ({ users }) => {
  const categorizedOrders = users.reduce(
    (acc, order) => {
      // Convert client_email_time and quotation_time to Date objects
      // Convert client_email_time_utc and quotation_time_utc to Date objects in UTC
      const quotationTimeUTC = new Date(order?.quotation_time_utc);

      const clientEmailUTC = new Date(order?.client_email_time_utc);

      // Convert the UTC times to local times
      const clientEmailLocal = new Date(
        clientEmailUTC.getFullYear(),
        clientEmailUTC.getMonth(),
        clientEmailUTC.getDate(),
        clientEmailUTC.getHours(),
        clientEmailUTC.getMinutes(),
        clientEmailUTC.getSeconds()
      );

      const quotationTimeLocal = new Date(
        quotationTimeUTC.getFullYear(),
        quotationTimeUTC.getMonth(),
        quotationTimeUTC.getDate(),
        quotationTimeUTC.getHours(),
        quotationTimeUTC.getMinutes(),
        quotationTimeUTC.getSeconds()
      );

      // Calculate the time difference in milliseconds
      const timeDifferenceMs = clientEmailLocal - quotationTimeLocal;

      // Convert time difference into minutes
      const timeDifferenceMinutes = Math.abs(timeDifferenceMs / 1000 / 60); // Convert ms to minutes

      // Categorize based on the time difference
      if (timeDifferenceMinutes <= 60) {
        acc["lessThan60Mins"].push(order);
      } else if (timeDifferenceMinutes > 60 && timeDifferenceMinutes <= 120) {
        acc["oneToTwoHours"].push(order);
      } else if (timeDifferenceMinutes > 120 && timeDifferenceMinutes <= 240) {
        acc["twoToFourHours"].push(order);
      } else if (timeDifferenceMinutes > 240 && timeDifferenceMinutes <= 480) {
        acc["fourToEightHours"].push(order);
      } else if (timeDifferenceMinutes > 480 && timeDifferenceMinutes <= 2880) {
        acc["oneToTwoDays"].push(order);
      } else {
        acc["moreThanTwoDays"].push(order);
      }

      return acc;
    },
    {
      lessThan60Mins: [],
      oneToTwoHours: [],
      twoToFourHours: [],
      fourToEightHours: [],
      oneToTwoDays: [],
      moreThanTwoDays: [],
    }
  );

  const conversionPercentages = [
    // For lessThan60Mins category
    (
      (categorizedOrders?.lessThan60Mins?.filter(
        (data) => data?.reminder_status === "order_placed"
      ).length /
        categorizedOrders?.lessThan60Mins.length) *
        100 || 0
    )?.toFixed(2),

    // For oneToTwoHours category
    (
      (categorizedOrders?.oneToTwoHours?.filter(
        (data) => data?.reminder_status === "order_placed"
      ).length /
        categorizedOrders?.oneToTwoHours.length) *
        100 || 0
    )?.toFixed(2),

    // For twoToFourHours category
    (
      (categorizedOrders?.twoToFourHours?.filter(
        (data) => data?.reminder_status === "order_placed"
      ).length /
        categorizedOrders?.twoToFourHours.length) *
        100 || 0
    )?.toFixed(2),

    // For fourToEightHours category
    (
      (categorizedOrders?.fourToEightHours?.filter(
        (data) => data?.reminder_status === "order_placed"
      ).length /
        categorizedOrders?.fourToEightHours.length) *
        100 || 0
    )?.toFixed(2),

    // For oneToTwoDays category
    (
      (categorizedOrders?.oneToTwoDays?.filter(
        (data) => data?.reminder_status === "order_placed"
      ).length /
        categorizedOrders?.oneToTwoDays.length) *
        100 || 0
    )?.toFixed(2),

    // For moreThanTwoDays category
    (
      (categorizedOrders?.moreThanTwoDays?.filter(
        (data) => data?.reminder_status === "order_placed"
      ).length /
        categorizedOrders?.moreThanTwoDays.length) *
        100 || 0
    )?.toFixed(2),
  ];

  const data = {
    labels: [
      ["Less", "than 60", " mins"], // Word broken into two lines
      ["1-2 hrs"],
      ["2-4 hrs"],
      ["4-8 hrs"],
      ["1-2 days"],
      ["Over", "2 days"],
    ],

    datasets: [
      {
        type: "line",
        // label: "Opportunity ",
        data: conversionPercentages || 0,
        borderWidth: 2,
        borderColor: "#878787",
        PointBorderColor: "#E0E7ED",
        pointBackgroundColor: "#FFF",
        pointBorderColor: "#FFF",
        pointRadius: 3,
        fill: false,
        order: 1,
        yAxisID: "y1",
      },
      {
        type: "bar",
        label: "Quoted Value AED",
        data: [
          categorizedOrders.lessThan60Mins.length || 0,
          categorizedOrders.oneToTwoHours.length || 0,
          categorizedOrders.twoToFourHours.length || 0,
          categorizedOrders.fourToEightHours.length || 0,
          categorizedOrders.oneToTwoDays.length || 0,
          categorizedOrders.moreThanTwoDays.length || 0,
        ],
        backgroundColor: "#558B70",
        // backgroundColor: "#E0E7ED",
        // hoverBackgroundColor: "#558B70",
        barPercentage: 0.4,
        borderRadius: 10,
        order: 2,
        yAxisID: "y",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 8,
          },
          color: "#A3AED0",
          align: "middle", // Aligns the tick labels cross-wise
          maxRotation: 0, // Maximum rotation for tick labels
          minRotation: 0,
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 8,
          },
          color: "#A3AED0",
          beginAtZero: true,
          callback: function (value) {
            return value; // Add 'M' after the value for millions
          },
        },
        title: {
          display: false,
          //   text: "Awarded value",
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
      y1: {
        position: "right",
        grid: {
          display: false,
          drawOnChartArea: false,
        },

        title: {
          display: false,
        },
        border: {
          display: false, // Hide the y-axis line
        },
        ticks: {
          font: {
            size: 10,
          },
          color: "#A3AED0",
          callback: function (value) {
            return value + "%"; // Add '%' after the value for percentage
          },
        },
      },
      x1: {
        title: {
          display: false,
        },
        border: {
          display: false, // Hide the y-axis line
        },
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: function () {
            return ""; // Return empty string to remove the title
          },
          label: function (tooltipItem) {
            if (tooltipItem.datasetIndex === 1) {
              return `Opportunity: ${tooltipItem.raw}`;
            }
            return `Conversion Rate: ${tooltipItem.raw}%`;
          },
        },
      },
    },
  };

  return (
    <div className="revenue-graph df flexColumn space-between sales-home-bar">
      <div className="revenue-title df justify-center">
        Quoted Response time
      </div>
      <div className="df revenue-label">
        <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#558B70"
            />
          </svg>
          Opportunity
        </div>
        <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#BEC8D0"
            />
          </svg>
          Conversion%
        </div>
      </div>
      <div
        style={{
          width: rem(290),
          height: rem(191),
        }}
      >
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default QuotedResponse;
