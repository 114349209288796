import React, { useEffect, useState } from "react";
// import { Dropdown, Space } from "antd";
import "./QuoteHome.scss";
import _http from "../../../Utils/Api/_http";
import { rem } from "../../../Components/Rem_func";
import SnackBar from "../../../Components/SnackBar";
const QuoteHome = () => {
  const [quote, setQuote] = useState();
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const handletoast = () => {
    setToast({ submit: false, error: false });
  };
  const branchTotals = quote?.reduce((acc, item) => {
    // Ensure item is not null or undefined and has valid properties
    if (item && item.branch && item.quotes > 0 && item.amount > 0) {
      if (acc[item.branch]) {
        // If the branch already exists in the accumulator, add to the totals
        acc[item.branch].quotes += item.quotes;
        acc[item.branch].amount += item.amount;
      } else {
        // If the branch doesn't exist, create a new entry for it
        acc[item.branch] = {
          branch: item.branch,
          quotes: item.quotes,
          amount: item.amount,
        };
      }
    }
    return acc;
  }, {});

  // Convert the branch totals object into an array
  const result = branchTotals ? Object.values(branchTotals) : [];

  // Convert the accumulator object to an array of branch totals
  const fetchData = async () => {
    try {
      const response = await _http.get("/api/fetching-generated-quotes");
      setQuote(response.data);
    } catch (error) {
      setToast({ error: true });
      setError("Error Fetching Quote Generated Data");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  function getDate() {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const date = today.getDate();
    return ` ${date}\t ${months[month]} ${year}`;
  }
  const formatNumber = (num) => {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1) + "B"; // Convert to billions
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + "M"; // Convert to millions
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + "K"; // Convert to thousands
    } else {
      return num?.toString(); // If less than a thousand, return as is
    }
  };
  return (
    <div className="quote_Home">
      <div className="quote-Data-TAble">
        <p className="userM">Quote Generator</p>
        {result?.map((item, index) => (
          <div key={index} className="quote-home-items-content">
            <div className="quote-home-items-title">
              <span>{item.branch || "--"}</span>
            </div>
            <div className="df quote-home-items">
              <div className="quoteitems">
                <span>Quotes</span>
                <span>{item.quotes}</span>
              </div>
              <div className="quoteitems">
                <span>Periods</span>
                <span>{getDate()}</span>
              </div>
              <div className="quoteitems">
                <span>Amount</span>
                <span>{formatNumber(item.amount)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </div>
  );
};

export default QuoteHome;
