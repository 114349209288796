// UserDataProvider.js

import React, { createContext, useContext, useCallback } from "react";
import _http from "./Api/_http";
import { useDispatch, useSelector } from "react-redux";
import { setUserdata, selectUserData } from "../Redux/Reducers";

const UserDataContext = createContext();

export const useUserData = () => {
  return useContext(UserDataContext);
};

export const UserDataProvider = ({ children }) => {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);

  const fetchData = useCallback(async () => {
    try {
      const response = await _http.get(`/get-user-data`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const updatedUserData = {
        ...userData,
        ...response?.data.user_data,
      };

      dispatch(setUserdata(updatedUserData));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [dispatch, userData]);

  return (
    <UserDataContext.Provider value={{ fetchData }}>
      {children}
    </UserDataContext.Provider>
  );
};
