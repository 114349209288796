import React, { useState } from "react";
import SelectSearch from "../../../Components/SelectFields/SelectField";
import { rem } from "../../../Components/Rem_func";
import { Popover, Space } from "antd";
import down from "../../../Assets/EnquiresIcon/down.svg";
import filter from "../../../Assets/EnquiresIcon/filter.svg";
import filteron from "../../../Assets/EnquiresIcon/filteron.svg";
import green_down from "../../../Assets/EnquiresIcon/green_down.svg";
import DashBoardFilter from "./DashBoardFilter";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../Redux/Reducers";

const SalesHeader = ({
  userList,
  filteredData,
  setFilters,
  filterOn,
  setFilteredData,
  DashBoard,
  filters,
  title,
}) => {
  const option = ["Abu Dhabi", "Dubai", "Oman"];

  const [openfilter, setOpenfilter] = useState(false);
  const access = useSelector(selectUserData);
  const currentYear = new Date().getFullYear();

  const handleSelectChange = (name, value) => {
    setFilters((prevFilters) => {
      let updatedFilters = {
        ...prevFilters,
        [name]: prevFilters[name] === value ? "" : value,
      };
      const resetData = {
        client_email_from: "",
        client_email_to: "",
        sales_email_from: "",
        sales_email_to: "",
        ack_email_from: "",
        ack_email_to: "",
        quotation_email_from: "",
        quotation_email_to: "",
        order_date_from: "",
        order_date_to: "",
      };
      if (name === "quick_filter") {
        updatedFilters = {
          ...updatedFilters,
          start_date: "",
          end_date: "",
          year: "",
          quarter: "",
        };
        setFilteredData((prevFilter) => ({ ...prevFilter, ...resetData }));
      } else if (name === "year") {
        updatedFilters = {
          ...updatedFilters,
          quick_filter: "",
          start_date: "",
          end_date: "",
        };
        setFilteredData((prevFilter) => ({ ...prevFilter, ...resetData }));
      } else if (name === "quarter") {
        updatedFilters = {
          ...updatedFilters,
          quick_filter: "",
          start_date: "",
          end_date: "",
        };
        setFilteredData((prevFilter) => ({ ...prevFilter, ...resetData }));
      } else if (name === "start_date" || name === "end_date") {
        updatedFilters = {
          ...updatedFilters,
          quick_filter: "",
          year: "",
          quarter: "",
        };
      } else if (name === "location") {
        const resetData = {
          managers: [],
          teamleads: [],
          employees: [],
        };
        if (DashBoard) {
          setFilteredData((prevFilter) => ({ ...prevFilter, ...resetData }));
        }
      }

      return updatedFilters;
    });
  };

  const hide = () => {
    setOpenfilter(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpenfilter(newOpen);
  };

  const years = Array.from({ length: currentYear - 2018 }, (_, i) => 2019 + i);
  const timeRanges = [
    "Yesterday",
    "Last 3 days",
    "Last week",
    "Last 2 weeks",
    "Last 3 weeks",
    "Last month",
    "Last 3 months",
    "Last 6 months",
    "Last 9 months",
    "Last year",
  ];
  const currency = ["AED", "USD", "OMR"];
  const handleCurrency = (prop) => {
    if (filters.currency === prop) {
      setFilters({ ...filters, currency: "" });
      return;
    }
    setFilters({ ...filters, currency: prop });
  };
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const maxDate = getCurrentDate();
  return (
    <div className="sales-header df flexBox">
      <div className="sales-year df">
        <span className="sales-label">Quick Filter</span>
        <div style={{ width: rem(148) }}>
          <SelectSearch
            options={timeRanges}
            name="quick_filter"
            Placeholder="Select"
            value={filters.quick_filter}
            onChange={(value) => handleSelectChange("quick_filter", value)}
            height={36}
          />
        </div>
      </div>
      <div className="sales-year df">
        <span className="sales-label">Year</span>
        <div style={{ width: rem(148) }}>
          <SelectSearch
            options={years.reverse()}
            name="year"
            Placeholder="year"
            value={filters.year}
            onChange={(value) => handleSelectChange("year", value)}
            height={36}
            multiple={true}
          />
        </div>
      </div>
      <div className="sales-year df">
        <span className="sales-label">Quarter</span>
        <div style={{ width: rem(148) }}>
          <SelectSearch
            options={["Q1", "Q2", "Q3", "Q4"]}
            name="quarter"
            Placeholder={"Quarter"}
            value={filters.quarter}
            onChange={(value) => handleSelectChange("quarter", value)}
            height={36}
            multiple={true}
          />
        </div>
      </div>
      {DashBoard && (
        <div className="sales-date df">
          <span className="sales-label">Date Range</span>
          <div
            onClick={(e) => document.getElementById("dateInput").showPicker()}
            style={{ cursor: "pointer" }}
          >
            <input
              type="date"
              id="dateInput"
              // min={minDate} // Set min date based on quarter and year
              max={filters.end_date ? filters.end_date : maxDate} // Set max date if endDate is not set
              value={filters?.start_date}
              onChange={(e) => handleSelectChange("start_date", e.target.value)}
              style={{ pointerEvents: "none" }}
            />
          </div>
          <div
            onClick={(e) => document.getElementById("dateInput1").showPicker()}
            style={{ cursor: "pointer" }}
          >
            <input
              type="date"
              id="dateInput1"
              min={filters.start_date ? filters.start_date : ""} // Set min date for endDate based on selected startDate
              max={maxDate} // Set max date based on quarter and year
              value={filters?.end_date}
              onChange={(e) => handleSelectChange("end_date", e.target.value)}
            />
          </div>
        </div>
      )}

      {DashBoard && access.role_name !== "employee" && (
        <Popover
          trigger="click"
          open={openfilter}
          placement="bottomLeft"
          onOpenChange={handleOpenChange}
          content={
            <DashBoardFilter
              hide={hide}
              userList={userList}
              setFilteredData={setFilteredData}
              filteredData={filteredData}
              setFilters={setFilters}
            />
          }
        >
          <Space>
            <span
              className="filter-head"
              data-tooltip-id="tooltip-arrow"
              data-tooltip-content="Filter"
            >
              <img src={filterOn ? filteron : filter} alt="icon" />
              <img src={filterOn ? green_down : down} alt="icon" />
            </span>
          </Space>
        </Popover>
      )}
      {access.role_name === "admin" && (
        <div className="sales-location df">
          <span className="sales-label">Location</span>
          <div style={{ width: rem(96) }}>
            <SelectSearch
              options={option}
              name="location"
              Placeholder="All"
              value={filters.location}
              onChange={(value) => handleSelectChange("location", value)}
              height={36}
            />
          </div>
        </div>
      )}
      {!["Awaiting Quote", "Email"].includes(title) &&
        currency.map((item, id) => (
          <div
            key={id}
            className={`${
              filters.currency === item
                ? "currency-select"
                : "currency-notSelect"
            } currency df`}
            onClick={() => handleCurrency(item)}
            style={{ cursor: "pointer" }}
          >
            {item}
          </div>
        ))}
    </div>
  );
};

export default SalesHeader;
