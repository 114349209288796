import React, { useState, useEffect, useCallback } from "react";
import { Bar } from "react-chartjs-2";
import { rem } from "../../../Components/Rem_func";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const RevenueChart = ({ formatNumber, users }) => {
  const [revenue, setRevenue] = useState([]);

  const fetchData = useCallback(() => {
    // Step 1: Filter users with reminder_status as 'order_placed'
    const filteredUsers = users.filter(
      (user) => user.reminder_status === "order_placed"
    );

    // Step 2: Sort the filtered users in descending order based on order_value
    const sortedUsers = filteredUsers.sort(
      (a, b) => b.final_order_currency - a.final_order_currency
    );

    // Step 3: Get the top 10 users
    const topTenUsers = sortedUsers.slice(0, 10);

    // Step 4: Set the filtered data to the state
    setRevenue(topTenUsers);
  }, [users]);
  useEffect(() => {
    fetchData();
  }, [users, fetchData]);
  const data = {
    labels: (revenue || []).map((client) => client?.client_person_name),
    datasets: [
      {
        type: "bar",
        label: "Awarded Value",
        data: revenue?.map((client) => client?.final_order_currency || 0),
        backgroundColor: "#609379",
        // borderColor: "rgba(0, 128, 0, 1)",
        // borderWidth: 1,
        // hoverBackgroundColor: "rgba(31, 103, 66, 0.31)",
        barPercentage: 0.5,
        borderRadius: 10,
        order: 2,
        yAxisID: "y",
      },
      {
        type: "line",
        // label: "Awarded Value",
        data: revenue?.map(
          (client) =>
            (parseFloat(
              client?.final_order_currency?.replace(/[^0-9.-]+/g, "")
            ) /
              parseFloat(client?.currency_value?.replace(/[^0-9.-]+/g, ""))) *
              100 || 0
        ),
        borderWidth: 2,
        borderColor: "#1e6641",

        PointBorderColor: "#E0E7ED",
        pointBackgroundColor: "#1e6641",
        pointBorderColor: "#fff",
        pointRadius: 3,
        fill: true,
        order: 1,
        yAxisID: "y1",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12, // Set a stable font size
          },
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          borderColor: "#EBEBEB",
        },
        ticks: {
          maxTicksLimit: 5,
          font: { size: 10 },
          color: "#636363",
          // color: "#A3AED0",
          beginAtZero: true,
          callback: function (value) {
            return `${formatNumber(value)}`; // Add '%' after the value for percentage
          },
        },
        border: {
          display: false, // Hide the y-axis line
        },
        title: {
          display: false,
        },
      },
      y1: {
        position: "right",
        beginAtZero: true,
        grid: {
          display: false,
          drawOnChartArea: false,
        },
        ticks: {
          font: {
            size: 10,
          },
          color: "#636363",
          maxTicksLimit: 5,
          callback: function (value) {
            return `${value}%`; // Add '%' after the value for percentage
          },
        },
        title: {
          display: false,
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            if (tooltipItem.datasetIndex === 1) {
              return `Conversion Rate: ${tooltipItem.raw.toFixed(2)}%`;
            }
            return `Awarded Value: ${formatNumber(tooltipItem.raw)}`;
          },
        },
        bodyFont: {
          size: 12, // Stable font size for tooltips
        },
      },
    },
  };

  return (
    <div className="revenue-graph df flexColumn space-between">
      <div className="revenue-title df justify-center">
        Top Revenue Generating Clients
      </div>

      <div className="barChat" style={{ height: rem(195), width: "100%" }}>
        <Bar data={data} options={options} />
      </div>
      <div className="df revenue-label" style={{ fontSize: rem(8) }}>
        <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#609379"
            />
          </svg>
          Awarded value
        </div>
        <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#BEC8D0"
            />
          </svg>
          Successful conversion divided by opportunity
        </div>
      </div>
    </div>
  );
};

export default RevenueChart;
