import React, { useState, useEffect } from "react";
import "./FilterEnquires.scss";
import Button from "../../../../Components/Button/Button";
import InputField from "../../../../Components/InputField/InputField";
import { rem } from "../../../../Components/Rem_func";

const FilterEnquires = ({
  hide,
  filteredData,
  setFilteredData,
  title,
  setFilters,
}) => {
  // Function to handle filter application
  const [localData, setLocalData] = useState(filteredData);

  const handleFilter = () => {
    const resetData = {
      client_email_from: "",
      client_email_to: "",
      sales_email_from: "",
      sales_email_to: "",
      ack_email_from: "",
      ack_email_to: "",
      quotation_email_from: "",
      quotation_email_to: "",
      order_date_from: "",
      order_date_to: "",
    };
    // If filteredData contains the resetData fields, reset the specific filters
    const shouldResetFilters = Object.keys(resetData).every(
      (key) => filteredData[key] === resetData[key]
    );

    if (shouldResetFilters) {
      // Reset quick_filter, year, and quarter
      setFilters((prevFilters) => ({
        ...prevFilters,
        quick_filter: "",
        year: "",
        quarter: "",
      }));
    }
    setFilteredData(localData);
    hide();
  };
  useEffect(() => {
    setLocalData(filteredData);
  }, [filteredData]);
  // Function to reset filters
  const ResetFilter = () => {
    // Reset filtered data
    const resetData = {
      ack_number: "",
      sales_person_name: "",
      client_person_name: "",
      client_subject: "",
      client_email_from: "",
      client_email_to: "",
      sales_email_from: "",
      sales_email_to: "",
      ack_email_from: "",
      ack_email_to: "",
      quotation_email_from: "",
      quotation_email_to: "",
      order_date_from: "",
      order_date_to: "",
      max_quote_value: "",
      min_quote_value: "",
      min_order_value: "",
      max_order_value: "",
    };
    setLocalData(resetData);
    setFilteredData(resetData);
  };
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };
  const maxDate = getCurrentDate();

  return (
    <div className="filter-box">
      <div className="popover-title">Filter</div>

      <div className="filter-body">
        {/* Filter input fields */}
        <InputField
          label={"ack_number"}
          labelName={"ACK Number"}
          type={"text"}
          placeholder={""}
          fieldData={localData?.ack_number}
          setFormData={setLocalData}
          maxLength={50}
          styles={{ textTransform: "uppercase" }}
          pattern={"textwithNospace"}
        />
        <InputField
          label={"sales_person_name"}
          labelName={"Sales Team Member"}
          type={"text"}
          placeholder={""}
          fieldData={localData?.sales_person_name}
          setFormData={setLocalData}
          maxLength={50}
          pattern={"onlyAlpha"}
          // styles={{ textTransform: "capitalize" }}
        />
        <InputField
          label={"client_person_name"}
          labelName={"Buyer Seller POC"}
          type={"text"}
          placeholder={""}
          fieldData={localData?.client_person_name}
          setFormData={setLocalData}
          maxLength={50}
          // styles={{ textTransform: "capitalize" }}
          pattern={"text"}
        />
        <InputField
          label={"client_subject"}
          labelName={"Subject"}
          type={"text"}
          placeholder={""}
          fieldData={localData?.client_subject}
          setFormData={setLocalData}
        />
        {!["Order Placed"].includes(title) && (
          <>
            <div className="sub-head" style={{ marginBottom: "0" }}>
              Buyer / Seller Time
            </div>
            <div className="date-filter">
              <div style={{ width: "48%" }}>
                <InputField
                  labelName={"From"}
                  label={"client_email_from"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.client_email_from}
                  setFormData={setLocalData}
                  maxLength={
                    localData.client_email_to
                      ? localData?.client_email_to?.split(" ")[0]
                      : maxDate
                  }
                />
              </div>
              <div style={{ width: "48%" }}>
                <InputField
                  labelName={"To"}
                  label={"client_email_to"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.client_email_to}
                  setFormData={setLocalData}
                  maxLength={maxDate}
                  minLength={localData?.client_email_from?.split(" ")[0] || ""}
                />
              </div>
            </div>
            <div className="sub-head" style={{ marginBottom: "0" }}>
              Sales to AI
            </div>
            <div className="date-filter">
              <div style={{ width: "48%" }}>
                <InputField
                  labelName={"From"}
                  label={"sales_email_from"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.sales_email_from}
                  setFormData={setLocalData}
                  maxLength={
                    localData.sales_email_to
                      ? localData?.sales_email_to?.split(" ")[0]
                      : maxDate
                  }
                />
              </div>
              <div style={{ width: "48%" }}>
                <InputField
                  labelName={"To"}
                  label={"sales_email_to"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.sales_email_to}
                  setFormData={setLocalData}
                  maxLength={maxDate}
                  minLength={localData?.sales_email_from?.split(" ")[0] || ""}
                />
              </div>
            </div>
            <div className="sub-head" style={{ marginBottom: "0" }}>
              AI Ack
            </div>
            <div className="date-filter">
              <div style={{ width: "48%" }}>
                <InputField
                  labelName={"From"}
                  label={"ack_email_from"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.ack_email_from}
                  setFormData={setLocalData}
                  maxLength={
                    localData.ack_email_to
                      ? localData?.ack_email_to?.split(" ")[0]
                      : maxDate
                  }
                />
              </div>
              <div style={{ width: "48%" }}>
                <InputField
                  labelName={"To"}
                  label={"ack_email_to"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.ack_email_to}
                  setFormData={setLocalData}
                  maxLength={maxDate}
                  minLength={localData?.ack_email_from?.split(" ")[0] || ""}
                />
              </div>
            </div>
          </>
        )}
        {!["Awaiting Quote", "Order Placed"].includes(title) && (
          <>
            <div className="sub-head" style={{ marginBottom: "0" }}>
              Quotation Receive Time
            </div>
            <div className="date-filter">
              <div style={{ width: "48%" }}>
                <InputField
                  labelName={"From"}
                  label={"quotation_email_from"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.quotation_email_from}
                  setFormData={setLocalData}
                  maxLength={
                    localData.quotation_email_to
                      ? localData?.quotation_email_to?.split(" ")[0]
                      : maxDate
                  }
                />
              </div>
              <div style={{ width: "48%" }}>
                <InputField
                  labelName={"To"}
                  label={"quotation_email_to"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.quotation_email_to}
                  setFormData={setLocalData}
                  maxLength={maxDate}
                  minLength={
                    localData?.quotation_email_from?.split(" ")[0] || ""
                  }
                />
              </div>
            </div>
          </>
        )}
        {!["Awaiting Quote"].includes(title) && (
          <>
            {" "}
            <div className="sub-head" style={{ marginBottom: "0" }}>
              Quote Value
            </div>
            <div className="date-filter flexColumn">
              <div style={{ width: "100%" }}>
                <InputField
                  labelName={"From"}
                  label={"min_quote_value"}
                  type={"range"}
                  placeholder={""}
                  fieldData={localData.min_quote_value}
                  setFormData={setLocalData}
                  maxLength={
                    localData?.max_quote_value
                      ? localData?.max_quote_value
                      : 1000000
                  }
                  minLength={100}
                  styles={{ cursor: "pointer" }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <InputField
                  labelName={"To"}
                  label={"max_quote_value"}
                  type={"range"}
                  placeholder={""}
                  fieldData={localData.max_quote_value}
                  setFormData={setLocalData}
                  minLength={
                    localData?.min_quote_value
                      ? localData?.min_quote_value
                      : 100
                  }
                  maxLength={1000000}
                  styles={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </>
        )}

        {!["Awaiting Quote", "Awaiting Order"].includes(title) && (
          <>
            {" "}
            <div className="sub-head" style={{ marginBottom: "0" }}>
              Ordered Date
            </div>
            <div className="date-filter">
              <div style={{ width: "48%" }}>
                <InputField
                  labelName={"From"}
                  label={"order_date_from"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.order_date_from}
                  setFormData={setLocalData}
                  maxLength={
                    localData.order_date_to
                      ? localData?.order_date_to?.split(" ")[0]
                      : maxDate
                  }
                />
              </div>
              <div style={{ width: "48%" }}>
                <InputField
                  labelName={"To"}
                  label={"order_date_to"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.order_date_to}
                  setFormData={setLocalData}
                  maxLength={maxDate}
                  minLength={localData?.order_date_from?.split(" ")[0] || ""}
                />
              </div>
            </div>
            <>
              {" "}
              <div className="sub-head" style={{ marginBottom: "0" }}>
                Order Value
              </div>
              <div className="date-filter flexColumn">
                <div style={{ width: "100%" }}>
                  <InputField
                    labelName={"From"}
                    label={"min_order_value"}
                    type={"range"}
                    fieldData={localData.min_order_value}
                    setFormData={setLocalData}
                    maxLength={
                      localData?.max_order_value
                        ? localData?.max_order_value
                        : 1000000
                    }
                    minLength={100}
                    styles={{ cursor: "pointer" }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <InputField
                    labelName={"To"}
                    label={"max_order_value"}
                    type={"range"}
                    placeholder={""}
                    fieldData={localData.max_order_value}
                    setFormData={setLocalData}
                    // max={}
                    minLength={
                      localData?.min_order_value
                        ? localData?.min_order_value
                        : 100
                    }
                    maxLength={1000000}
                    styles={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </>
          </>
        )}
      </div>
      {/* Filter buttons */}
      <div className="filter-btn">
        <div>
          <Button
            onclick={ResetFilter}
            btnname={"Reset"}
            cl={"#1e6641"}
            br={4}
            bg={"#fff"}
            border={`${rem(1)} solid #1E6641`}
            box_s="none"
            fs={16}
          />
        </div>
        <div>
          <Button btnname={"Apply Now"} onclick={handleFilter} fs={16} br={4} />
        </div>
      </div>
    </div>
  );
};

export default FilterEnquires;
