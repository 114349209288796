import React from "react";
// import { Dropdown, Space } from "antd";
import "./QuoteHome.scss";
import { rem } from "../../../Components/Rem_func";
const QuoteHome = () => {
  // const items = [
  //   {
  //     key: "1",
  //     label: <span>Dubai</span>,
  //   },
  //   {
  //     key: "2",
  //     label: <span>Abudhabi</span>,
  //   },
  //   {
  //     key: "3",
  //     label: <span>Oman</span>,
  //   },
  // ];

  return (
    <div className="quote_home">
      <div className="kas">
        <p className="userM">Quote Generator</p>
        <div className="flexBox df" style={{ gap: rem(19) }}>
          {/* <div className="branch">
            <Dropdown
              menu={{
                items,
              }}
            >
              <Space>
                Select Team
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={rem(19)}
                  height={rem(19)}
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <path
                    d="M13.459 7.91667L9.50065 11.875L5.54232 7.91667L13.459 7.91667Z"
                    fill="#AAAAAA"
                  />
                </svg>
              </Space>
            </Dropdown>
          </div> */}
          {/* <div className="branch">
            <Dropdown
              menu={{
                items,
              }}
            >
              <Space>
                Select Branch
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={rem(19)}
                  height={rem(19)}
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <path
                    d="M13.459 7.91667L9.50065 11.875L5.54232 7.91667L13.459 7.91667Z"
                    fill="#AAAAAA"
                  />
                </svg>
              </Space>
            </Dropdown>
          </div> */}
        </div>
      </div>

      <div className="quote-home-table">
        <table>
          <thead>
            <tr>
              {/* <th>Branch </th> */}
              <th className="tl">Quotes</th>

              <th className="tl">
                <span className="df" style={{ gap: rem(8) }}>
                  Period
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={rem(14)}
                    height={rem(14)}
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M11.0827 2.33341H9.91601V1.75008C9.91601 1.40008 9.68268 1.16675 9.33268 1.16675C8.98268 1.16675 8.74935 1.40008 8.74935 1.75008V2.33341H5.24935V1.75008C5.24935 1.40008 5.01602 1.16675 4.66602 1.16675C4.31602 1.16675 4.08268 1.40008 4.08268 1.75008V2.33341H2.91602C1.92435 2.33341 1.16602 3.09175 1.16602 4.08342V5.25008H12.8327V4.08342C12.8327 3.09175 12.0743 2.33341 11.0827 2.33341Z"
                      fill="#A3AED0"
                    />
                    <path
                      d="M2.91602 12.6873C2.00489 12.6873 1.31185 11.9943 1.31185 11.0832V6.56234H12.6868V11.0832C12.6868 11.9943 11.9938 12.6873 11.0827 12.6873H2.91602Z"
                      stroke="#A3AED0"
                      strokeWidth="0.291667"
                    />
                  </svg>
                </span>
              </th>
              <th className="tl">Progress</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* <td>Dubai</td> */}
              <td className="tl">01</td>

              <td className="date tl">18 Apr 2021</td>
              <td>
                <div className="progress-bar">
                  <div className="progress" style={{ width: `60%` }}></div>
                </div>
              </td>
            </tr>
            <tr>
              {/* <td>Abudhabi</td> */}
              <td className="tl">23</td>

              <td className="date tl">18 Apr 2021</td>
              <td>
                <div className="progress-bar">
                  <div className="progress" style={{ width: `30%` }}></div>
                </div>
              </td>
            </tr>
            <tr>
              {/* <td>Oman</td> */}
              <td className="tl">11</td>

              <td className="date tl">20 May 2021</td>
              <td className="tl">
                <div className="progress-bar">
                  <div className="progress" style={{ width: `90%` }}></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default QuoteHome;
