import React, { useRef, useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import { rem } from "../../../Components/Rem_func";
const CurrencyBieChart = ({
  colour1,
  colour2,
  label1,
  label2,
  data1,
  data2,
}) => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    labels: [label1, label2],
    datasets: [
      {
        data: [data1, data2],
        backgroundColor: ["#D9D9D9", "#fff"], // Initial fallback colors
        hoverBackgroundColor: ["#D9D9D9", "#fff"], // Initial fallback colors
        borderColor: colour1,
        borderWidth: 0.8,
      },
    ],
  });
  const formatNumber = (num) => {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1) + "B"; // Convert to billions
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + "M"; // Convert to millions
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + "K"; // Convert to thousands
    } else {
      return num?.toString(); // If less than a thousand, return as is
    }
  };
  useEffect(() => {
    const chart = chartRef.current;

    if (chart) {
      const ctx = chart.ctx;

      // Create gradient
      const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
      gradient.addColorStop(0, colour1); // Start with specified color
      gradient.addColorStop(1, `${colour1}00`); // Fully transparent at the end

      // Update the chart data with the gradient
      setChartData((prevData) => ({
        ...prevData,
        labels: [label1, label2],

        datasets: [
          {
            ...prevData.datasets[0],
            backgroundColor: [gradient, colour2],
            hoverBackgroundColor: [gradient, colour2],
            data: [data1, data2],
          },
        ],
      }));
    }
  }, [colour1, colour2, data1, data2, label1, label2]);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Disable legend
      },
      tooltip: {
        enabled: false, // Disable default tooltip
        external: (context) => {
          // Create or select custom tooltip element
          let tooltipEl = document.getElementById("chartjs-tooltip");

          if (!tooltipEl) {
            tooltipEl = document.createElement("div");

            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.backgroundColor = "#000";
            tooltipEl.style.color = "#fff";
            tooltipEl.style.border = "1px solid #D9D9D9";
            tooltipEl.style.borderRadius = "8px";
            tooltipEl.style.padding = "8px 12px";
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.whiteSpace = "nowrap";
            tooltipEl.style.zIndex = "1000";
            document.body.appendChild(tooltipEl);
          }

          const tooltip = context.tooltip;

          if (!tooltip.opacity) {
            tooltipEl.style.opacity = 0;
            return;
          }

          const label = tooltip.dataPoints[0]?.label || "";
          const value = tooltip.dataPoints[0]?.raw || "";

          // Update tooltip content
          tooltipEl.innerHTML = `<div>${label}: ${formatNumber(value)}</div>`;

          // Get canvas position
          const canvas = context.chart.canvas;
          const canvasRect = canvas.getBoundingClientRect();

          // Set tooltip position to the left side of the cursor
          const leftPosition =
            canvasRect.left + tooltip.caretX - tooltipEl.offsetWidth - 0; // Offset to the left
          const topPosition = canvasRect.top + tooltip.caretY;

          tooltipEl.style.opacity = 1;
          tooltipEl.style.left = `${Math.max(leftPosition, 0)}px`; // Prevent going off-screen
          tooltipEl.style.top = `${topPosition}px`;
        },
      },
    },
  };

  return (
    <div style={{ width: rem(44), height: rem(44) }}>
      <Pie ref={chartRef} data={chartData} options={options} />
    </div>
  );
};

export default CurrencyBieChart;
